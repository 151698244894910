import React from 'react';
import { Form, Row, Col, Button, Table } from 'antd';
import { paymentService } from '../../../../services/paymentService';

const columns = [{
    title: 'ID Solicitud',
    key: 'name',
    render:(text, record) => (
        <span>
          { record.contract.quotation[0].id }
        </span>
      )
  },{
    title: 'Propietario',
    key: 'contract.propietary_email',
    dataIndex: 'contract.propietary_email'
  },{
    title: 'Arrendatario',
    key: 'contract.lessor_email',
    dataIndex: 'contract.lessor_email'
  },{
    title: 'Fecha de pago',
    dataIndex: 'date',
    key: 'date',
  },{
    title: 'Monto',
    key: 'price',
    dataIndex: 'price',
  },{
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },{
    title: 'Acciones',
    key: 'name',
    render:(text, record) => (
        <span>
            <a href={"/quotations/" + record.contract.quotation[0].id}>Ver</a>
        </span>
      )
  }


];

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true
        };
    }

    async componentDidMount() {
        let request = await paymentService.getDelayedPayments()
        let today = await paymentService.getTodayPayments()
        if(request && today ){
        await this.setState({
            loading:false,
            delayed: request.data,
            today: today.data
        })
        }
    }

    renderDelayedPayments = ()=>{
        let payments = this.state.delayed ? this.state.delayed.results : null

        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(payments){
            if(payments.length === 0) return 'No hay pagos atrasados'
        }
        
        return this.state.delayed ?
        <Table columns={columns} dataSource={ this.state.delayed.results } /> : 'No hay pagos atrasados';
    }

    goToQuotation = (quotation_id)=>{
    }

    renderTodayPayments = ()=>{
        let payments = this.state.today ? this.state.today.results : null
        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(payments){
            if(payments.length === 0) return 'No hay pagos agendados para el día de hoy'
        }

        return this.state.today ?
        <Table columns={columns} dataSource={ this.state.today.results } /> : null;
    }

    render() {

        return (
                <div>
                    <Row >
                        <Col className="gutter-row" >
                            <h2>Atrasados</h2>
                            {this.renderDelayedPayments()}
                        </Col>

                    </Row>
                    <Row>
                        <Col className="gutter-row" >
                            <h2>Pagos de Hoy</h2>
                            {this.renderTodayPayments()}
                        </Col>
                    </Row>
                </div>

        );
    }
}

export const PaymentIndex = Form.create()(Payment);