import React, { Component } from 'react';
import { Layout, Menu, Icon, PageHeader } from 'antd';
import {  NavLink, Link } from "react-router-dom";
import { browserHistory } from 'react-router'
import SubMenu from 'antd/lib/menu/SubMenu';
import { userService } from '../../../services/userService';

const { Header, Sider, Content } = Layout;
const MenuItemGroup = Menu.ItemGroup;

class AdminLayout extends Component {

  constructor(props) {
    super(props);
      this.state = {
        collapsed: window.innerWidth < 700,
      };
  }
        toggle = () => {
          this.setState({
            collapsed: !this.state.collapsed,
          });
        }

    async componentDidMount(){
        const query = new URLSearchParams(this.props.location.search);
        let token = query.get('token')
        if(token){
          localStorage.setItem('token',token)
          let user = await userService.me()
          if(user){
            localStorage.setItem('user',JSON.stringify(user))
            window.history.replaceState({}, document.title, "/propietary/properties");
            window.location.reload();
          }




        }
    }

    async logout(){
      this.props.auth.logout( ()=>window.location.href='/' )
    }

    render() {
      
        const children = (this.props.children);
        let form 
        if(this.props.auth.user()){
          form =      
        <Layout style={{ minHeight:'100vh'}} className = "layout" >
         
            {
              this.props.auth.user().type === "admin" ?

          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => { console.log(broken); }}
            onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
              theme="light"
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
              >
            <div className="logo" style={{textAlign:'center'}} >
              <img alt="logo" src="/logo.png" />
            </div>
            <Menu theme="light" mode="inline" selectedKeys={[this.props.location.pathname]} >
                <Menu.Item key="/dashboard">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/dashboard">Dashboard</Link>
                </Menu.Item>
                  <Menu.Item key="/quotations">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/quotations">Solicitudes</Link>
                </Menu.Item>
                <Menu.Item key="/payments">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/payments">Estado Pagos</Link>
                </Menu.Item>
                <Menu.Item key="/applicants">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/applicants">Postulantes</Link>
                </Menu.Item>
                <Menu.Item key="/properties">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/properties">Propiedades</Link>
                </Menu.Item>
              <Menu.Item onClick={()=>this.logout()}>
                <Icon type="logout" />
                <span>Salir</span>
              </Menu.Item>
            </Menu> 
            
            { this.props.auth.user.type === "propietary" ? 
            <Menu theme="light" mode="inline" selectedKeys={[this.props.location.pathname]}>

                <Menu.Item key="/propietary/properties">
                    <Icon style={{display:'inline-block'}} type="file" />
                    <Link style={{display:'inline-block'}}  to="/propietary/properties">Propiedades</Link>
                </Menu.Item>
                  <Menu.Item key="/propietary/applicants">
                    <NavLink to="/propietary/applicants" className="nav-text">
                        Postulantes
                    </NavLink>
                </Menu.Item>
              <Menu.Item onClick={()=>this.logout()}>
                <Icon type="logout" />
                <span>Salir</span>
              </Menu.Item>
            </Menu> : null}
          </Sider> : null}
        <Layout>

          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280 }}>

        {
          children
        }
          </Content>
        </Layout>
      </Layout>
        }

        else{
           form = <div></div>
        }
        

        return form
                

    }
}

export default AdminLayout;
