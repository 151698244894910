import {
  PYTHON_URL
} from '../env'
import axios from 'axios'
import {
  getToken
} from './helper';

class SchedulerService {

  getHours(timezone) {
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      "Authorization": "Bearer " + getToken()
    }
    let http = axios.create({
      baseURL: PYTHON_URL,
      headers: headers
    })
    return http.get('/propietary/scheduler/get_hours/' , {  params : {timezone : timezone} } ).catch(error => {
      console.log(error);
      return false
    })
  }

  schedule(data) {
    let headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + getToken()
    }
    let http = axios.create({
      baseURL: PYTHON_URL,
      headers: headers
    })
    return http.post('/propietary/scheduler/schedule/', data).catch(error => {
      console.log(error);
      return false
    })
  }

  complete(data) {
    let headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + getToken()
    }
    let http = axios.create({
      baseURL: PYTHON_URL,
      headers: headers
    })
    return http.post('/propietary/property_details/', data).catch(error => {
      console.log(error);
      return false
    })
  }


  listMyProperty(id) {

    let headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + getToken()
    }
    let http = axios.create({
      baseURL: PYTHON_URL,
      headers: headers
    })
    return http.post('/propietary/properties/list/do', {
      uid: id
    }).catch(error => {
      console.log(error);
      return false
    })
  }

  dontListMyProperty(id) {

    let headers = {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + getToken()
    }
    let http = axios.create({
      baseURL: PYTHON_URL,
      headers: headers
    })
    return http.post('/propietary/properties/list/dont', {
      uid: id
    }).catch(error => {
      console.log(error);
      return false
    })
  }
}

export const schedulerService = new SchedulerService();
