import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import { Upload, Modal  } from 'antd';



class ImageUpload extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleChange = ({ fileList }) => this.setState({ fileList })


    render() {

        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
          <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Subir Imagen</div>
          </div>
        );


        return  <div className="clearfix">
        <Card style={{border:'0px'}}>
            <h3 style={{textAlign:'center'}}>Sube fotos de tu propiedad</h3>
            <Upload
            action="//jsonplaceholder.typicode.com/posts/"
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            multiple
            >
            {fileList.length >= 6 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Card>
      </div>
    }
}

export default ImageUpload;
