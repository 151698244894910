import axios from 'axios'

const BASE_URL = "https://dev.arriendoasegurado.com"

class PythonService 
{

	pay( id )
	{
    	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    	let http = axios.create({ baseURL: BASE_URL,  headers: headers	})
    	    
    	return http.get( 'payments/by_rut/'+id + '/' ).catch( error => { console.log(error); return false })
        		
	}




}

export const pythonService = new PythonService();
