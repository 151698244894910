export const  Comunas = [
    {
        "nombre": "Arica",
        "code": "15101",
        "provincia": "Arica",
        "region": "Arica y Parinacota",
        "region_number": "XV",
        "region_iso_3166_2": "CL-AP"
    },
    {
        "nombre": "Camarones",
        "code": "15102",
        "provincia": "Arica",
        "region": "Arica y Parinacota",
        "region_number": "XV",
        "region_iso_3166_2": "CL-AP"
    },
    {
        "nombre": "Putre",
        "code": "15201",
        "provincia": "Parinacota",
        "region": "Arica y Parinacota",
        "region_number": "XV",
        "region_iso_3166_2": "CL-AP"
    },
    {
        "nombre": "General Lagos",
        "code": "15202",
        "provincia": "Parinacota",
        "region": "Arica y Parinacota",
        "region_number": "XV",
        "region_iso_3166_2": "CL-AP"
    },
    {
        "nombre": "Iquique",
        "code": "01101",
        "provincia": "Iquique",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Alto Hospicio",
        "code": "01102",
        "provincia": "Iquique",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Pozo Almonte",
        "code": "01201",
        "provincia": "Tamarugal",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Camiña",
        "code": "01402",
        "provincia": "Tamarugal",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Colchane",
        "code": "01403",
        "provincia": "Tamarugal",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Huara",
        "code": "01404",
        "provincia": "Tamarugal",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Pica",
        "code": "01405",
        "provincia": "Tamarugal",
        "region": "Tarapacá",
        "region_number": "I",
        "region_iso_3166_2": "CL-TA"
    },
    {
        "nombre": "Antofagasta",
        "code": "02101",
        "provincia": "Antofagasta",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Mejillones",
        "code": "02102",
        "provincia": "Antofagasta",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Sierra Gorda",
        "code": "02103",
        "provincia": "Antofagasta",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Taltal",
        "code": "02104",
        "provincia": "Antofagasta",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Calama",
        "code": "02201",
        "provincia": "El Loa",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Ollagüe",
        "code": "02202",
        "provincia": "El Loa",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "San Pedro de Atacama",
        "code": "02203",
        "provincia": "El Loa",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Tocopilla",
        "code": "02301",
        "provincia": "Tocopilla",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "María Elena",
        "code": "02302",
        "provincia": "Tocopilla",
        "region": "Antofagasta",
        "region_number": "II",
        "region_iso_3166_2": "CL-AN"
    },
    {
        "nombre": "Copiapó",
        "code": "03101",
        "provincia": "Copiapó",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Caldera",
        "code": "03102",
        "provincia": "Copiapó",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Tierra Amarilla",
        "code": "03103",
        "provincia": "Copiapó",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Chañaral",
        "code": "03201",
        "provincia": "Chañaral",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Diego de Almagro",
        "code": "03202",
        "provincia": "Chañaral",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Vallenar",
        "code": "03301",
        "provincia": "Huasco",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Alto del Carmen",
        "code": "03302",
        "provincia": "Huasco",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Freirina",
        "code": "03303",
        "provincia": "Huasco",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "Huasco",
        "code": "03304",
        "provincia": "Huasco",
        "region": "Atacama",
        "region_number": "III",
        "region_iso_3166_2": "CL-AT"
    },
    {
        "nombre": "La Serena",
        "code": "04101",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Coquimbo",
        "code": "04102",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Andacollo",
        "code": "04103",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "La Higuera",
        "code": "04104",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Paiguano",
        "code": "04105",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Vicuña",
        "code": "04106",
        "provincia": "Elqui",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Illapel",
        "code": "04201",
        "provincia": "Choapa",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Canela",
        "code": "04202",
        "provincia": "Choapa",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Los Vilos",
        "code": "04203",
        "provincia": "Choapa",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Salamanca",
        "code": "04204",
        "provincia": "Choapa",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Ovalle",
        "code": "04301",
        "provincia": "Limarí",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Combarbalá",
        "code": "04302",
        "provincia": "Limarí",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Monte Patria",
        "code": "04303",
        "provincia": "Limarí",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Punitaqui",
        "code": "04304",
        "provincia": "Limarí",
        "region": "Coquimbo",
        "region_number": "IV"
    },
    {
        "nombre": "Río Hurtado",
        "code": "04305",
        "provincia": "Limarí",
        "region": "Coquimbo",
        "region_number": "IV",
        "region_iso_3166_2": "CL-CO"
    },
    {
        "nombre": "Valparaíso",
        "code": "05101",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Casablanca",
        "code": "05102",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Concón",
        "code": "05103",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Juan Fernández",
        "code": "05104",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Puchuncaví",
        "code": "05105",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Quintero",
        "code": "05107",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Viña del Mar",
        "code": "05109",
        "provincia": "Valparaíso",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Isla de Pascua",
        "code": "05201",
        "provincia": "Isla de Pascua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Los Andes",
        "code": "05301",
        "provincia": "Los Andes",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Calle Larga",
        "code": "05302",
        "provincia": "Los Andes",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Riconada",
        "code": "05303",
        "provincia": "Los Andes",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "San Esteban",
        "code": "05304",
        "provincia": "Los Andes",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "La Ligua",
        "code": "05401",
        "provincia": "Petorca",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Cabildo",
        "code": "05402",
        "provincia": "Petorca",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Papudo",
        "code": "05403",
        "provincia": "Petorca",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Petorca",
        "code": "05404",
        "provincia": "Petorca",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Zapallar",
        "code": "05405",
        "provincia": "Petorca",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Quillota",
        "code": "05501",
        "provincia": "Quillota",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Calera",
        "code": "05502",
        "provincia": "Quillota",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Hijuelas",
        "code": "05503",
        "provincia": "Quillota",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "La Cruz",
        "code": "05504",
        "provincia": "Quillota",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Nogales",
        "code": "05506",
        "provincia": "Quillota",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "San Antonio",
        "code": "05601",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Algarrobo",
        "code": "05602",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Cartagena",
        "code": "05603",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "El Quisco",
        "code": "05604",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "El Tabo",
        "code": "05605",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Santo Domingo",
        "code": "05606",
        "provincia": "San Antonio",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "San Felipe",
        "code": "05701",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Catemu",
        "code": "05702",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Llaillay",
        "code": "05703",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Panquehue",
        "code": "05704",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Putaendo",
        "code": "05705",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Santa María",
        "code": "05706",
        "provincia": "San Felipe de Aconcagua",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Quilpué",
        "code": "05801",
        "provincia": "Marga Marga",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Limache",
        "code": "05802",
        "provincia": "Marga Marga",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Olmué",
        "code": "05803",
        "provincia": "Marga Marga",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Villa Alemana",
        "code": "05804",
        "provincia": "Marga Marga",
        "region": "Valparaíso",
        "region_number": "V",
        "region_iso_3166_2": "CL-VS"
    },
    {
        "nombre": "Rancagua",
        "code": "06101",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Codegua",
        "code": "06102",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Coinco",
        "code": "06103",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Coltauco",
        "code": "06104",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Doñihue",
        "code": "06105",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Graneros",
        "code": "06106",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Las Cabras",
        "code": "06107",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Machalí",
        "code": "06108",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Malloa",
        "code": "06109",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Mostazal",
        "code": "06110",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Olivar",
        "code": "06111",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Peumo",
        "code": "06112",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Pichidegua",
        "code": "06113",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Quinta de Tilcoco",
        "code": "06114",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Rengo",
        "code": "06115",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Requínoa",
        "code": "06116",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "San Vicente",
        "code": "06117",
        "provincia": "Cachapoal",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Pichilemu",
        "code": "06201",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "La Estrella",
        "code": "06202",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Litueche",
        "code": "06203",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Marichihue",
        "code": "06204",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Navidad",
        "code": "06205",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Paredones",
        "code": "06206",
        "provincia": "Cardenal Caro",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "San Fernando",
        "code": "06301",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Chépica",
        "code": "06302",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Chimbarongo",
        "code": "06303",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Lolol",
        "code": "06304",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Nancagua",
        "code": "06305",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Palmilla",
        "code": "06306",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Peralillo",
        "code": "06307",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Placilla",
        "code": "06308",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Pumanque",
        "code": "06309",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Santa Cruz",
        "code": "06310",
        "provincia": "Colchagua",
        "region": "Región del Libertador Gral. Bernardo O'Higgins",
        "region_number": "VI",
        "region_iso_3166_2": "CL-LI"
    },
    {
        "nombre": "Talca",
        "code": "07101",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Constitución",
        "code": "07102",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Curepto",
        "code": "07103",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Empedrado",
        "code": "07104",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Maule",
        "code": "07105",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Pelarco",
        "code": "07106",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Pencahue",
        "code": "07107",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Río Claro",
        "code": "07108",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "San Clemente",
        "code": "07109",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "San Rafael",
        "code": "07110",
        "provincia": "Talca",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Cauquenes",
        "code": "07201",
        "provincia": "Cauquenes",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Chanco",
        "code": "07202",
        "provincia": "Cauquenes",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Pelluhue",
        "code": "07203",
        "provincia": "Cauquenes",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Curicó",
        "code": "07301",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Hualañé",
        "code": "07302",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Licantén",
        "code": "07303",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Molina",
        "code": "07304",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Rauco",
        "code": "07305",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Romeral",
        "code": "07306",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Sagrada Familia",
        "code": "07307",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Teno",
        "code": "07308",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Vichuquén",
        "code": "07309",
        "provincia": "Curicó",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Linares",
        "code": "07401",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Colbún",
        "code": "07402",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Longaví",
        "code": "07403",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Parral",
        "code": "07404",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Retiro",
        "code": "07405",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "San Javier",
        "code": "07406",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Villa Alegre",
        "code": "07407",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Yerbas Buenas",
        "code": "07408",
        "provincia": "Linares",
        "region": "Región del Maule",
        "region_number": "VII",
        "region_iso_3166_2": "CL-ML"
    },
    {
        "nombre": "Concepción",
        "code": "08101",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Coronel",
        "code": "08102",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Chiguayante",
        "code": "08103",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Florida",
        "code": "08104",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Hualqui",
        "code": "08105",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Lota",
        "code": "08106",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Penco",
        "code": "08107",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Pedro de la Paz",
        "code": "08108",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Santa Juana",
        "code": "08109",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Talcahuano",
        "code": "08110",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Tomé",
        "code": "08111",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Hualpén",
        "code": "08112",
        "provincia": "Concepción",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Lebu",
        "code": "08201",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Arauco",
        "code": "08202",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Cañete",
        "code": "08203",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Contulmo",
        "code": "08204",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Curanilahue",
        "code": "08205",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Los Álamos",
        "code": "08206",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Tirúa",
        "code": "08207",
        "provincia": "Arauco",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Los Ángeles",
        "code": "08301",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Antuco",
        "code": "08302",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Cabrero",
        "code": "08303",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Laja",
        "code": "08304",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Mulchén",
        "code": "08305",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Nacimiento",
        "code": "08306",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Negrete",
        "code": "08307",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Quilaco",
        "code": "08308",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Quilleco",
        "code": "08309",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Rosendo",
        "code": "08310",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Santa Bárbara",
        "code": "08311",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Tucapel",
        "code": "08312",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Yumbel",
        "code": "08313",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Alto Biobío",
        "code": "08314",
        "provincia": "Biobío",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Chillán",
        "code": "08401",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Bulnes",
        "code": "08402",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Cobquecura",
        "code": "08403",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Coelemu",
        "code": "08404",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Coihueco",
        "code": "08405",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Chillán Viejo",
        "code": "08406",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "El Carmen",
        "code": "08407",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Ninhue",
        "code": "08408",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Ñiquén",
        "code": "08409",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Pemuco",
        "code": "08410",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Pinto",
        "code": "08411",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Portezuelo",
        "code": "08412",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Quillón",
        "code": "08413",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Quirihue",
        "code": "08414",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Ránquil",
        "code": "08415",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Carlos",
        "code": "08416",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Fabián",
        "code": "08417",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Ignacio",
        "code": "08418",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "San Nicolás",
        "code": "08419",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Treguaco",
        "code": "08420",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Yungay",
        "code": "08421",
        "provincia": "Ñuble",
        "region": "Región del Biobío",
        "region_number": "VIII",
        "region_iso_3166_2": "CL-BI"
    },
    {
        "nombre": "Temuco",
        "code": "09101",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Carahu",
        "code": "09102",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Cunco",
        "code": "09103",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Curarrehue",
        "code": "09104",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Freire",
        "code": "09105",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Galvarin",
        "code": "09106",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Gorbea",
        "code": "09107",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Lautaro",
        "code": "09108",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Loncoche",
        "code": "09109",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Melipeuco",
        "code": "09110",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Nueva Imperial",
        "code": "09111",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Padre las Casa",
        "code": "09112",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Perquenco",
        "code": "09113",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Pitrufquén",
        "code": "09114",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Pucón",
        "code": "09115",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Saavedra",
        "code": "09116",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Teodoro Schmid",
        "code": "09117",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Toltén",
        "code": "09118",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Vilcún",
        "code": "09119",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Villarrica",
        "code": "09120",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Cholchol",
        "code": "09121",
        "provincia": "Cautín",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Angol",
        "code": "09201",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Collipulli",
        "code": "09202",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Curacautín",
        "code": "09203",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Ercilla",
        "code": "09204",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Lonquimay",
        "code": "09205",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Los Sauces",
        "code": "09206",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Lumaco",
        "code": "09207",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Purén",
        "code": "09208",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Renaico",
        "code": "09209",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Traiguén",
        "code": "09210",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Victoria",
        "code": "09211",
        "provincia": "Malleco",
        "region": "Región de la Araucanía",
        "region_number": "IX",
        "region_iso_3166_2": "CL-AR"
    },
    {
        "nombre": "Valdivia",
        "code": "14101",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Corral",
        "code": "14102",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Lanco",
        "code": "14103",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Los Lagos",
        "code": "14104",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Máfil",
        "code": "14105",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Mariquina",
        "code": "14106",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Paillaco",
        "code": "14107",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Panguipulli",
        "code": "14108",
        "provincia": "Valdivia",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "La Unión",
        "code": "14201",
        "provincia": "Ranco",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Futrono",
        "code": "14201",
        "provincia": "Ranco",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Lago Ranco",
        "code": "14201",
        "provincia": "Ranco",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Río Bueno",
        "code": "14201",
        "provincia": "Ranco",
        "region": "Región de los Ríos",
        "region_number": "XIV",
        "region_iso_3166_2": "CL-LR"
    },
    {
        "nombre": "Puerto Montt",
        "code": "10101",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Calbuco",
        "code": "10102",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Cochamó",
        "code": "10103",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Fresia",
        "code": "10104",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Frutillar",
        "code": "10105",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Los Muermos",
        "code": "10106",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Llanquihue",
        "code": "10107",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Maullín",
        "code": "10108",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Puerto Varas",
        "code": "10109",
        "provincia": "Llanquihue",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Castro",
        "code": "10201",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Ancud",
        "code": "10202",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Chonchi",
        "code": "10203",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Curaco de Vélez",
        "code": "10204",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Dalcahue",
        "code": "10205",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Puqueldón",
        "code": "10206",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Queilén",
        "code": "10207",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Quellón",
        "code": "10208",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Quemchi",
        "code": "10209",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Quinchao",
        "code": "10210",
        "provincia": "Chiloé",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Osorno",
        "code": "10301",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Puerto Octay",
        "code": "10302",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Purranque",
        "code": "10303",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Puyehue",
        "code": "10304",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Río Negro",
        "code": "10305",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "San Juan de la Costa",
        "code": "10306",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "San Pablo",
        "code": "10307",
        "provincia": "Osorno",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Chaitén",
        "code": "10401",
        "provincia": "Palena",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Futaleufú",
        "code": "10402",
        "provincia": "Palena",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Hualaihué",
        "code": "10403",
        "provincia": "Palena",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Palena",
        "code": "10404",
        "provincia": "Palena",
        "region": "Región de los Lagos",
        "region_number": "X",
        "region_iso_3166_2": "CL-LL"
    },
    {
        "nombre": "Coihaique",
        "code": "11101",
        "provincia": "Coihaique",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Lago Verde",
        "code": "11102",
        "provincia": "Coihaique",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Aisén",
        "code": "11201",
        "provincia": "Aisén",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Cisnes",
        "code": "11202",
        "provincia": "Aisén",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Guaitecas",
        "code": "11203",
        "provincia": "Aisén",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Cochrane",
        "code": "11301",
        "provincia": "Capitán Pratt",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "O’Higgins",
        "code": "11302",
        "provincia": "Capitán Pratt",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Tortel",
        "code": "11303",
        "provincia": "Capitán Pratt",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Chile Chico",
        "code": "11401",
        "provincia": "General Carrera",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Río Ibáñez",
        "code": "11402",
        "provincia": "General Carrera",
        "region": "Región Aisén del Gral. Carlos Ibañez del Campo",
        "region_number": "XI",
        "region_iso_3166_2": "CL-AI"
    },
    {
        "nombre": "Punta Arenas",
        "code": "12101",
        "provincia": "Magallanes",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Laguna Blanca",
        "code": "12102",
        "provincia": "Magallanes",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Río Verde",
        "code": "12103",
        "provincia": "Magallanes",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "San Gregorio",
        "code": "12104",
        "provincia": "Magallanes",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Cabo de Hornos (Ex. Navarino)",
        "code": "12201",
        "provincia": "Antártica Chilena",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Antártica",
        "code": "12202",
        "provincia": "Antártica Chilena",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Porvenir",
        "code": "12301",
        "provincia": "Tierra del Fuego",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Primavera",
        "code": "12302",
        "provincia": "Tierra del Fuego",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Timaukel",
        "code": "12303",
        "provincia": "Tierra del Fuego",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Natales",
        "code": "12401",
        "provincia": "Última Esperanza",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Torres del Paine",
        "code": "12402",
        "provincia": "Última Esperanza",
        "region": "Región de Magallanes y de la Antártica Chilena",
        "region_number": "XII",
        "region_iso_3166_2": "CL-MA"
    },
    {
        "nombre": "Santiago",
        "code": "13101",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Cerrillos",
        "code": "13102",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Cerro Navia",
        "code": "13103",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Conchalí",
        "code": "13104",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "El Bosque",
        "code": "13105",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Estación Central",
        "code": "13106",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Huechuraba",
        "code": "13107",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Independencia",
        "code": "13108",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "La Cisterna",
        "code": "13109",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "La Florida",
        "code": "13110",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "La Granja",
        "code": "13111",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "La Pintana",
        "code": "13112",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "La Reina",
        "code": "13113",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Las Condes",
        "code": "13114",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII"
    },
    {
        "nombre": "Lo Barnechea",
        "code": "13115",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Lo Espejo",
        "code": "13116",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Lo Prado",
        "code": "13117",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Macul",
        "code": "13118",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Maipú",
        "code": "13119",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Ñuñoa",
        "code": "13120",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Pedro Aguirre Cerda",
        "code": "13121",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Peñalolén",
        "code": "13122",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Providencia",
        "code": "13123",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Pudahuel",
        "code": "13124",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Quilicura",
        "code": "13125",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Quinta Normal",
        "code": "13126",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Recoleta",
        "code": "13127",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Renca",
        "code": "13128",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San Joaquín",
        "code": "13129",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San Miguel",
        "code": "13130",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San Ramón",
        "code": "13131",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Vitacura",
        "code": "13132",
        "provincia": "Santiago",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Puente Alto",
        "code": "13201",
        "provincia": "Cordillera",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Pirque",
        "code": "13202",
        "provincia": "Cordillera",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San José de Maipo",
        "code": "13203",
        "provincia": "Cordillera",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Colina",
        "code": "13301",
        "provincia": "Chacabuco",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Lampa",
        "code": "13302",
        "provincia": "Chacabuco",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Tiltil",
        "code": "13303",
        "provincia": "Chacabuco",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San Bernardo",
        "code": "13401",
        "provincia": "Maipo",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Buin",
        "code": "13402",
        "provincia": "Maipo",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Calera de Tango",
        "code": "13403",
        "provincia": "Maipo",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Paine",
        "code": "13404",
        "provincia": "Maipo",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Melipilla",
        "code": "13501",
        "provincia": "Mellipilla",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Alhué",
        "code": "13502",
        "provincia": "Mellipilla",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Curacaví",
        "code": "13503",
        "provincia": "Mellipilla",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "María Pinto",
        "code": "13504",
        "provincia": "Mellipilla",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "San Pedro",
        "code": "13505",
        "provincia": "Mellipilla",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Talagante",
        "code": "13601",
        "provincia": "Talagante",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "El Monte",
        "code": "13602",
        "provincia": "Talagante",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Isla de Maipo",
        "code": "13603",
        "provincia": "Talagante",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Padre Hurtado",
        "code": "13604",
        "provincia": "Talagante",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    },
    {
        "nombre": "Peñaflor",
        "code": "13605",
        "provincia": "Talagante",
        "region": "Región Metropolitana de Santiago",
        "region_number": "XIII",
        "region_iso_3166_2": "CL-RM"
    }
]

export const Regiones = {
    "regiones": [
    {
        "region": "Región Metropolitana de Santiago",
        "comunas": ["Cerrillos", "Cerro Navia", "Conchalí", "El Bosque", "Estación Central", "Santiago Centro", "Huechuraba", "Independencia", "La Cisterna", "La Florida", "La Granja", "La Pintana", "La Reina", "Las Condes", "Lo Barnechea", "Lo Espejo", "Lo Prado", "Macul", "Maipú", "Ñuñoa", "Pedro Aguirre Cerda", "Peñalolén", "Providencia", "Pudahuel", "Quilicura", "Quinta Normal", "Recoleta", "Renca", "San Joaquín", "San Miguel", "San Ramón", "Vitacura", "Puente Alto", "Pirque", "San José de Maipo", "Colina", "Lampa", "Tiltil", "San Bernardo", "Buin", "Calera de Tango", "Paine", "Melipilla", "Alhué", "Curacaví", "María Pinto", "San Pedro", "Talagante", "El Monte", "Isla de Maipo", "Padre Hurtado", "Peñaflor"]
    },   
    {
        "region": "Región de Arica y Parinacota",
        "comunas": ["Arica", "Camarones", "Putre", "General Lagos"]
    },
    {
        "region": "Región de Tarapacá",
        "comunas": ["Iquique", "Alto Hospicio", "Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"]
    },
    {
        "region": "Región de Antofagasta",
        "comunas": ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal", "Calama", "Ollagüe", "San Pedro de Atacama", "Tocopilla", "María Elena"]
    },
    {
        "region": "Región de Atacama",
        "comunas": ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Alto del Carmen", "Freirina", "Huasco"]
    },
    {
        "region": "Región de Coquimbo",
        "comunas": ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Paiguano", "Vicuña", "Illapel", "Canela", "Los Vilos", "Salamanca", "Ovalle", "Combarbalá", "Monte Patria", "Punitaqui", "Río Hurtado"]
    },
    {
        "region": "Región de Valparaíso",
        "comunas": ["Valparaíso", "Casablanca", "Concón", "Juan Fernández", "Puchuncaví", "Quintero", "Viña del Mar", "Isla de Pascua", "Los Andes", "Calle Larga", "Rinconada", "San Esteban", "La Ligua", "Cabildo", "Papudo", "Petorca", "Zapallar", "Quillota", "Calera", "Hijuelas", "La Cruz", "Nogales", "San Antonio", "Algarrobo", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "San Felipe", "Catemu", "Llaillay", "Panquehue", "Putaendo", "Santa María", "Quilpué", "Limache", "Olmué", "Villa Alemana"]
    },
    {
        "region": "Región del Libertador General Bernardo O’Higgins",
        "comunas": ["Rancagua", "Codegua", "Coinco", "Coltauco", "Doñihue", "Graneros", "Las Cabras", "Machalí", "Malloa", "Mostazal", "Olivar", "Peumo", "Pichidegua", "Quinta de Tilcoco", "Rengo", "Requínoa", "San Vicente", "Pichilemu", "La Estrella", "Litueche", "Marchihue", "Navidad", "Paredones", "San Fernando", "Chépica", "Chimbarongo", "Lolol", "Nancagua", "Palmilla", "Peralillo", "Placilla", "Pumanque", "Santa Cruz"]
    },
    {
        "region": "Región del Maule",
        "comunas": ["Talca", "Constitución", "Curepto", "Empedrado", "Maule", "Pelarco", "Pencahue", "Río Claro", "San Clemente", "San Rafael", "Cauquenes", "Chanco", "Pelluhue", "Curicó", "Hualañé", "Licantén", "Molina", "Rauco", "Romeral", "Sagrada Familia", "Teno", "Vichuquén", "Linares", "Colbún", "Longaví", "Parral", "Retiro", "San Javier", "Villa Alegre", "Yerbas Buenas"]
    },
    {
        "region": "Región del Bío Bío",
        "comunas": ["Concepción", "Coronel", "Chiguayante", "Florida", "Hualqui", "Lota", "Penco", "San Pedro de la Paz", "Santa Juana", "Talcahuano", "Tomé", "Hualpén", "Lebu", "Arauco", "Cañete", "Contulmo", "Curanilahue", "Los Álamos", "Tirúa", "Los Ángeles", "Antuco", "Cabrero", "Laja", "Mulchén", "Nacimiento", "Negrete", "Quilaco", "Quilleco", "San Rosendo", "Santa Bárbara", "Tucapel", "Yumbel", "Alto Biobío", "Chillán", "Bulnes", "Cobquecura", "Coelemu", "Coihueco", "Chillán Viejo", "El Carmen", "Ninhue", "Ñiquén", "Pemuco", "Pinto", "Portezuelo", "Quillón", "Quirihue", "Ránquil", "San Carlos", "San Fabián", "San Ignacio", "San Nicolás", "Treguaco", "Yungay"]
    },
    {
        "region": "Región de la Araucanía",
        "comunas": ["Temuco", "Carahue", "Cunco", "Curarrehue", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Melipeuco", "Nueva Imperial", "Padre las Casas", "Perquenco", "Pitrufquén", "Pucón", "Saavedra", "Teodoro Schmidt", "Toltén", "Vilcún", "Villarrica", "Cholchol", "Angol", "Collipulli", "Curacautín", "Ercilla", "Lonquimay", "Los Sauces", "Lumaco", "Purén", "Renaico", "Traiguén", "Victoria"]
    },
    {
        "region": "Región de los Ríos",
        "comunas": ["Valdivia", "Corral", "Lanco", "Los Lagos", "Máfil", "Mariquina", "Paillaco", "Panguipulli", "La Unión", "Futrono", "Lago Ranco", "Río Bueno"]
    },
    {
        "region": "Región de los Lagos",
        "comunas": ["Puerto Montt", "Calbuco", "Cochamó", "Fresia", "Frutillar", "Los Muermos", "Llanquihue", "Maullín", "Puerto Varas", "Castro", "Ancud", "Chonchi", "Curaco de Vélez", "Dalcahue", "Puqueldón", "Queilén", "Quellón", "Quemchi", "Quinchao", "Osorno", "Puerto Octay", "Purranque", "Puyehue", "Río Negro", "San Juan de la Costa", "San Pablo", "Chaitén", "Futaleufú", "Hualaihué", "Palena"]
    },
    {
        "region": "Región de Aysén del General Carlos Ibáñez del Campo",
        "comunas": ["Coihaique", "Lago Verde", "Aisén", "Cisnes", "Guaitecas", "Cochrane", "O’Higgins", "Tortel", "Chile Chico", "Río Ibáñez"]
    },
    {
        "region": "Región de Magallanes y de la Antártica Chilena",
        "comunas": ["Punta Arenas", "Laguna Blanca", "Río Verde", "San Gregorio", "Cabo de Hornos (Ex Navarino)", "Antártica", "Porvenir", "Primavera", "Timaukel", "Natales", "Torres del Paine"]
    }
    ]}