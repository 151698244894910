import React, { Component } from 'react';
import { Card, Button } from 'antd';
const { Meta } = Card;


const translations = {
    "2" : "Completar propiedad",
    "3" : "Agendar fotografías",
    "4" : "Reagendar fotografías",
    "5" : "Buscando arrendatarios",
    "6" : "Elegir arrendatario",
    "7" : "Pagar",
    "8" : "Pagar",
    "9" : "Arrendada",
    "10" : "Moroso",
    "11" : "En proceso de demanda"
}

class PropertyCard extends Component {


    render() {
        if(this.props.placeholder) 
        
        return (
            <Card onClick={this.props.onClick} hoverable
            bodyStyle={{ display:'block' }}
            actions={ [ <Button type="primary" block >Crear</Button> ] }
            cover={<img alt="example" src="https://www.sentrycommercial.com/wp-content/themes/sentry/img/property_placeholder.jpg" />} >
                <Meta
                title='Crear una nueva propiedad'
                />
                </Card>
        ) 
        else
        return (
            <Card onClick={this.props.onClick} hoverable
            bodyStyle={{ display:'block' }}
            actions={ [ <Button type="primary" block > {translations[this.props.item.status.id]}</Button> ] }
            cover={<img alt="example" src="https://www.sentrycommercial.com/wp-content/themes/sentry/img/property_placeholder.jpg" />} >
                <Meta
                title={this.props.item.address + ' ' + this.props.item.street_number }
                description={ this.props.item.numero_depto ? "Departamento: " +  this.props.item.numero_depto : <br/> }
                />
                    <p style={{fontWeight:'bold', color:'gray'}}>{this.props.item.comuna}</p>
                </Card>
                )
    }
}

export default PropertyCard;
