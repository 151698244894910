/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './comoFunciona.css';
import '../ResetFont.css';
import './comoFuncionaMobile.css';
import './comoFuncionaTablet.css';
import './comoFuncionaDesktop.css';

/*Imagenes */
import Paso1 from '../../../assets/img/paso1.svg';
import Paso2 from '../../../assets/img/paso2.svg';
import Paso3 from '../../../assets/img/paso3.svg';
import Paso4 from '../../../assets/img/paso4.svg';

class ComoFunciona extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">        
        <Row className="pasosContainer">
          <h1 className="tituloPasos comoFunciona">¿Cómo Funciona?</h1>
          <Row className="pasosMobile">
            <Col span={3} >
              <div id="columnaIconosPasos">
                <div className="verticalLinePasos"></div>
                <div className="circleStepsMobile circleStep1">
                  <div className="circleStepInnerMobile"></div>
                </div>
                <div className="horizontalLinePasos linea1"></div>
                <div className="circleStepsMobile circleStep2">
                  <div className="circleStepInnerMobile"></div>
                </div>
                <div className="horizontalLinePasos linea2"></div>
                <div className="circleStepsMobile circleStep3">
                  <div className="circleStepInnerMobile"></div>
                </div>
                <div className="horizontalLinePasos linea3"></div>
                <div className="circleStepsMobile circleStep4">
                  <div className="circleStepInnerMobile"></div>
                </div>
                <div className="horizontalLinePasos linea4"></div>
              </div>
            </Col>
            <Col span={9} >
              <div className="columnaTextosPasos">
                <div className="textContainerPasos">
                  <div className="circleIconMobile">
                    <img className="iconMobile" src={Paso1} alt="Primer paso: registra tu propiedad" />
                  </div>
                  <p className="textoPasos">
                    Registra tu propiedad y la subiremos grara los mejores portales del país sin costo! 
                  </p>
                </div>
                <div className="textContainerPasos">
                  <div className="circleIconMobile">
                    <img className="iconMobile" src={Paso2} alt="Segundo paso: agenda la toma de fotografías profesionales de tu propiedad" />
                  </div>
                  <p className="textoPasos">
                    Programa una sesión de fotos gratis con nuestros expertos
                  </p>
                </div>
                <div className="textContainerPasos">
                  <div className="circleIconMobile">
                    <img className="iconMobile" src={Paso3} alt="Tercer paso: Te ayudamos a encontrar al mejor arrendatario" />
                  </div>
                  <p className="textoPasos">
                    Empieza a recibir candidatos y te ayudamos a escoger al mejor arrendatario
                  </p>
                </div>
                <div className="textContainerPasos">
                  <div className="circleIconMobile">
                    <img className="iconMobile" src={Paso4} alt="Cuarto paso: Descarga tu contrato listo para firmar en notaría" />
                  </div>
                  <p className="textoPasos">
                    Genera el contrato en línea y recibe tus pagos SIEMPRE!
                  </p>  
                </div>
              </div>
            </Col>
            </Row>

            {/* VERSION DESKTOP */}
            <div className="pasosDesktop">
              <div>
                  <div id="columnaIconosPasos">
                    <div className="verticalLinePasos"></div>
                    <div className="circleStepsMobile circleStep1">
                      <div className="circleStepInnerMobile"></div>
                    </div>
                    <div className="horizontalLinePasos linea1"></div>
                    <div className="circleStepsMobile circleStep2">
                      <div className="circleStepInnerMobile"></div>
                    </div>
                    <div className="horizontalLinePasos linea2"></div>
                    <div className="circleStepsMobile circleStep3">
                      <div className="circleStepInnerMobile"></div>
                    </div>
                    <div className="horizontalLinePasos linea3"></div>
                    <div className="circleStepsMobile circleStep4">
                      <div className="circleStepInnerMobile"></div>
                    </div>
                    <div className="horizontalLinePasos linea4"></div>
                  </div>
               
              </div>
              <div>
                  <div className="columnaTextosPasos">
                    <div className="textContainerPasos">
                      <div className="circleIconMobile">
                        <img className="iconMobile" src={Paso1} alt="Primer paso: registra tu propiedad" />
                      </div>
                      <p className="textoPasos">
                        Registra tu propiedad y la subiremos grara los mejores portales del país sin costo! 
                      </p>
                    </div>
                    <div className="textContainerPasos">
                      <div className="circleIconMobile">
                        <img className="iconMobile" src={Paso2} alt="Segundo paso: agenda la toma de fotografías profesionales de tu propiedad" />
                      </div>
                      <p className="textoPasos">
                        Programa una sesión de fotos gratis con nuestros expertos
                      </p>
                    </div>
                    <div className="textContainerPasos">
                      <div className="circleIconMobile">
                        <img className="iconMobile" src={Paso3} alt="Tercer paso: Te ayudamos a encontrar al mejor arrendatario" />
                      </div>
                      <p className="textoPasos">
                        Empieza a recibir candidatos y te ayudamos a escoger al mejor arrendatario
                      </p>
                    </div>
                    <div className="textContainerPasos">
                      <div className="circleIconMobile">
                        <img className="iconMobile" src={Paso4} alt="Cuarto paso: Descarga tu contrato listo para firmar en notaría" />
                      </div>
                      <p className="textoPasos">
                        Genera el contrato en línea y recibe tus pagos SIEMPRE!
                      </p>  
                    </div>
                  </div>
                
              </div>
              </div>
          <Button className="btnPrincipal btnPrincipalPasos btnIntro" type="primary"><Link to="/lead">Publica tu propiedad</Link></Button>
        </Row>
      </div>      
    );
  }
}

export default ComoFunciona;
