import React, { Component } from 'react';
import { Row, Col, Card, Button, Divider } from 'antd';

import ImageUpload from './partials/ImageUpload';
import PropertyForm from './partials/PropertyForm';
import PropertyDetail from './partials/PropertyDetail';
import ListingDetail from './partials/ListingDetail';
import SelectListing from './partials/SelectListing';
import { propertyService } from '../../../../services/propertyService';

/*CSS */
import './info2.css';
import './alerts.css';


class PropertyStoreDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      properties: [],
      loading_step: false,
      step: 1
    }
  }

  async componentDidMount() {

    let id = this.props.match.params.id
    let property = await propertyService.get(id)
    if (property) {

    }

  }


  render() {
    return (
      <div style={{ width: '90%', margin: '0 auto' }} >
        <Row className="admin-header" gutter={16}>
          <Col xs={17} span={20}>
            <h1 className="tituloInfo1">Nueva propiedad</h1>
          </Col>
          {/* <Col xs={6} span={4}>
                    <Button onClick={()=>this.back()} type="default">Cancelar</Button>
                </Col> */}
          <Divider />
        </Row>

        <div className="paso1">

          <Row gutter={50} >
            {/*                 <Col md={12} xs={24}>
                        <ImageUpload/>
                    </Col> */}
            <Col md={6} xs={0} ></Col>

            <Col md={12} xs={24} >
              <PropertyDetail {...this.props} uid={this.props.match.params.id} />
            </Col>


          </Row>
        </div>
      </div>

    );
  }
}

export default PropertyStoreDetails;
