import React, { Component } from 'react';
import {
	Form, Card, Button, Radio, Input, Select, Divider
} from 'antd';

import PlaceSuggest from './PlaceSuggest';

import { Comunas, Regiones } from '../../../../Shared/partials/Address'
import ChipOptions from '../../../../Shared/partials/ChipOptions';
import SwitchOption from '../../../../Shared/partials/SwitchOption';
import { propertyService } from '../../../../../services/propertyService';

import name from '../pregunta.css';
import { updateData } from '../../../../../utils/intercom';

const Option = Select.Option;
const { TextArea } = Input;

const selectAfter = (
	<Select defaultValue="pesos" style={{ width: 80 }}>
		<Option value="pesos">pesos</Option>
		<Option value="UF">UF</Option>
	</Select>
);

class SelectListing extends Component {
	state = {
	};


	onChange = (e) => {
		this.setState({
			type: e.target.value,
		});
	}

	componentDidMount() {

	}

	onRegionChange = (e) => {
		let comunas = Regiones.regiones.filter((item) => {
			return item.region === e
		})
		this.setState({ region: e, comuna: comunas[0].comunas })
	}

	onComunaChange = (e) => {
		this.setState({ comuna: e })
	}

	async listing() {
		let response = await propertyService.listMyProperty(this.props.match.params.id)
		if (response) {
            let user = JSON.parse(localStorage.getItem('user')) 
            user.intercomstage = 4
            user.pipedrivestage = 195
            user.nombrestage = 'COORDINAR_FOTO'
            user.corretaje = true
            updateData(user)
			this.props.history.push('/propietary/properties/' + this.props.match.params.id + '/schedule')
		}

	}

	async noListing() {
		let response = await propertyService.dontListMyProperty(this.props.match.params.id)
		if (response) {
            let user = JSON.parse(localStorage.getItem('user')) 
            user.intercomstage = 6
            user.pipedrivestage = 190
            user.nombrestage = 'BUSCAR_ARRENDATARIO'
            user.corretaje = false
            updateData()
			this.props.history.push('/propietary/properties/' + this.props.match.params.id)
		}

	}

	render() {

		let comunas = Regiones.regiones.filter((item) => {
			return item.region.includes(this.state.region)
		})

		return <div style={{maxWidth:'500px', margin:'0 auto'}} className="preguntaContainer">
			<h1 className="tituloInfo1 tituloPregunta">¿Quieres que encontremos a tu arrendatario?</h1>
			<Card style={{ border: '0px' }}>
				<Form layout="vertical" className="cardsPreguntaContainer" >
					<Form.Item>
						<div className="formitem">
							<div className="cards cardPregunta cardGris">
								<h3>No gracias, <br/> yo lo hago por mi <br/> propia cuenta y <br/> con mucho esfuerzo... </h3>
								<Button className="btnSecundario btnNoCorretaje" type="default" onClick={() => this.noListing()} loading={this.state.loading_step} block>Elegir</Button>
							</div>
						</div>
					</Form.Item>
					<Form.Item>
						<div className="formitem">
							<div className="cards cardPregunta cardOscura">
								<h3 className="txtFondoOscuro">Quiero que publiquen mi propiedad <br/> en todos los portales.</h3>
								<Button className="btnPrincipal btnCorretaje" type="default" onClick={() => this.listing()} loading={this.state.loading_step} block>Elegir</Button>
							</div>
						</div>
					</Form.Item>
				</Form>
			</Card>
					<h2 className="txtBeneficioPregunta txtFondoBlanco">
						Recuerda que no pagas nada hasta que el arrendatario entra al departamento! 
					</h2>


		</div>
	}
}

export default SelectListing;
