import React, { Component } from 'react';
import ApplicantCard from './partials/ApplicantCard';
import { List, Row, Col, Button, Divider, Input, PageHeader } from 'antd';

import { propertyService } from '../../../../services/propertyService'

class InviteForm extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        properties : []
    }
  }

  async componentDidMount(){
    let request = await propertyService.index()
    if(request ){
      this.setState({
        loading:false,
        properties: request.data
      })
    }
  }


  create = ()=>{
    this.props.history.push('properties/create')
  }
  

    render() {
        return  <div style={{ padding:'4%', margin:'0 auto'}} >

          <Row>
            <PageHeader
              onBack={() => this.props.history.goBack() }
              title="Volver a la propiedad"
            />
          </Row>
          
        <Row>
          <Col style={{heigth:'100%'}} xs={24} md={8}>
            <Row type="flex" style={{textAlign:'center', paddingTop:'30px'}}>
              <img width="200" height="200" style={{margin:'0 auto'}}  src = "https://pngimage.net/wp-content/uploads/2018/05/buz%C3%B3n-png-1.png" alt="email-icon" />
            </Row>
          </Col>

             <Col xs={24} md={12}  >
              <Row style={{paddingTop:'10%'}}>
                <h2>Invita a un arrendatario a postular a tu propiedad</h2>

                <p style={{lineHeight:'1.5'}}>Sólo necesitas ingresar su correo electrónico y nosotros le solicitaremos
                  toda la información necesaria. </p>
              </Row>
              <Row className="inputInviteRow">
                <Col xs={24} >
                  <Input placeholder="email@ejemplo.com" />
                </Col>
                <Col xs={24} style={{textAlign:'center'}} >
                <Button className="btnPrincipal" type="primary" >Invitar</Button>
                </Col>
              </Row>
            </Col>

            </Row>
        </div>
    }
}

export default InviteForm;
