import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select, Divider
  } from 'antd';

import PlaceSuggest from './PlaceSuggest';

import {Comunas, Regiones} from '../../../../Shared/partials/Address'
import ChipOptions from '../../../../Shared/partials/ChipOptions';
import SwitchOption from '../../../../Shared/partials/SwitchOption';

const Option = Select.Option;
const { TextArea } = Input;

const selectAfter = (
    <Select defaultValue="pesos" style={{ width: 80 }}>
      <Option value="pesos">pesos</Option>
      <Option value="UF">UF</Option>
    </Select>
  );

class ListingDetail extends Component {
    state = {
    };
    
    
    onChange = (e) => {
        this.setState({
          type: e.target.value,
        });
    }

    onRegionChange = (e)=>{
        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region===e
        })
        this.setState({region:e, comuna:comunas[0].comunas})
    }
  
    onComunaChange = (e)=>{
        this.setState({comuna:e})
    }

    render() {

        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region.includes(this.state.region)
        })

        return <div>

            <Card style={{border:'0px'}}>
                <Form layout="vertical" >
                    <Form.Item>
                        <div className="formitem">
                            <p>Canon de arriendo <span style={{fontWeight:100}}>(No debes incluir gastos comunes)</span></p>
                            <div style={{textAlign:'center'}}>
                                <Input addonAfter={ selectAfter } placeholder="800.000" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Divider/>
                    <Form.Item>
                        <div className="formitem">
                            <p>Gastos comunes <span style={{fontWeight:100}}>(Aproximado mensual, en pesos)</span></p>
                            <div style={{textAlign:'center'}}>
                                <Input  placeholder="65.000" defaultValue="" />
                            </div>
                        </div>
                        
                    </Form.Item>
                    <Divider/>
                    <Form.Item>
                        <div className="formitem">
                            <p>Descripción <span style={{fontWeight:100}}>Más información sobre tu inmueble</span></p>
                            <div style={{textAlign:'center'}}>
                                <TextArea rows={6} />
                            </div>
                        </div>

                    </Form.Item>
                    <Divider/>   
                </Form>
            </Card>


        </div>
    }
}

export default ListingDetail;
