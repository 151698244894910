import React, { Component } from 'react';
import { Card, Button, Row, Col, Tag, Icon, Divider } from 'antd';

import { schedulerService } from '../../../../../services/schedulerService';

import moment from 'moment'
import 'moment/locale/es';
import { updateData } from '../../../../../utils/intercom';

import '../agendarFotos.css'

moment().locale('es');
//  moment.updateLocale('es',{
//   weekdays: [
//       "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes","Sábado"
//   ]
//  } )




class SchedulePhotos extends Component {


    state = {
      available:{}
    };
    
    
    onChange = (e) => {
        this.setState({
          type: e.target.value,
        });
    }

    async componentDidMount(){
        updateData({})
        let available = await schedulerService.getHours( moment.tz.guess() )
        if(available){
          
          this.setState({available:available.data});

          
        }
    }


    renderDays(){
      
      return Object.entries(this.state.available).map( (item)=>{
        let selectedDay = this.state.selectedDay
          
        return <Col xs={6} sm={4} className="dayCardCol" onClick={()=>this.setState({selectedDay:item[0], selectedHour:null }) }  >
          <Tag  className={  selectedDay === item[0] ?  ('cards dayCard dayCardActive'):('cards dayCard') }>
            <div className="dayName">
              {moment(item[0],'YYYY-MM-DD').format('dddd')}
            </div>
            <div className="dayNumber">
              {moment(item[0],'YYYY-MM-DD').format('DD')}
            </div>
          </Tag>
        </Col>
      } )
    }

    showDayAM(){
      return this.state.available[this.state.selectedDay].map( (item)=>{
        let selectedHour = this.state.selectedHour
        if(moment(item).format('A') === 'AM'){
          //console.log(moment(item).format('A HH:mm'))
          return(
            <Col xs={6} sm={4} onClick={()=>this.setState({selectedHour:item}) } >
              <div  style={selectedHour === item ? {backgroundColor:'lightgray', border:'1px solid lightgray'}:{border:'1px solid lightgray'} }>
                {moment(item).format('HH:mm')}
              </div>
            </Col>
          ) 
        }
      } )
    }

    showDayPM(){
      return this.state.available[this.state.selectedDay].map( (item)=>{
        let selectedHour = this.state.selectedHour
        if(moment(item).format('A') === 'PM'){
          //console.log(moment(item).format('A HH:mm'))
          return(
            <Col xs={6} sm={4} onClick={()=>this.setState({selectedHour:item}) } >
              <div  style={selectedHour === item ? {backgroundColor:'lightgray', border:'1px solid lightgray'}:{border:'1px solid lightgray'} }>
                {moment(item).format('HH:mm')}
              </div>
            </Col>
          ) 
        }
      } )
    }

    async schedule(){
      let dat = moment(this.state.selectedHour)
      let res = await schedulerService.schedule({
        date: dat.format(),
        property_uid : this.props.match.params.id
      })
      if(res){
        let user = JSON.parse(localStorage.getItem('user'))
        user.intercomstage = 5
        user.pipedrivestage = 196
        user.nombrestage = 'PUBLICAR_PROPIEDAD'
        updateData(user)
        this.props.history.push('/propietary/properties/' + this.props.match.params.id )
      }else{
        alert('falló el agendamiento')
      }
    }



    render() {

        return  <div style={{maxWidth:'500px', margin:'0 auto', padding:'3%'}}>
            <Row>
              <h1 style={{marginTop:'10px',marginBottom:'10px'}}>Agendar fotos </h1>
              <Divider />
              <h2 className="txtFondoBlanco" style={{marginTop:'10px',marginBottom:'10px'}}>
                Selecciona una fecha para tomar las fotos
              </h2>
              <h2 className="monthName txtFondoOscuro">
                Mes de {moment().format('MMMM')} 
              </h2>
              <div className="daysContainer">
                {this.renderDays()}
              </div>
            </Row>
            <Row>
            {this.state.selectedDay ?
              <div>
                <h2 className="selecTimeTitle txtFondoBlanco" style={{marginTop:'10px',marginBottom:'10px', textAlign:'center'}}>
                <Icon type="clock-circle" /> Selecciona la hora 
                </h2>
                <div className="amHorarioContainer">
                  <h3 className="timeInfo">Horarios AM</h3>
                  {this.showDayAM()}
                </div>
                <div className="pmHorarioContainer">
                  <h3 className="timeInfo">Horarios PM</h3>
                  {this.showDayPM()}
                </div>
              </div> : null
            }
            </Row>

            <Row style={{textAlign:'center'}}>
              {
                this.state.selectedDay && this.state.selectedHour ?
                <Button style={{marginTop:'30px' }} className="btnPrincipal" type="primary" onClick={()=>this.schedule()}>Agendar</Button>:null
              }
            </Row>
        </div> 
    }
}

export default SchedulePhotos;
