import React, { Component } from 'react';
import PropertyCard from './partials/PropertyCard';
import { List, Row, Col, Button, Divider, Card } from 'antd';
import { Link } from "react-router-dom";

import { propertyService } from '../../../../services/propertyService';
import PropertyTitle from './showpartials/PropertyTitle';
import Applicants from './showpartials/Applicants';
import BankAccount from './showpartials/BankAccount';
import SchedulePhotos from './partials/SchedulePhotos';
import PropertyDetail from './partials/PropertyDetail';
import moment from 'moment-timezone'

import './show.css';

const ReSchedule = (props) => 
                <div style={{textAlign:'center',paddingTop:'10vh'}}>
                <img src="https://cdn0.iconfinder.com/data/icons/flat-designed-circle-icon-2/1000/camera.png" height="100" alt="camera_image" style={{margin:'0 auto'}} />
                <br/>
                <h3 className="txtFondoBlanco">Tienes una hora agendada para tomar fotografías</h3>
                <h2>{ moment( props.property.property_schedule[0].date ).format('LLLL') } </h2>
                <Link to={"/propietary/properties/" + props.match.params.id + "/schedule"}>Reagendar</Link> </div>


class PropertyShow extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  async componentDidMount(){
    let request = await propertyService.get(this.props.match.params.id)
    if(request ){
      this.setState({
        loading:false,
        property: request.data
      })
    }
  }

  async updateProperty(){
      let request = await propertyService.get(this.props.match.params.id)
      if (request) {
        this.setState({
          loading: false,
          property: request.data
        })
      }
  }

  exists(item, subitem){
    return item? item[subitem] :null;
  }

  renderApplicants(  ){
    return <List
    style={{border:'1px solid lightgray', textAlign:'left', padding:'10px', marginBottom:'20px'}}
    grid={{ gutter: 16, column: 1 }}
    dataSource={this.state.property.applicants}
    renderItem={item => (
      <List.Item>
        <p > {item.name + ' ' + item.last_name} {item.approved ? <strong>Aprobado</strong> : <strong>En espera de aprobación</strong> }</p>
      </List.Item>
    )}
  />
  }


    render() {
        let property = this.state.property ? this.state.property : null

        let fullcontent;

        if(property){
          switch (property.status.id) {
            case 2:
              fullcontent = <PropertyDetail />
              break;
            case 3:
              fullcontent = <SchedulePhotos update={()=>this.updateProperty()} {...this.props} />
              break;
            case 4:
              fullcontent = <ReSchedule  {...this.props} property={property} />
              break;
            case 5:
              if(property.corretaje) fullcontent = <div>Estamos buscando tu arrendatario</div>
              else fullcontent=<div style={{textAlign:'center'}}>
              {
                property.applicants && property.applicants.length >0? <div> <h3>Tus Postulantes:</h3><br/>
                {this.renderApplicants()} </div> : null
              }
              { property.applicants && property.applicants.length >0 ?
              <Row>
                <Divider> O </Divider>
                <p>Puedes agregar más postulantes aquí</p>
              </Row>:
              <h3 className="txtFondoBlanco">Ahora debes buscar a tus arrendatarios</h3>}
  
              <Row style={{padding:'2vh'}}>
                <Button className="btnPrincipal btnInvitar" onClick={()=> this.props.history.push("/propietary/properties/" + this.props.match.params.id + "/invite") } type="primary">Invitar arrendatario</Button>
              </Row>
              <Row style={{padding:'2vh'}}>
                <Button className="btnSecundario" onClick={()=> this.props.history.push("/propietary/properties/" + this.props.match.params.id + "/apply") } type="default">Subiré yo mismo su información</Button>
              </Row>
               </div>
              break;
            case 6:
              fullcontent = <div>Estos son tus candidatos aptos, elige uno para continuar</div>
              break;
            case 7:
              fullcontent = <div>Completa tu contrato o paga</div>
              break;
            case 8:
              fullcontent = <div>Paga o cambia a tu arrendatario o tu plan</div>
              break;
            case 9:
              fullcontent = <div>Estos son los pagos recibidos, cuando hayan aparecerán aquí</div>
              break;
            default:
            
              break;
          }

        }

        return  (
        <div>


          
          <div className="header-show-image">
              <img style={{width:'100%'}} src="/house_placeholder.png" />
          </div>
          <div style={{width:'90%', margin:'0 auto'}} >

              <Row style={{marginBottom:'30px'}}>
                {
                  property ? <PropertyTitle {...this.props} property={property} /> : null
                }
              </Row>

              <Row>
     
                  {fullcontent}
             
              </Row>
              
          </div>
          <div style={{marginBottom:'60px'}}></div>
        </div> )
    }
}

export default PropertyShow;
