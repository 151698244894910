import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select
  } from 'antd';
import UploadFiles from './UploadFiles';
import ChipOptions from '../../../../Shared/partials/ChipOptions';
import { applicantService } from '../../../../../services/applicantService';
const {
    TextArea
} = Input;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

class ApplySubmit extends Component {
    state = {
        id:this.props.uid, 
        name: '', 
        last_name:'', 
        second_last_name:'', 
        rut:'', 
        email:'', 
        phone:'', 
        description:'', 
        property_id: this.props.match.params.id,
				loading:false,
				ocupation: 'Dependiente'
      };

    async submit(){
        this.setState({loading:true})
        let state = this.state
        let {id, name, last_name, second_last_name, rut, email, phone, description, property_id, ocupation} = state
        

        if (id && name && last_name && second_last_name && rut && email && phone && description && property_id && ocupation) {
            let data = {
                id : id, 
                name : name, 
                last_name : last_name, 
                second_last_name : second_last_name, 
                rut : rut,
                email: email,
                phone: phone,
                description: description,
                property_id: property_id
            }

             let res = await applicantService.store(data)
             if(res){
                     this.props.history.push('/propietary/properties/'+this.props.match.params.id )  
             }
        }else{
            alert("ingrese toda la información requerida antes de continuar");
        }


    }
    
    
    onChange = (e) => {
      
        this.setState({
          [e.target.name]: e.target.value,
        });
    }

    onNumberChange = (e) => {
       let val= e.target.value
    }


    onRegionChange = (e)=>{
        
    }


    render() {

        let dependiente = ['3 últimas liquidaciones de sueldo','Certificado de antiguedad (Opcional)', 'Contrato de trabajo (Opcional)', 'Otra documentación que justifique renta (Opcional)']
        let independiente = ['Declaración de renta F22 de Servicio de impuestos internos', 'Otra documentación que justifique renta (Opcional)']
        let codebtor = (namespace)=> <div>
                    <Form.Item>
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">Nombre {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'name'} onChange={(e)=>this.onChange(e)} value={this.state.name} placeholder="Felipe" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">Apellido paterno {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'last_name'} onChange={(e)=>this.onChange(e)} value={this.state.last_name} placeholder="Perez" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">Apellido materno {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'second_last_name'} onChange={(e)=>this.onChange(e)} value={this.state.second_last_name} placeholder="Umanzor" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">RUT {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'rut'} onChange={(e)=>this.onChange(e)} value={this.state.rut} placeholder="11.111.111-1" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item >
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">Email {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'email'} onChange={(e)=>this.onChange(e)} value={this.state.email} placeholder="email@ejemplo.com" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item>
                            <h3 className="txtFondoBlanco">Celular {namespace}</h3>
                            <div style={{textAlign:'center'}}>
                                <Input name= {namespace+'phone'} onChange={(e)=>this.onChange(e)} value={this.state.phone} placeholder="+569 95604525" defaultValue="" />
                            </div>
                    </Form.Item>

                    <Form.Item>
                            {this.props.isPropietary ?
                            <h3 className="txtFondoBlanco">Cuéntanos qué sabes sobre tu candidato</h3> :
                            <h3 className="txtFondoBlanco">Cuéntanos por qué eres el candidato ideal {namespace}<span style={{fontWeight:100}}></span></h3>
                            }
                            <div style={{textAlign:'center'}}>
                                <TextArea name= {namespace+'description'} onChange={(e)=>this.onChange(e)} value={this.state.description}  rows={4} />
                            </div>
                    </Form.Item>

        </div>

        return <div style={{maxWidth:'500px', margin:'0 auto', padding:'3%'}} >
            {this.props.isPropietary ?
                <h3 className="txtFondoBlanco">Ingresa la información de tu candidato para evaluarlo</h3> :
                <h3 className="txtFondoBlanco">Ingresa tu información</h3>
            }
            

            <Card style={{border:'0px', paddingTop:'20px'}}>
            
                <Form bodyStyle={{padding:'3%'}} {...formItemLayout} >

                    {codebtor('')}



                    <Form.Item>
                        <div className="formitem">
                            <h3 className="txtFondoBlanco">Ocupación</h3>
                              <Radio.Group name="ocupacion" defaultValue='Dependiente' onChange={(e) => this.setState({ocupation: e.target.value})} buttonStyle="solid">
                            		<div className="ocupationButtonContainer">
																	<Radio.Button clasname="btnSecundario txtBtnOcupation" value="Dependiente"><span className="txtBtnOcupation">Dependiente</span></Radio.Button>
																	<Radio.Button clasname="btnSecundario txtBtnOcupation" value="Independiente"><span className="txtBtnOcupation">Independiente</span></Radio.Button>
                            		</div>
                           		</Radio.Group>
                        </div>
                    </Form.Item>


                    

                    <Form.Item>
                        <div className="formitem">
                            <p>{ this.props.isPropietary ? 'Este arrendatario Complementa renta?' : 'Quiero complementar renta' } <span style={{fontWeight:100}}></span></p>
                            <div style={{textAlign:'center'}}>
                                <ChipOptions update={(item)=>this.setState(item) } name='codebtor' data={['No','Si']} default='No' />
                            </div>
                        </div>
                    </Form.Item>

                    {

                        this.state.codebtor && this.state.codebtor  ==='Si' ? codebtor('Codeudor') : null

                    }

                    {
                        this.state.codebtor && this.state.codebtor === 'Si' ?
                    <Form.Item>
                        <div className="formitem">
                            <p>{ this.props.isPropietary ? 'Deseas agregar otro codeudor?' : 'Quiero agregar otro codeudor' } <span style={{fontWeight:100}}></span></p>
                            <div style={{textAlign:'center'}}>
                                <ChipOptions update={(item)=>this.setState(item) } name='codebtor2' data={['No','Si']} default='No' />
                            </div>
                        </div>
                    </Form.Item> : null
                    }



                    {

                       this.state.codebtor2 && this.state.codebtor2 === 'Si' ? codebtor('Codeudor-2') : null 

                    }





                    <br/><br/>

                    <p>Subir documentos</p>
                    {
                        this.state.ocupation ==='Dependiente' ?
                        <ul>
                            {dependiente.map( (item)=><li>{item}</li> )}
                        </ul>:
                        <ul>
                            {independiente.map( (item)=><li>{item}</li> )}
                        </ul>
                    }


                    <UploadFiles uid={this.props.uid} />

                    
                    <Button type="primary" onClick={()=>this.submit()} loading={this.state.loading} style={{marginTop:'50px'}} block>Continuar</Button>

                </Form>
            </Card>


        </div>
    }
}

export default ApplySubmit;
