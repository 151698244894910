import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select, Divider, List, Row, Empty, Avatar, Table
  } from 'antd';



const Option = Select.Option;


const columns = [{
    title: 'Nombre',
    render: (record)=> record.lessor.name + ' ' + record.lessor.last_name 
  },{
      title: 'Estado',
      dataIndex: 'status'
  },
  {
      title:'Acción',
      render: (record)=>{
          if(record.status === 'Aprobado'){
              return  <Button type="primary">Elegir Candidato</Button>
          }else{
              return null
          }
      }
  }
]


class Applicants extends Component {

    applicantList(){
        let props
        return <Table 
            columns={columns}
            dataSource={this.props.applicants}
            size="small"
            style={{margin:'0 auto'}} locale={{emptyText: <Empty
                image={null}
                description={
                <span>
                    Aún no tiene postulantes
                </span>
            }
          >
            <Button type="primary">Nuevo Postulante</Button>
          </Empty> }} ></Table>
    }

    render() {
    let {property} = this.props

    return <div>
            <Card bodyStyle={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0px', paddingTop:'10px'}} style={{border:'0px'}}>
                <div style={{flex:1, textAlign:'left'}} >
                    <h3>Postulantes</h3>                
                </div>
                <div style={{flex:3, textAlign:'right'}} >
                    <p style={{fontSize:'12px'}}>Obtener enlace para compartir</p>                
                </div>

            </Card>
            <Divider style={{ margin: '3px 0'}} />
            <Card bodyStyle={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0px', paddingTop:'10px'}} style={{border:'0px'}}>
                {this.applicantList()}
            </Card>
        </div>
    }
}

export default Applicants;
