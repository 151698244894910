import React, { Component } from 'react';
import { Card } from 'antd';
const { Meta } = Card;



class ApplicantCard extends Component {

    render() {
        return <Card hoverable
        style={{ width: 240 }}>
            <Meta
            title={this.props.item.address.split(',')[0] }
            description={ this.props.item.numero_depto ? "Departamento: " +  this.props.item.numero_depto : <br/> }
            />
            <p style={{fontWeight:'bold', color:'gray'}}>{this.props.item.comuna}</p>
             </Card>
    }
}

export default ApplicantCard;
