/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button, Card, Tabs, Icon } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './prensa.css';
import '../ResetFont.css';
import './prensaMobile.css';
import './prensaTablet.css';
import './prensaDesktop.css';

import logoTele13 from '../../../assets/img/tele13.png'
import logoDiarioFinanciero from '../../../assets/img/diarioFinanciero.png'
import logoElMercurio from '../../../assets/img/mercurio.png'
import logoInfinita from '../../../assets/img/infinita.png'


class Prensa extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="prensaContainer">
          <h1 className="tituloPasos">¿Qué dicen de nosotros?</h1>
          <Row>
            <div className="punteoPartners bloqueLogoPartnersSinTexto">
              <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 12, lg: 12 }}>
                <img className="logosPartners logoPartnerSinTexto" src={logoTele13} alt="avalados por seguros continental" />
                  <a className="aLink linkPrensa" 
                    href="https://www.tele13radio.cl/?id_podcast=120180913165331">
                      Ver nota 
                    <Icon className="arrowLink" type="arrow-right" />
                  </a>
              </Col>
              <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
                <img className="logosPartnersHorizontales logoPartnerSinTexto" src={logoDiarioFinanciero} alt="logo grupo de abogados legal chile" />
                <a className="aLink linkPrensa" href="https://www.df.cl/noticias/tendencias/df-lab/ojo-a-los-que-arriendan-crean-plataforma-web-que-asegura-el-pago-del/2018-11-23/191517.html">
                  Ver nota 
                  <Icon className="arrowLink" type="arrow-right" />
                </a>
              </Col>
            </div>
          </Row>
          <Row className="prensaRow2">
            <div className="bloqueLogoPartnersSinTexto">
              <Col className="partnersCol logoMercurio" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
                <img className="logosPartners logoPartnerSinTexto" src={logoElMercurio} alt="logo banco Bci" />
                <a className="aLink linkPrensa" href="https://digital.elmercurio.com/2019/04/16/A">Ver nota <Icon className="arrowLink" type="arrow-right" /></a>  
              </Col>
              <Col className="partnersCol logoInfinita" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
                <img className="logosPartnersHorizontales logoPartnerSinTexto" src={logoInfinita} alt="logo servipag" />
                <a className="aLink linkPrensa" href="http://www.infinita.cl/entrevistas/2018/11/05/benjamin-labra-gerente-de-arriendo-asegurado/">
                  Ver nota 
                  <Icon className="arrowLink" type="arrow-right"/>
                </a>  
              </Col>
            </div>
          </Row>         
        </div>
      </div>
    );
  }
}

export default Prensa;
