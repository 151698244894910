import React, { Component } from 'react';
import ApplicantCard from './partials/ApplicantCard';
import { List, Row, Col, Button, Divider } from 'antd';

import { propertyService } from '../../../../services/propertyService'
import { updateData } from '../../../../utils/intercom';

class ApplicantList extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        properties : []
    }
  }

  async componentDidMount(){
    let request = await propertyService.index()
    updateData({})
    if(request ){
      this.setState({
        loading:false,
        properties: request.data
      })
    }
  }


  create = ()=>{
    this.props.history.push('properties/create')
  }
  

    render() {
        return  <div style={{width:'90%', margin:'0 auto'}} >
            <Row className="admin-header" gutter={16}>
                <Col span={20}>
                <h1>Tus postulantes</h1>
                </Col>
                <Col span={4}>
                    <Button onClick={()=>this.create()} type="primary">Nuevo Postulante</Button>
                </Col>
                <Divider />

                <List
                    grid={{
                    gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5,
                    }}
                    dataSource={this.state.properties.results}
                    renderItem={item => (
                    <List.Item>
                        <ApplicantCard key={item.id} item={item} />
                    </List.Item>
                    )}
                />

            </Row>
        </div>
    }
}

export default ApplicantList;
