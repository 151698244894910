import React, { Component } from 'react';
import ApplicantCard from './partials/ApplicantCard';
import { List, Row, Col, Button, Divider, Spin } from 'antd';

import { propertyService } from '../../../../services/propertyService'
import ApplySubmit from './partials/ApplySubmit';

const uuidv4 = require('uuid/v4');


class ApplicantForm extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        property : null,
        loading: true,
        uid: uuidv4()
    }
  }

  async componentDidMount(){
    let request = await propertyService.get(this.props.match.params.id)
    if(request ){
      this.setState({
        loading:false,
        property: request.data,
      })
    }
  }

  isPropietary(){
    return this.props.location.pathname.startsWith('/propietary')
  }


    render() {
      let property = this.state.property
        return this.state.loading ? <Spin size="large"><div style={{flex:1,height:'100vh', width:'100vw', alignItems:'center'}} ></div></Spin> :  
        
        <div style={{maxWidth:'500px', margin:'0 auto', padding:'3%'}} >
            <Row className="admin-header" gutter={16}>
                <h1>Bienvenido!</h1>
                { this.isPropietary() ?
                  <h2>Crea un nuevo postulante para tu propiedad ubicada en {property.address} {property.street_number}, {property.comuna} </h2>:
                  <h2>Estás postulando como arrendatario a la propiedad ubicada en {property.address} {property.street_number}, {property.comuna} </h2>
                }
                <Divider />

                <ApplySubmit {...this.props} isPropietary={this.isPropietary()} uid ={this.state.uid} property={property} />


            </Row>
            <Row>

            </Row>
        </div>
    }
}

export default ApplicantForm;
