import React, { Component } from 'react';
import SubscriptionSummary from './partials/subscriptions_summary';

class Dashboard extends Component {

    render() {
        return(
          <div style={{width:'70%', margin: '0 auto'}}> 
                    <SubscriptionSummary/>
            </div>
        )
    }
}

export default Dashboard;
