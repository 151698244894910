/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button} from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './pagoFacil.css';
import '../ResetFont.css';
import './pagoFacilMobile.css';
import './pagoFacilTablet.css';
import './pagoFacilDesktop.css';

class PagoFacil extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <Row className="pagoDirectoRow">
          <Col span={24}>
            <p className="txtPagoDirecto">¿Ya eres arrendatario? Paga aquí</p>
            <Button className="btnSecundarioFondoOScuro btnPagoDirecto"><Link to="/pagar">Pagar</Link></Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PagoFacil;
