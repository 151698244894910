import {  PYTHON_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class LeadService
{
    postLead(data){
        let http = axios.create({ baseURL: PYTHON_URL })
        return http.post('/users/', data ).catch( error => { console.log('peticion con error ' + error); return false })
    }

}

export const leadService = new LeadService();