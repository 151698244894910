import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import { Upload } from '@progress/kendo-react-upload';
import { PYTHON_URL } from '../../../../../env';




class UploadFiles extends React.Component {
    onBeforeUpload = (event) => {
        event.additionalData.uid = this.props.uid;
    }

    onBeforeRemove = (event) => {
        event.additionalData.uid = this.props.uid;
    }

    render() {
        return (
            <Upload
                batch={false}
                multiple={true}
                defaultFiles={[]}
                onBeforeUpload={this.onBeforeUpload}
                onBeforeRemove={this.onBeforeRemove}
                withCredentials={false}
                saveUrl={PYTHON_URL+ 'applicant_documents/upload'}
                removeUrl={PYTHON_URL+ 'applicant_documents/remove'}
            />
        );
    }
}

export default UploadFiles;