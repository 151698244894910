import React from 'react';
import { Form, Row, Col, Button, Table } from 'antd';
import { paymentService } from '../../../../services/paymentService';
import { propertyService } from '../../../../services/propertyService';

const columns = [{
    title: 'Direccion',
    key: 'address',
    render:(text, record) => (
        <span>
          { record.address } { record.street_number }, { record.comuna }, { record.region }
        </span>
      )
  },{
    title: 'Tipo',
    key: 'type',
    dataIndex: 'type'
  },{
    title: 'Monto',
    key: 'price',
    render:(text, record) => (
        <span>
          { record.property_details.length>0 ?  record.property_details[0].valor : 'No informado'  }
        </span>
      )
  },{
    title: 'State',
    dataIndex: 'status.name',
    key: 'status.name',
  },{
    title: 'Acciones',
    key: 'name',
    render:(text, record) => (
        <span>
            <a href={"/properties/" + record.uid }>Ver</a>
        </span>
      )
  }


];

class Properties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true
        };
    }

    async componentDidMount() {
        let request = await propertyService.index()
        if(request ){
        await this.setState({
            loading:false,
            properties: request.data,
        })
        }
    }

    renderDelayedPayments = ()=>{
        let payments = this.state.delayed ? this.state.delayed.results : null

        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(payments){
            if(payments.length === 0) return 'No hay pagos atrasados'
        }
        
        return this.state.delayed ?
        <Table columns={columns} dataSource={ this.state.delayed.results } /> : 'No hay pagos atrasados';
    }

    goToQuotation = (quotation_id)=>{
    }

    renderProperties = ()=>{
        let properties = this.state.properties ? this.state.properties.results : null
        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(properties){
            if(properties.length === 0) return 'No hay pagos agendados para el día de hoy'
        }

        return <Table columns={columns} dataSource={ this.state.properties.results } />;
    }

    render() {

        return (
                <div>
                    <Row >
                        <Col className="gutter-row" >
                            <h2>Propiedades</h2>
                            {this.renderProperties()}
                        </Col>

                    </Row>

                </div>

        );
    }
}

export const PropertyIndex = Form.create()(Properties);