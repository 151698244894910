import axios from 'axios'
import {  API_URL, PYTHON_URL  } from '../../../env'

export const AuthService = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: ()=> localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  async authenticate(credentials,cb) {
    
    let http = axios.create({ baseURL: PYTHON_URL })
    return await http.post('/login/', {
      email : credentials.email,
      password: credentials.password
    })
    .then((res)=>{
  
      if(res.status===200) return res.data
    })
    .then((res)=>{
      localStorage.setItem('token', res.token)
      this.isAuthenticated = true
      res.connected = true

      localStorage.setItem('user', JSON.stringify(res) )
      this.user = res
      cb(true, res)
    })
    .catch(error => { 
      cb(false, error)
      return false })


  },

  register(user)
  {
    let headers = {  'Content-Type': 'multipart/form-data' }
    let http = axios.create({ baseURL: API_URL,  headers: headers })
    let formData = new FormData()
    formData.append('name', user.name)
    formData.append('address', user.address)
    formData.append('certificate_type', user.certificate_type)
    formData.append('country', user.country)
    formData.append('email', user.email)
    formData.append('password', user.password)
    formData.append('phone', user.phone)
    formData.append('rut', user.rut)
    formData.append('score', user.score)
    formData.append('documents', user.documents)
    return http.post( '/api/teacher/register', formData ).catch( error => { console.log(error); return false })
    .then(response => {
        return response
    })
    .catch( error => { console.log(error); return false }) 
  },

  newPassword(user, token)
  {
    let headers = {  'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token }
    let http = axios.create({ baseURL: API_URL,  headers: headers })
    let formData = new FormData()
    formData.append('password', user.password)
    return http.post( '/api/me/set_password ', formData ).catch( error => { console.log(error); return false })
    .then(response => {
        return response
    })
    .catch( error => { console.log(error); return false }) 
  },

  logout(cb) {
    localStorage.removeItem('token')
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}