import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select
  } from 'antd';

import {Comunas, Regiones} from '../../../../Shared/partials/Address'

const Option = Select.Option;


class BankForm extends Component {
    state = {
        type:'casa',
        comuna:'Ñuñoa',
        region:'Región Metropolitana de Santiago',
        disabled_places:false
      };
    
    
    onChange = (e) => {
        this.setState({
          type: e.target.value,
        });
    }

    onRegionChange = (e)=>{
        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region===e
        })
        this.setState({region:e, comuna:comunas[0].comunas})
    }
  
    onComunaChange = (e)=>{
        this.setState({comuna:e})
    }



    render() {

        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region.includes(this.state.region)
        })

        return <div>
            <h2>Detalle Propiedad</h2>
            <Card style={{border:'0px'}}>
            
                <Form layout='vertical'>
                    <Form.Item label="Dirección">
                    </Form.Item>

                    <Form.Item label="Tipo de vivienda" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Radio.Group defaultValue="casa" onChange={(e)=>this.onChange(e)} buttonStyle="solid">
                            <Radio.Button value="casa">Casa</Radio.Button>
                            <Radio.Button value="departamento">Dpto</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {this.state.type==='departamento'?
                        <Form.Item label="N° departamento" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                            <Input placeholder="1234" />
                        </Form.Item> : null
                    }

                    <br/>
                    <Form.Item label="Región" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Select
                            style={{  }}
                            disabled={this.state.disabled_places}
                            value={this.state.region}
                            onChange={this.onRegionChange}>
                            {Regiones.regiones.map(city => <Option key={city.region}>{city.region}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Comuna" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Select
                            style={{  }}
                            disabled={this.state.disabled_places}
                            value={this.state.comuna}
                            onChange={this.onComunaChange}>
                            {comunas[0].comunas.map(city => <Option key={city}>{city}</Option>)}
                        </Select>
                    </Form.Item>
                    

                </Form>
            </Card>


        </div>
    }
}

export default BankForm;
