import React, { Component } from 'react';
import {quotationService} from '../../../../services/quotationService'
import {numberWithCommas} from '../../../../utils'
import { Table, Icon, Tooltip, Card, Row, Col, Menu, Dropdown} from 'antd';
import moment from 'moment'

const { Column} = Table;

const planes = ['Con aval cobertura 6 meses - Anual', 'Sin aval cobertura 6 meses - Anual','Con aval cobertura 9 meses - Anual', 'Sin aval cobertura 9 meses - Anual','Con aval cobertura 6 meses - Mensual', 'Sin aval cobertura 6 meses - Mensual','Con aval cobertura 9 meses - Mensual', 'Sin aval cobertura 9 meses - Mensual' ]
const comisiones = [4,5,5,6,5,6.25,6.25,7.5]
class ShowQuotation extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      quotation_id: props.match.params.id,
      quotations: [],
      loading: true,
      filteredInfo: { status:['Por aprobar'] },
      uf:0
    }
  }

  async componentDidMount(){
    let request = await quotationService.quotation(this.state.quotation_id)
    if(request ){
      this.setState({
        loading:false,
        quotations: request.data
      })
    }
  }

  

  renderFullRow(record){
    let monthly_price, total;
    monthly_price = record.monthly_price
    if(record.type_money === 'uf'){
      monthly_price = record.monthly_price*this.state.uf
    }
    total = monthly_price * 12 *  comisiones[record.plan_id-1]/100

    return <div>
            <table style={{width:'60%'}}>
              
              { record.monthly_price? <tr><td><p> <span style={{fontWeight:'bold'}}>Monto Mensual:</span></p></td> <td><p> ${numberWithCommas(monthly_price)} </p></td></tr> : null}
              
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Plan cotizado:</span></p></td> <td><p> {planes[record.plan_id-1] }</p></td></tr> : null}
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Comisión:</span></p></td> <td><p> {comisiones[record.plan_id-1] }%</p></td></tr> : null}
              
              { record.discount ? <tr><td><p> <span style={{fontWeight:'bold'}}>Descuento:</span></p></td><td><p> {record.discount}%</p></td></tr> : <tr><td> <p> <span style={{fontWeight:'bold'}}>Descuento:</span></p></td><td><p> Sin descuento Aplicado</p></td></tr>  }
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Total Cotización:</span></p></td><td><p> ${ numberWithCommas(total)  }</p></td></tr> : null}
              { record.properties? <tr><td><p> <span style={{fontWeight:'bold'}}>Dirección:</span></p></td><td><p> {record.properties.address}</p></td></tr> : null}
            </table>
           </div>
  }
    
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }

  noResults(){
    return <div style={{paddingTop:'50px', paddingBottom:'50px'}}>
      <img alt="No Data" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K"></img>
      <p style={{paddingTop:'10px'}} >No hay solicitudes { this.state.filteredInfo.status.length>0? this.state.filteredInfo.status[0] : null }</p>
    </div>
  }

  renderAprovedIcon(record){
    if(record.status === 'Por aprobar'){
      return <Tooltip title="Por aprobar">
        <Icon type="minus-circle" style={{color: "yellow"}} />
      </Tooltip>
    }else if(record.status === 'Aprobado'){
      return <Tooltip title="Aprobado"> <Icon type="check-circle" style={{color: "green"}} />
      </Tooltip>
    }else if(record.status === 'Rechazado'){
      return <Tooltip title="Rechazado"> <Icon type="close-circle" style={{color: "red"}} /> </Tooltip>
    }else{
      return <Tooltip title="Incompleto"> <Icon type="exclamation-circle" style={{color: "gray"}} /> </Tooltip>
    }
  }

    render() {
      const rental_payments = this.state.quotations.rental_payments;
      const contract = this.state.quotations.contract
      const propietary = this.state.quotations.user
      const lessor = this.state.quotations.lessor
      const plan = this.state.quotations.plan
      const property = this.state.quotations.properties;
      let bankData = <div>No se tiene información bancaria</div>
      if(property){
        if(property.bank_accounts){
          if(property.bank_accounts.length > 0){
            let bacc = property.bank_accounts[0]
            bankData = <div>
                        <p>Rut titular: {bacc.dni}</p>                        
                        <p>Nombre titular: {bacc.owner}</p>
                        <p>Número de cuenta: {bacc.account_number}</p>

                        <p>Tipo de cuenta: {bacc.account_type}</p>
                        <p>Banco: {bacc.bank}</p>
            </div>
          }
        }
      }

    if(rental_payments) {
        return(
          <div> 
            <Row gutter={12} >
                <Col span={12}>
                    <Card
                        size="small"
                        title="Contrato"
                        extra={<a href="#">Ver más</a>}
                        >
                        <p>Monto arriendo: {contract.amount} {contract.type_money}</p>
                        <p>Dia de pago arriendo: {contract.pay_day}</p>
                        <p>Fecha inicio contrato: {contract.init_date}</p>
                        <p>Fecha primer pago: {contract.first_payment_date}</p>


                    </Card>
                    <Card
                        size="small"
                        title="Arrendatario"
                        extra={<a href="#">Ver más</a>}
                        >
                        <p>ID: {lessor.id}</p>
                        <p>Nombre: {lessor.name} {lessor.last_name}</p>
                        <p>Email: {lessor.email}</p>
                        <p>Rut: {lessor.rut}</p>

                    </Card>

                </Col>
                <Col span={12}>
                    <Card
                        size="small"
                        title="Propietario"
                        extra={<a href="#">Ver más</a>}
                        >
                        <p>ID: {propietary.id}</p>
                        <p>Nombre: {propietary.name} {propietary.last_name}</p>
                        <p>Email: {propietary.email}</p>
                        <p>Rut: {propietary.rut}</p>

                    </Card>
                    <Card
                        size="small"
                        title="Plan"
                        extra={<a href="#">Ver más</a>}
                        >
                        <p>Plan: {plan.description} {plan.type}</p>
                    </Card>
                    <Card
                        size="small"
                        title="Datos Bancarios"
                        extra={<a href="#">Ver más</a>}
                        >
                        {bankData}
                    </Card>
                </Col>
            </Row>


            <br/>
            { rental_payments ?  rental_payments.length + " Ordenes de pago generadas" : null }
            <br/>
            <Table locale={{ emptyText: this.noResults() }} onChange={this.handleChange} loading={this.state.loading} pagination={{ pageSize: 200 }} dataSource={rental_payments} size="small" rowKey="id"  >
              <Column
                title="Id Renta"
                dataIndex="id"
                key="id"
              />
              <Column
                title="Fecha"
                defaultSortOrder='ascend'
                sorter={(a,b)=>moment(a.date).valueOf()-moment(b.date).valueOf() }  
                key="date"
                render={(text, record) => (
                  <span>
                    <a href="javascript:;">{moment(record.date, "YYYY-MM-DD").format('DD-MM-YYYY')}</a>
                  </span>
                )}
              />
              <Column
                title="Estado"
                dataIndex="state"
                key="state"
              />
              <Column 
                title="Deposito"
                key="deposito"
                render={(text, record) => (
                  <span>
                    <a href="javascript:;">{record.real_payment}</a>
                  </span>
                )}
              />
              <Column 
                title="Acciones"
                key="actions"
                render={(text, record) => (
                  <Dropdown overlay={  
                    <Menu>
                      <Menu.Item key="0">
                        <a href="http://www.alipay.com/">Transfirió arriendo a Insurtech</a>
                      </Menu.Item>
                      {
                        plan.id <= 4 ?
                        <Menu.Item href="http://google.com" key="3">Transfirió arriendo al propietario</Menu.Item>:
                        null
                      }
                      <Menu.Item key="1">
                        <a href="http://www.taobao.com/">Transferencia de cuota Suscripción a Insurtech</a>
                      </Menu.Item>

                    </Menu>
                    } 
                    trigger={['click']}>
                    <a className="ant-dropdown-link" href="#">
                      Marcar como pagado <Icon type="down" />
                    </a>
                  </Dropdown>
                )}
              />

            
            </Table>
          </div>
        )}else{
            return <Table loading={this.state.loading} />
        } 
    }
}

export default ShowQuotation;
