import React, { Component } from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import {statsService} from '../../../../../services/statsService'
import { Card } from 'antd';


  const data = {
    labels: [
      '',
      '',
      '',
      '','','','',''
    ],
    datasets: [{
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [100, 100, 100, 100, 100, 100, 100, 100],
      label: 'Suscripciones'
     
    }]
  };

class PlanPie extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getSum(total, num) {
        return total + num;
    }

    async componentDidMount(){
        let data = await statsService.byPlan()

        if(data){
                     
           let items = (data.data);
           items = (items.data);
           
           let count=[], subscription_price=[], debt_continental=[], label = []
           let total = 0
           for(let i = 0; i<items.length; i++ ){
                count.push(items[i][1])
                label.push(items[i][0])
           }

           await this.setState({
               count:count,
               subscription_price:subscription_price,
               debt_continental:debt_continental,
               label:label,
               total:total
           })

           this.forceUpdate()
        }
    }

    render() {
        if(this.state.count){
            data.datasets[0].data = this.state.count
            data.labels = this.state.label
        }
        
        return (
        <div>
            <Card style={{width:'500px'}}>
              <h2>Suscripciones por tipo de plan</h2>
              <HorizontalBar data={data} />
            </Card>

        </div>
        );
  }

}

export default PlanPie;