import {  PYTHON_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class PropertyService
{

    index(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.get( '/propietary/properties/' ).catch( error => { console.log(error); return false })
    }

    get(id){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.get( '/propietary/properties/'+id ).catch( error => { console.log(error); return false })
    }

    store(data){
        let headers = { 'Content-Type': 'application/json', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.post( '/propietary/properties/', data ).catch( error => { console.log(error); return false })
    }

    complete(data){
        let headers = {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + getToken()
        }
        let http = axios.create({
            baseURL: PYTHON_URL,
            headers: headers
        })
        return http.post('/propietary/property_details/' , data).catch(error => {
            console.log(error);
            return false
        })
    }


    listMyProperty(id){

        let headers = { 'Content-Type': 'application/json', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.post( '/propietary/do_list', {uid:id}  ).catch( error => { console.log(error); return false })
    }

    dontListMyProperty(id){

        let headers = { 'Content-Type': 'application/json', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.post( '/propietary/dont_list', {uid:id}  ).catch( error => { console.log(error); return false })
    }
}

export const propertyService = new PropertyService();
