export const updatePage = (props) => {
  if (localStorage.getItem('hmac')) {
    let email = localStorage.getItem('email')
    let user_type = localStorage.getItem('role')
    let hmac = localStorage.getItem('hmac')

    window.Intercom("update", {
      user_id: email,
      email: email,
      user_type: user_type,
      user_hash: hmac
    });
  } else {
    window.Intercom("update");
  }
}


export const updateData = (data) => {
  if (localStorage.getItem('hmac')) {

    let hmac = localStorage.getItem('hmac')
    data.hmac = hmac

    window.Intercom("update", data );
  } else {
    window.Intercom("update", data);
  }
}