import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select, InputNumber, Divider
  } from 'antd';

import PlaceSuggest from './PlaceSuggest';
import ReactGoogleMap from "react-google-map"
import ReactGoogleMapLoader from "react-google-maps-loader"

import {Comunas, Regiones} from '../../../../Shared/partials/Address'
import { propertyService } from '../../../../../services/propertyService';
import ChipOptions from '../../../../Shared/partials/ChipOptions';
import { numberWithCommas } from '../../../../../utils';
import SwitchOption from '../../../../Shared/partials/SwitchOption';

const Option = Select.Option;
const API_KEY = "AIzaSyDPWrtJQrSfU0xyhhDhiGhVOqX8d0vNBqo"



class PropertyEdit extends Component {

    constructor(props) {
        super(props);

        let details =  props.property.property_details.length>0 ? {...props.property.property_details[0]} : {}


        this.state = {
            ...props.property,
            ...details
        }
    }
    
    async submit(){
        this.setState({loading:true})
        let state = this.state
        let {property, property_details} = state
        

        if(property.comuna && property.region && property.street_number && property.address && property.type){
            let data = {
                comuna : property.comuna, 
                region : property.region, 
                street_number : property.street_number, 
                address : property.address, 
                type : property.type
            }

            let res = await propertyService.store(data)
            if(res){
                    localStorage.setItem('property_uid', res.data.uid)
                    this.props.history.push('/propietary/properties/'+res.data.uid + '/complete')  
            }
        }else{
            alert("ingrese toda la info");
        }


    }
    

        onChangeAmount = (value, name) => {
            this.setState({
                [name]: value,
            });
        }

    onNumberChange = (e) => {
        this.setState({
          street_number: e.target.value,
        });
    }


    onTypeChange = (e) => {
        if(e.target.value ==='departamento')
            this.setState({
                type: e.target.value,
            });
        else
            this.setState({
                type: e.target.value,
                numero_depto:null
            });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }


    onRegionChange = (e)=>{
        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region===e
        })
        this.setState({region:e, comuna:comunas[0].comunas})
    }
  
    onComunaChange = (e)=>{
        this.setState({comuna:e})
    }

    onMoneyTypeChange = (e)=>{
        this.setState({comuna:e})
    }

    render() {
        let property =this.state
        let property_details = this.state

        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region.includes(property.region)
        })

        



        return   <div style={{maxWidth:'500px', margin:'0 auto', padding:'3%'}} >
     <Form layout='vertical'  >
                    <Form.Item label="Dirección">
                        <Input value={property.address} name='property' onChange={(e)=> this.onChange(e) } />
                    </Form.Item>

                    <Form.Item>
                        <div className="formitem">
                            <p>Número <span style={{fontWeight:100}}></span></p>
                            <div style={{textAlign:'left'}}>
                                <Input onChange={(e)=>this.onNumberChange(e)} value={property.street_number} placeholder="1445" defaultValue={property.street_number} />
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item label="Tipo de vivienda" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Radio.Group defaultValue={property.type} onChange={(e)=>this.onTypeChange(e)} buttonStyle="solid">
                            <Radio.Button value="casa">Casa</Radio.Button>
                            <Radio.Button value="departamento">Dpto</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <br/>

                    {property.type==='departamento'?
                        <Form.Item label="N° departamento" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                            <Input value={property.numero_depto}  placeholder="32b" />
                        </Form.Item> : null
                    }

                    <br/>

                    <Form.Item label="Región" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Select
                            style={{  }}
                            value={property.region}
                            onChange={this.onRegionChange}>
                            {Regiones.regiones.map(city => <Option key={city.region}>{city.region}</Option>)}
                        </Select>
                    </Form.Item> 

                    <Form.Item label="Comuna" style={{ display: 'inline-block', width: 'calc(50% )', marginBottom:'0px' }}>
                        <Select
                            style={{  }}
                            value={property.comuna}
                            onChange={this.onComunaChange}>
                            {comunas[0].comunas.map(city => <Option key={city}>{city}</Option>)}
                        </Select>
                    </Form.Item> 


                    <br/><br/>

                    {this.state.lat && this.state.lng ? 

                    <ReactGoogleMapLoader
                    height="100px"
                                style={{height:'100px'}}
                    params={{
                        key: API_KEY, // Define your api key here
                        libraries: "places,geometry", // To request multiple libraries, separate them with a comma
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <div                                 
                            style={{height:'100px', marginLeft:'-25vw', width:'80vw'}}>
                                <ReactGoogleMap
                                    googleMaps={googleMaps}
                                    coordinates={[{position:{lat: this.state.lat, lng: this.state.lng}} ]}
                                    center={{lat: this.state.lat, lng: this.state.lng}}
                                    zoom={16}
                                    />
                            </div>
                        )}
                    />
                     : null }
                    



                    <Form.Item>
                            <p>Monto de arriendo <br/><span style={{fontWeight:100, fontSize:'9px'}}>(No debes incluir gastos comunes)</span></p>
                            <div style={{textAlign:'left'}}>
                            <InputNumber
                            style={{width:'100%'}}
                            defaultValue={this.state.valor}
                            formatter={value => numberWithCommas(value) }
                            parser = {
                                value => value.replace(/\./g, '').replace(/\$/g ,'').replace(' ','')
                            }
                            onChange={ (value)=> this.onChangeAmount(value,'price') }
                            /></div>
                    </Form.Item>
                    <Form.Item>
                        <p>Tipo Moneda <br/><span style={{fontWeight:100, fontSize:'9px'}}>(Especifica si tu arriendo está en pesos o UF)</span></p>

                        <ChipOptions name="tipo_moneda" default={property_details.tipo_moneda} update={(data)=>this.setState(data)} data={['Pesos','U.F.']} />
                    </Form.Item>
                   <Form.Item>
                        <div className="formitem">
                            <p>Gastos comunes aprox.<br/><span style={{fontWeight:100, fontSize:'9px'}}>(Ingresa un monto aproximado, si no sabes déjalo en blanco)</span></p>
                           
                            <InputNumber
                            style={{width:'100%'}}
                            defaultValue={this.state.gc}
                            formatter={value => numberWithCommas(value) }
                            parser = {
                                value => value.replace(/\./g, '').replace(/\$/g, '').replace(' ', '')
                            }
                            onChange={ (value)=> this.onChangeAmount(value,'gc') }
                            />           
                        </div>
                    </Form.Item>
                    <Form.Item>
                            <p>Superficie Construida</p>
                            <div style={{textAlign:'left'}}>
                                <InputNumber onChange={(e)=>this.onChange( { target : {name:'m_construidos', value:e} })}  addonAfter={ <span>m2</span> } placeholder="112" name="m_construidos" defaultValue={property_details.m_construidos} />
                            m2
                            </div>
                    </Form.Item>
                    <Form.Item>
                            <p>Superficie Total</p>
                            <div style={{textAlign:'left'}}>
                                <InputNumber onChange={(e)=>this.onChange( { target : { name:'m_terreno', value: e} } )} addonAfter={ <span>m2</span> } placeholder="112" name="m_terreno" defaultValue={property_details.m_terreno} /> m2
                            </div>
                    </Form.Item>
                    <Divider/>
                    <Form.Item>
                        <div className="formitem">
                            <p>Habitaciones</p>
                            <div style={{textAlign:'center'}}>
                                <ChipOptions default={property_details.dormitorios} name="dormitorios" update={(data)=>this.setState(data)} data={[1,2,3,4,5,6]} />
                            </div>
                        </div>

                    </Form.Item>
                    <Divider/>     
                    <Form.Item>
                        <div className="formitem">
                            <p>Baños</p>
                            <div style={{textAlign:'center'}}>
                                <ChipOptions default={property_details.banos} name="banos" update={(data)=>this.setState(data)} data={[1,2,3,4,5,6]} />
                            </div>
                        </div>

                    </Form.Item>
                    <Divider/>

                    <Form.Item>
                    <div className="formitem">
                        <p>Marque según corresponda</p><br/>
                        <SwitchOption name="servicios" update={(data)=>this.setState(data)} label = "Tiene pieza de servicio" />
                        <SwitchOption name="amoblado" update={(data)=>this.setState(data)} label="Amoblado" />
                        <SwitchOption name="calefaccion" update={(data)=>this.setState(data)} label="Tiene calefacción" />
                        <SwitchOption name="piscina" update={(data)=>this.setState(data)} label="Tiene piscina" />
                        <SwitchOption name="condominio" update={(data)=>this.setState(data)} label="Es condominio" />
                        </div>
                    </Form.Item>








                    <Button type="primary" onClick={()=>this.submit()} loading={this.state.loading} style={{marginTop:'50px'}} block>Continuar</Button>

                </Form>
                </div> 


    
    }
}

export default PropertyEdit;
