import PropTypes from "prop-types"
import React from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import { Input } from "antd";

const API_KEY = "AIzaSyDPWrtJQrSfU0xyhhDhiGhVOqX8d0vNBqo"
class PlaceSuggest extends React.Component {
  state = {
    search: "",
    value: "",
  }

  handleInputChange(e) {
    this.setState({search: e.target.value, value: e.target.value})
    this.props.updateAddress({
      address : e.target.value
    })
  }

  handleSelectSuggest(suggest) {


    let data = {
      ask_street_number: true,
      ask_region: true,
      ask_comuna:true,
      lat: suggest.geometry.location.lat(),
      lng: suggest.geometry.location.lng()
    }




    let region = suggest.address_components.filter( (item)=>{
        return item.types.includes('administrative_area_level_1')
    } )

    if(region.length>0){
      data.region = (region[0].long_name);
      data.ask_region = false
    }

    let comuna = suggest.address_components.filter( (item)=>{
      return item.types.includes('administrative_area_level_3')
    } )
    if(comuna.length>0){
      data.comuna = (comuna[0].long_name);
      data.ask_comuna = false
    }

    let street_number = suggest.address_components.filter( (item)=>{
      return item.types.includes('street_number')
    } )
    if(street_number.length>0){
      data.street_number = (street_number[0].long_name);
      data.ask_street_number = false
    }

    let route = suggest.address_components.filter( (item)=>{
      return item.types.includes('route')
    } )
    if(route.length>0){
      data.address = (route[0].long_name);
      data.ask_route = false
    }

    this.setState({search: "", value: suggest.formatted_address,region:region,
    comuna:comuna,
    street_number:street_number,})

    this.props.updateAddress({
      ...data,
      disabled_places:true
    })
  }

  validateAddress(){
    let data = {
      ask_street_number: true,
      ask_region: true,
      ask_comuna:true
    }
    if(this.state.street_number){
      if(this.state.street_number.length>0){
        data.ask_street_number = false
        data.ask_street_number = this.state.ask_street_number
      }
    }

    if(this.state.region){
      if(this.state.region.length>0){
        data.ask_region = false
        data.ask_region = this.state.ask_region
      }
    }

    if(this.state.comuna){
      if(this.state.comuna.length>0){
        data.ask_comuna = false
        data.ask_comuna = this.state.ask_comuna
      }
    }

    this.props.updateAddress(
      data
    )
  }

  render() {
    const {search, value} = this.state
    return (
      <ReactGoogleMapLoader
        params={{
          key: API_KEY,
          libraries: "places,geocode",
        }}
        render={googleMaps =>
          googleMaps && (
            <div>
              <ReactGooglePlacesSuggest
                autocompletionRequest={{input: search, componentRestrictions:{country:'cl'}}}
                googleMaps={googleMaps}
                onSelectSuggest={(d)=>this.handleSelectSuggest(d)}
              >
                <Input
                  type="text"
                  value={value}
                  onBlur={()=>this.validateAddress()}
                  placeholder="Av. del Parque 5275"
                  onChange={(e)=>this.handleInputChange(e)}
                />





              </ReactGooglePlacesSuggest>
            </div>
          )
        }
      />
    )
  }
}

PlaceSuggest.propTypes = {
  googleMaps: PropTypes.object,
}

export default PlaceSuggest