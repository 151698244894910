import React, { Component } from 'react';
import PropertyCard from './partials/PropertyCard';
import { List, Row, Col, Button, Divider, Empty } from 'antd';
import { propertyService } from '../../../../services/propertyService';
import {   Link } from "react-router-dom";


class PropertyList extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        properties : [],
        loading:true
    }
  }

  async componentDidMount(){
    let request = await propertyService.index()
    if(request ){
      this.setState({
        loading:false,
        properties: request.data
      })
    }
  }

  renderProperties(){
      if(this.state.properties.results) 
        return this.state.properties.results.map( (item)=>{
            return  <Col key={item.id} span={6}>
                        <PropertyCard key={item.id} item={item} />
                        <br/>
                    </Col>
        } )
    else return <div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center'}}>
        <img alt="loader" style={{margin:'0 auto'}} src="/preloader2.gif" />
        <h4>Cargando tus propiedades</h4>
    </div>
       
  }

  create = ()=>{
    this.props.history.push('properties/create')
  }
  

    render() {
        return  <div style={{width:'90%', margin:'0 auto'}} >
            <Row className="admin-header" style={{paddingTop:'5%'}} gutter={16}>
                <Col xs={13} md={20}>
                  <h2>Tus propiedades</h2>
                </Col>
                <Col xs={11} md={4}>
                    <Button onClick={()=>this.create()} type="primary">Nueva Propiedad</Button>
                </Col>
                <br/>
                <Divider />
            </Row>
            <Row>
                <List
                    loading={this.state.loading}
                    locale={ {
                      emptyText: <Empty
                      image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                      imageStyle={{
                        height: 200,
                        display:'block'
                      }}
                      description={
                        <span>
                          Aún no tienes ninguna <a href="#API">propiedad</a>,<br/> Crea la primera
                        </span>
                      }
                    >
                    <Link to='/propietary/properties/create'>
                      <Button href="/propietary/properties/create" type="primary">Crear</Button>
                    </Link>
                      
                    </Empty>
                    } }
                    grid={{
                    gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5,
                    }}
                    dataSource={this.state.properties.results}
                    renderItem={item => (
                    <List.Item>
                        <PropertyCard onClick={ ()=>this.props.history.push('/propietary/properties/'+item.uid)} key={item.id} item={item} />
                    </List.Item>
                    )}
                />
            </Row>
        </div>
    }
}

export default PropertyList;
