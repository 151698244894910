import React, { Component } from 'react';
import { Input, Button, Row, Col, Checkbox, } from 'antd';
import { leadService } from '../../services/leadService'
import './formLead.css';
import { updateData } from '../../utils/intercom';

class formLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      apellido: '',
      email: '',
      fono: '',
      checked: false
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  
  
  activeChecked = () => {
    this.setState({
      checked: true
    })
  }
  
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
  }
  
  async handleSubmit(event) {
    //alert('A name was submitted: ' + this.state.nombre + this.state.email + this.state.fono);
    const data =  {
      name: this.state.nombre,
      last_name: this.state.apellido,
      email : this.state.email,
      phone: this.state.fono,
      intercomstage: 1,
      pipedrivestage: 194,
      nombrestage: 'COMPLETAR_INFO_1'
    }
    let user = await leadService.postLead(data);
    if (user){
      if(user.data.redirect){
        this.props.history.push('/login?error=Ya tienes un usuario')
      }else if(user.data.token){
        this.props.history.push('/propietary/properties?token=' + user.data.token)
      }else{
          updateData(data)

          localStorage.setItem('token', user.data.jwt);
          localStorage.setItem('set_password', true)
          localStorage.setItem('user', JSON.stringify(user.data))
          this.props.history.push('/propietary/properties/create')
      }

    }  
    event.preventDefault();
  }
  render() {
    // const label = `${this.state.checked ? 'Checked' : 'Unchecked'}-${this.state.disabled ? 'Disabled' : 'Enabled'}`;
    const isEnabled = this.state.checked === true && this.state.nombre.length > 0 && this.state.email.length > 0 && this.state.fono.length > 0;
    return (
      <div className="cards formContainer">
        <form>
          <label>Email
            <input className="inputCard" placeholder="mimail@mail.com"  name="email" value={this.state.email} onChange={this.handleChange} />
          </label>
          <Row className="nombreRow">  
            <label>Nombre 
              <input className="inputCard" id="nombreInput" placeholder="Juan" type="text" name="nombre" value={this.state.nombre} onChange={this.handleChange} />
            </label>
            <label>Apellido
              <input className="inputCard" id="apellidoInput" placeholder="Perez" type="text" name="apellido" value={this.state.apellido} onChange={this.handleChange} />
            </label>
          </Row>
          <label>Teléfono de contacto
          <input className="inputCard" placeholder="+56948638295" name="fono" value={this.state.fono} onChange={this.handleChange}/>
          </label>
          <div className="checkBoxDiv">
            <Checkbox onClick={this.activeChecked}>
            
                Acepto los <a className="aLink" href="/">términos y condiciones</a> del servicio de ArriendoAsegurado.com
              
            </Checkbox>
          </div>
          <div className="buttonDiv">
            <Button disabled={!isEnabled} className="btnPrincipal" type="submit" value="Submit" onClick={this.handleSubmit}>Comenzar</Button>
          </div>
        </form> 
      </div>
    );
  }
}

export default formLead;
