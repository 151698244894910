import React from "react";
import { Route, Switch } from "react-router-dom";
import PropertyList from './propietary/properties'
import { AuthService } from "./auth/AuthService";
import AdminLayout from "./layout/AdminLayout";
import Dashboard from './administrator/dashboard'
import Quotations from './administrator'
import QuotationShow from './administrator/Quotation/show'
import {PaymentIndex} from './administrator/Payment'
import PropertyStore from './propietary/properties/store'
import PropertyShow from './propietary/properties/show'
import BankForm from './propietary/properties/showpartials/BankForm'
import ApplicantList from './propietary/applicants'
import PasswordSet from './propietary/SetPassword'
import '../../assets/css/main.css';
import PropertyStoreDetails from "./propietary/properties/storedetails";
import SelectListing from "./propietary/properties/partials/SelectListing";
import SchedulePhotos from "./propietary/properties/partials/SchedulePhotos";
import ApplicantForm from "./propietary/applicants/ApplicantForm";
import InviteForm from "./propietary/applicants/InviteForm";
import { ApplicantIndex } from "./administrator/applicants";
import { PropertyIndex } from "./administrator/properties";

function AdminContainer(props) {
  return (
    <AdminLayout location={props.location} auth={AuthService}>
    <Switch>
        <Route exact path="/dashboard"  component={Dashboard} />
        
        <Route exact path="/propietary/properties" component={PropertyList}  />
        <Route exact path="/propietary/properties/create" component={PropertyStore} />
        <Route exact path="/propietary/properties/:id/complete" component={PropertyStoreDetails} />
        <Route exact path="/propietary/properties/:id" component={PropertyShow} />
        <Route exact path="/propietary/properties/:id/bank" component={BankForm} />
        <Route exact path = "/propietary/properties/:id/select_listing" component = { SelectListing } />
        <Route exact path = "/propietary/properties/:id/schedule" component = { SchedulePhotos } />
        <Route exact path = "/propietary/properties/:id/apply" component = { ApplicantForm } />
        <Route exact path = "/propietary/properties/:id/invite" component = { InviteForm } />

        <Route exact path="/propietary/set_password" component={PasswordSet} />

        <Route exact path="/propietary/applicants" component={ApplicantList} />

        <Route exact path="/quotations"  component={Quotations} />
        <Route exact path="/quotations/:id" component={QuotationShow}/>
        <Route exact path="/payments" component={PaymentIndex} />
        
        <Route exact path="/applicants" component={ApplicantIndex} />
        <Route exact path="/properties" component={PropertyIndex} />

      </Switch>
    </AdminLayout>

  );
}

export default AdminContainer;
