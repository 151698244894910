/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './contacto.css';
import '../ResetFont.css';
import './contactoMobile.css';
import './contactoTablet.css';
import './contactoDesktop.css';

import imgEdificiosSolos from '../../../assets/img/casas_aparte.svg';

/*Iconos*/
import iconoChat from '../../../assets/img/Message.svg';
import iconoCel from '../../../assets/img/Mobile.svg';
import iconoMail from  '../../../assets/img/Mail.svg';
import iconoWsp from  '../../../assets/img/logo_wsp.png';



class Contacto extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
         <div className="contactoContainer">
          <h1 className="tituloPasos">Contáctanos</h1>
          <Row className="rowContacto rowContacto1">
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <img className="iconoContacto" src={iconoChat} alt="avalados por seguros continental" />
            </Col>   
            <Col className="contactCol chatTxt" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <h3 className="contactoSubtitle chatTitle">Chat en vivo</h3>
              <p className="contactoText hablanosChat">Habla directamente con nuestros Super Agentes en la ventana del chat.</p>      
            </Col>   
          </Row>
          <Row className="rowContacto">
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <img className="iconoContacto" src={iconoWsp} alt="avalados por seguros continental" />
            </Col>   
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <h3 className="contactoSubtitle enviaWsp">Envíanos un Whatsapp</h3>
              <a title="Click para chatear"  className="contactoText wsp" href="https://wa.me/56950914747" target="_blank" rel="noopener noreferrer">+569 50914747</a>
            </Col> 
          </Row>
          <Row className="rowContacto">
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <img className="iconoContacto" src={iconoCel} alt="avalados por seguros continental" />
            </Col>   
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <h3 className="contactoSubtitle">Llámanos al</h3>
              <a className="contactoText wsp" href="tel:+562 29405026">+562 29405026</a>
            </Col> 
          </Row>
          <Row className="rowContacto">
            <Col className="contactCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <img className="iconoContacto" src={iconoMail} alt="avalados por seguros continental" />
            </Col>   
            <Col className="contactCol escribenos" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
              <h3 className="contactoSubtitle">Escribenos a</h3>
              <p className="contactoText mail">contacto@arriendoasegurado.com</p>
            </Col>       
          </Row>
              <img className="imgEdificiosSolos" src={imgEdificiosSolos} alt="edificios bonitos" />
          <Button className="btnPrincipal btnVerDetalles btnFinal btnIntro" type="primary"><Link to="/lead">Publica tu propiedad</Link></Button>
        </div>
      </div>
    );
  }
}

export default Contacto;
