import React, { Component } from 'react';
import {quotationService} from '../../../services/quotationService'
import {moneyService} from '../../../services/moneyService'
import {numberWithCommas} from '../../../utils'
import { Table, Icon, Tooltip} from 'antd';
import { Link } from "react-router-dom";

const { Column} = Table;

const planes = ['Con aval cobertura 6 meses - Anual', 'Sin aval cobertura 6 meses - Anual','Con aval cobertura 9 meses - Anual', 'Sin aval cobertura 9 meses - Anual','Con aval cobertura 6 meses - Mensual', 'Sin aval cobertura 6 meses - Mensual','Con aval cobertura 9 meses - Mensual', 'Sin aval cobertura 9 meses - Mensual' ]
const comisiones = [4,5,5,6,5,6.25,6.25,7.5]
class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data_teachers: [],
      loading: true,
      filteredInfo: { status:['Por aprobar'] },
      uf:0
    }
  }

  async componentDidMount(){
    let token = localStorage.getItem('token')
    let request = await quotationService.quotations(token)
    let uf = await moneyService.getUf();
    if(request && uf){
      this.setState({
        loading:false,
        data_teachers: request.data,
        uf : uf.data
      })
    }
  }

  

  renderFullRow(record){
    let monthly_price, total;
    monthly_price = record.monthly_price
    if(record.type_money === 'uf'){
      monthly_price = record.monthly_price*this.state.uf
    }
    total = monthly_price * 12 *  comisiones[record.plan_id-1]/100

    return <div>
            <table style={{width:'60%'}}>
              
              { record.monthly_price? <tr><td><p> <span style={{fontWeight:'bold'}}>Monto Mensual:</span></p></td> <td><p> ${numberWithCommas(monthly_price)} </p></td></tr> : null}
              
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Plan cotizado:</span></p></td> <td><p> {planes[record.plan_id-1] }</p></td></tr> : null}
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Comisión:</span></p></td> <td><p> {comisiones[record.plan_id-1] }%</p></td></tr> : null}
              
              { record.discount ? <tr><td><p> <span style={{fontWeight:'bold'}}>Descuento:</span></p></td><td><p> {record.discount}%</p></td></tr> : <tr><td> <p> <span style={{fontWeight:'bold'}}>Descuento:</span></p></td><td><p> Sin descuento Aplicado</p></td></tr>  }
              { record.plan_id? <tr><td><p> <span style={{fontWeight:'bold'}}>Total Cotización:</span></p></td><td><p> ${ numberWithCommas(total)  }</p></td></tr> : null}
              { record.properties? <tr><td><p> <span style={{fontWeight:'bold'}}>Dirección:</span></p></td><td><p> {record.properties.address}</p></td></tr> : null}
            </table>
           </div>
  }
    
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }

  noResults(){
    return <div style={{paddingTop:'50px', paddingBottom:'50px'}}>
      <img alt="No Data" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K"></img>
      <p style={{paddingTop:'10px'}} >No hay solicitudes { this.state.filteredInfo.status.length>0? this.state.filteredInfo.status[0] : null }</p>
    </div>
  }

  renderAprovedIcon(record){
    if(record.status === 'Por aprobar'){
      return <Tooltip title="Por aprobar">
        <Icon type="minus-circle" style={{color: "yellow"}} />
      </Tooltip>
    }else if(record.status === 'Aprobado'){
      return <Tooltip title="Aprobado"> <Icon type="check-circle" style={{color: "green"}} />
      </Tooltip>
    }else if(record.status === 'Rechazado'){
      return <Tooltip title="Rechazado"> <Icon type="close-circle" style={{color: "red"}} /> </Tooltip>
    }else{
      return <Tooltip title="Incompleto"> <Icon type="exclamation-circle" style={{color: "gray"}} /> </Tooltip>
    }
  }

    render() {
      const users = this.state.data_teachers;
        return(
          <div> 
            <Table locale={{ emptyText: this.noResults() }} onChange={this.handleChange} loading={this.state.loading} pagination={{ pageSize: 200 }} dataSource={users} size="small" expandedRowRender={record=>this.renderFullRow(record)} rowKey="id"  >
              <Column
                title="Id Solicitud"
                dataIndex="id"
                key="id"
                defaultSortOrder='ascend'
                sorter={(a,b)=>a.id-b.id}
              />
              <Column
                title="Name"
                key="name"
                render={
                  (text,record) => (
                    <p style={{textTransform:'capitalize'}}>{record.user.name.toLowerCase()} {record.user.last_name.toLowerCase()}</p>
                  )
                }
              />

              <Column
                title="Estado"
                dataIndex="status"
                key="status"
                filters={[{
                  text: 'Rechazado',
                  value: 'Rechazado',
                }, {
                  text: 'Incompleto',
                  value: 'Incompleto',
                },
                {
                  text: 'Por aprobar',
                  value: 'Por aprobar',
                },
                {
                  text: 'Aprobado',
                  value: 'Aprobado',
                }]}
                onFilter={(value, record) => record.status.indexOf(value) === 0 }
                filteredValue={this.state.filteredInfo.status}
                render={(text, record) => (
                  <span>
                    { this.renderAprovedIcon(record) }
                  </span>
                )}
              />
              <Column
                title="Action"
                key="action"
                Id="id"

                render={(text, record) => (
                  <span>
                    <Link to={"quotations/" + record.id}  params={record.id}><Icon type="eye"  /> Ver</Link>
                  </span>
                )}
              />
            </Table>
          </div>
        )
    }
}

export default Admin;
