/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './intro.css';
import '../ResetFont.css';
import './introMobile.css';
import './introTablet.css';
import './introDesktop.css';

/*Imagenes */
import ImgPrincipalMobile from '../../../assets/img/ciudad_principal_v2.png';
import ImgPrincipalDesktop from '../../../assets/img/ciudad_v3_grande.png';
import ImgPrincipal4k from '../../../assets/img/ciudad_v3_gigante.svg';

class Intro extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        {/* Seccion 1 */}
        <div className="introContainer">
          <Row>
            <Col span={24} className="subtitle">Hacemos el arriendo más facil para todos.
              <br />
              <span className="subtitle2">
                Arrendamos de manera rápida, segura, online y recibe tu arriendo siempre!
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <img className="imgPrincipalMobile" src={ImgPrincipalMobile} alt="arriendo asegurado es genial" />
              <img className="imgPrincipalDesktop" src={ImgPrincipalDesktop} alt="arriendo asegurado es genial" />
              <img className="imgPrincipal4k" src={ImgPrincipal4k} alt="arriendo asegurado es genial" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button className="btnPrincipal btnIntro" type="primary"><Link to="/lead">Publica tu propiedad</Link></Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Intro;
