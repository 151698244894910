import React, {Component} from "react"
import {Tag, Switch, Row, Col} from 'antd'

const CheckableTag = Tag.CheckableTag;

class SwitchOption extends Component {

constructor(props) {
    super(props);
    
    this.state = {
        value : false
    }
}


  handleChange(tag){
      this.setState({value:tag})
      this.props.update({[this.props.name] : tag})
  }


  render() {
    return (
        <Row>
            <Col xs={10} >
                {this.props.label}
            </Col>
            <Col xs={9} style={{textAlign:'right', paddingRight:'20px'}} >
                <Switch onChange={(checked)=>this.handleChange(checked)} />
            </Col>
              
        </Row>
    )
  }
}

export default SwitchOption