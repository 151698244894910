import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select, Divider, List, Row, Empty
  } from 'antd';



const Option = Select.Option;





class BankAccount extends Component {

    editBank = ()=>{

    }

    empty(){
        return <Empty
        image={null}
        description={
          <span>
            Configura la cuenta en que recibirás tu pago mensual
          </span>
        }
      >
        <Button onClick={()=>this.editBank()} type="primary">Completar información</Button>
      </Empty>
    }

    bankAccountList(){
        let props
        return <List style={{margin:'0 auto'}} locale={{emptyText: this.empty() }} ></List>
    }

    render() {
    let {property} = this.props

    return <div>
            <Card bodyStyle={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0px', paddingTop:'10px'}} style={{border:'0px'}}>
                <div style={{flex:1, textAlign:'left'}} >
                    <h3>Cuenta Bancaria</h3>                
                </div>

            </Card>
            <Divider style={{ margin: '3px 0'}} />
            <Card bodyStyle={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0px', paddingTop:'10px'}} style={{border:'0px'}}>
                {this.bankAccountList()}
            </Card>
        </div>
    }
}

export default BankAccount;
