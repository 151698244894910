/*Elementos */
import React, { Component } from 'react';
import { Row, Button } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './precios.css';
import '../ResetFont.css';
import './preciosMobile.css';
import './preciosTablet.css';
import './preciosDesktop.css';

import Tab from './tabEjemplo';

class Precios extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="planesContainer">
          <h1 className="tituloPasos tituloPrecios">Nuestros precios</h1>
          <Row className="planesOpcionBtn">
              <main>
                <Tab>
                  <p className="pagoMensualTitle">Pago anual</p>
                  <span className="pAnualContent active">
                    <div className="cards planesHome">
                      <span className="planTitle">Plan Silver</span>
                      <br />
                      <span className="planSubtitle">
                        1/2 mes + iva <br /> (por corretaje)
                      </span>
                      <br />
                      <span className="planMas">+</span>
                      <div className="circle circle1">
                        5%
                      </div>
                      <p className="planFootTxt">
                        Del valor del arriendo mensual
                        por el servicio completo
                      </p>
                    </div>
                    <div className="cards planesHome">
                      <span className="planTitle">Plan Gold</span>
                      <br />
                      <span className="planSubtitle">
                        1/2 mes + iva <br /> (por corretaje)
                      </span>
                      <br />
                      <span className="planMas">+</span>
                      <div className="circle circle2">
                        7%
                      </div>
                      <p className="planFootTxt">
                        Del valor del arriendo mensual
                        por el servicio completo
                      </p>
                    </div>
                  </span>
                  <p className="pagoMensualTitle">Pago mensual</p>
                  <span className="pAnualContent">
                    <div className="cards planesHome">
                      <span className="planTitle">Plan Silver</span>
                      <br />
                      <span className="planSubtitle">
                        1/2 mes + iva <br /> (por corretaje)
                      </span>
                      <br />
                      <span className="planMas">+</span>
                      <div class="circle circle1">
                        6,25%
                      </div>
                      <p className="planFootTxt">
                        Del valor del arriendo mensual
                        por el servicio completo
                      </p>
                    </div>
                    <div className="cards planesHome">
                      <span className="planTitle">Plan Gold</span>
                      <br />
                      <span className="planSubtitle">
                        1/2 mes + iva <br /> (por corretaje)
                      </span>
                      <br />
                      <span className="planMas">+</span>
                      <div class="circle circle2">
                        7,50%
                      </div>
                      <p className="planFootTxt">
                        Del valor del arriendo mensual
                        por el servicio completo
                      </p>
                    </div>
                  </span>
                </Tab>
              </main>
          </Row>
          <Row>
            <Button className="btnPrincipal btnVerDetalles" type="primary"><Link to="/lead">Ver detalles</Link></Button>
          </Row>
        </div>
      </div>
    );
  }
}

export default Precios;
