import React, { Component } from 'react';
import { Layout, Alert } from 'antd';
import { Form, Icon, Input, Button,  notification } from 'antd';
import {AuthService} from './AuthService'
import { updateData } from '../../../utils/intercom';

const FormItem = Form.Item;

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      email:'',
      password:''
    };
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }


  componentDidMount() {
    updateData({})
    const query = new URLSearchParams(this.props.location.search);
    let error = query.get('error')
    if(error){
      this.setState({error:error})
    }
  }

  redirect() {
    if(AuthService.user.type==="admin"){
      this.props.history.push('/dashboard')
    }else if(AuthService.user.type==="propietary"){
      this.props.history.push('/propietary/properties')
    }else{
      this.props.history.push('/')
    }
     
  }

  handleChange(e){
    this.setState({
      [e.id] : e.value
    })
    
  }

  showError(data){
    notification.open({
      message: 'No autorizado',
      description: 'Tu combinación clave/contraseña es incorrecta, vuelve a intentarlo.',
      icon: <Icon type="error" style={{ color: 'red' }} />,
    });
    
  }

  handleSubmit(e) {
    e.preventDefault()
    let credentials = {
      email:this.state.email,
      password:this.state.password
    }
    AuthService.authenticate(credentials, (res,data)=>{
      if(res){
        this.redirect()
      }else{
        this.showError(data);
      }
    } );
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    let form =
      <Layout style={{ width: '100vw', height: '100vh', flex: 1, alignItems: 'center', flexDirection: 'column' }} className="cont-register login-cont" >
      
        <Layout className="cont-register-form" >

            {this.state.error ?
              <Alert message={this.state.error + ', Por favor ingresa a continuación '}
                type="warning"
                closable /> : null
            }
            

            <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
              <FormItem>
                <h2>ArriendoAsegurado</h2>
                <hr />
                <p>Administración</p>
              </FormItem>
              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Ingresa tu email!' }],
                })(
                  <Input onChange={(e) => this.handleChange(e.target)} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Ingresa tu contraseña!' }],
                })(
                  <Input onChange={(e)=>this.handleChange(e.target)} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                )}
              </FormItem>
              <FormItem>


                <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  <Icon type="login" /> Ingresar
                </Button>
                </FormItem>

                <FormItem>
                  <p style={{ marginBottom:0, marginTop:'20px' }}>¿No recuerdas tu contraseña?</p>
                  <a>Recuperar contraseña</a>
                </FormItem>

              </FormItem>
            </Form>

        </Layout>

      </Layout>

    return form


  }
}

export const WrappedLogin = Form.create()(Login);
