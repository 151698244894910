import {  PYTHON_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class PaymentService
{

    getDelayedPayments(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })

        return http.get( '/admin/payments/delayed/' ).catch( error => { console.log(error); return false })
    }

    getTodayPayments(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })

        return http.get( '/admin/payments/today/' ).catch( error => { console.log(error); return false })
    }
}

export const paymentService = new PaymentService();
