import React, { Component } from 'react';
import './Footer.css'
import './FooterMobile.css'
import './FooterTablet.css'
import './FooterDesktop.css'
import logoTituloMobile from '../../../assets/img/logo-white.png';
import mapMarker from '../../../assets/img/map-marker.png';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className="footerContainer" id="footer">
          <Row>   
            <p>
              <img className="mapIcon" src={mapMarker} alt="ubicación de nuestra empresa"/>
              Av. del Parque 5275, Of. 504 <br/>
              Ciudad Empresarial <br/>
              Santiago de Chile <br/>
              +562 29405026 <br/>
              contacto@arriendoasegurado.com
            </p>
          </Row>
          <Row>
            <div className="footerNavigationContainer">
              <Button className="btnTerciarioOscuro"><Link to="/lead">Blog</Link></Button>
              <Button className="btnTerciarioOscuro"><Link to="/lead">Términos y condiciones</Link></Button>
              <Button className="btnTerciarioOscuro"><Link to="/lead">Preguntas frecuentes</Link></Button>
              <Button className="btnTerciarioOscuro"><Link to="/lead">Política de privacidad</Link></Button>
              <Button className="btnTerciarioOscuro"><Link to="/lead">Sobre nosotros</Link></Button>
            </div>
          </Row>
          <Row>
            <img className="logoTitulo logoFooter" src={logoTituloMobile} alt="arriendo asegurado.com"/>
          </Row>
          
      </div>
    );
  }
}

export default Footer;
