import React, {Component} from "react"
import {Tag} from 'antd'

const CheckableTag = Tag.CheckableTag;

class ChipOptions extends Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            value : props.default ? props.default : 1
        }
      }

  handleInputChange(e) {
    this.setState({search: e.target.value, value: e.target.value})
  }

  handleChange(tag){
      this.setState({value:tag})
      this.props.update({ [this.props.name] : tag })
  }

  mapChips(){
      return this.props.data.map( (item)=>{
          return  <CheckableTag
            style={{paddingTop:'11px',paddingBottom:'9px',height:'auto', ...this.props.itemStyle }}
            key={item}
            checked={this.state.value === item}
            onChange={() => this.handleChange(item)}>
                <span style={{fontSize:'14px'}}>{item}</span>
          </CheckableTag>
      } )
  }

  render() {
    const {search, value} = this.state
    return (
        <div>
            {this.mapChips()}
        </div>
    )
  }
}

export default ChipOptions