import React, { Component } from 'react';
import {
	Form, Card, Button, Radio, Input, Select, Divider, InputNumber, Icon
} from 'antd';

import PlaceSuggest from './PlaceSuggest';

import { Comunas, Regiones } from '../../../../Shared/partials/Address'
import ChipOptions from '../../../../Shared/partials/ChipOptions';
import SwitchOption from '../../../../Shared/partials/SwitchOption';
import { propertyService } from '../../../../../services/propertyService';
import { updateData } from '../../../../../utils/intercom';

const Option = Select.Option;


const numberWithCommas = (x) => {
	return '$ ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

class PropertyDetail extends Component {
	state = {
		price: '',
		tipo_moneda: 'Pesos',
		gc: '',
		m_construidos: '',
		m_terreno: '',
		dormitorios: 1,
		banos: 1,
		servicios: false,
		amoblado: false,
		calefaccion: false,
		piscina: false,
		condominio: false
	};

	componentDidMount() {
		updateData({})
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	onChangeAmount = (value, name) => {
		this.setState({
			[name]: value,
		});
	}

	validate() {
		let {
			price,
			tipo_moneda,
			gc,
			m_construidos,
			m_terreno,
			dormitorios,
			banos,
			servicios,
			amoblado,
			calefaccion,
			piscina,
			condominio
		} = this.state

		if (m_construidos == 0 || m_terreno == 0 || price == null || price == 0) {
			this.showAlert(price, m_construidos, m_terreno)
			// alert("Ingresa toda la información requerida")
			return false
		} else {
			return true
		}

	}

	showAlert(price, m_construidos, m_terreno){
		document.getElementById('alertError').className = 'alert alert-error';
		document.getElementById('price').className = 'inputRequired';
		document.getElementById('mConstruidos').className = 'inputRequiredNumb';
		document.getElementById('mTerreno').className = 'inputRequiredNumb';
		window.scrollTo(0, 0);
	}

	submit = () => {
		if (this.validate()) {
			let { price, tipo_moneda, gc, m_construidos, m_terreno, dormitorios, banos, servicios, amoblado, calefaccion, piscina, condominio } = this.state

			let response = propertyService.complete({
				uid: this.props.uid,
				valor: price,
				calefaccion: calefaccion,
				tipo_moneda: tipo_moneda,
				gc: gc,
				piscina: piscina,
				condominio: condominio,
				amoblado: amoblado ? 1 : 0,
				servicios: servicios ? 1 : 0,
				m_construidos: m_construidos,
				m_terreno: m_terreno,
				dormitorios: dormitorios,
				banos: banos
			})
			if (response) {
				let user = JSON.parse(localStorage.getItem('user'))
				user.intercomstage = 3
				user.pipedrivestage = 189
				user.nombrestage = 'CREACION_USUARIO'
				updateData(user)
				this.props.history.push('/propietary/set_password')
			}
		}
	}


	render() {

		return <div style={{maxWidth:'500px', margin:'0 auto', padding:'3%'}}>

			<Card style={{ border: '0px' }}>
				<Form layout="vertical" >
					<Form.Item>
						<div id="alertError" className="alert alertHide"><Icon type="warning" className="alertIcon"/>Ingresa toda la información requerida</div>
						<h3 className="txtFondoBlanco">Monto de arriendo <br /><p>(No debes incluir gastos comunes)</p></h3>
						<div>
							<InputNumber id="price"
								// style={{ width: '100%' }}
								defaultValue=''
								formatter={value => numberWithCommas(value)}
								parser={
									value => value.replace(/\./g, '').replace(/\$/g, '').replace(' ', '')
								}
								onChange={(value) => this.onChangeAmount(value, 'price')}
							/>                           </div>
					</Form.Item>
					<Form.Item>
						<h3 className="txtFondoBlanco">Tipo Moneda <br /><p>(Especifica si tu arriendo está en pesos o UF)</p></h3>
						<Radio.Group name="tipo_moneda" defaultValue='Pesos' onChange={(e) => this.setState({tipo_moneda: e.target.value})} buttonStyle="solid">
								<Radio.Button clasname="btnSecundario" value="Pesos">Pesos</Radio.Button>
								<Radio.Button clasname="btnSecundario" value="U.F.">U.F.</Radio.Button>
						</Radio.Group>

						{/* <ChipOptions name="tipo_moneda" default='Pesos' update={(data) => this.setState(data)} data={['Pesos', 'U.F.']} /> */}
					</Form.Item>
					<Form.Item>
						<div className="formitem">
							<h3 className="txtFondoBlanco">Gastos comunes aprox.<br /><p>(Ingresa un monto aproximado, si no sabes déjalo en blanco)</p></h3>

							<InputNumber
								// style={{ width: '100%' }}
								defaultValue=''
								formatter={value => numberWithCommas(value)}
								parser={
									value => value.replace(/\./g, '').replace(/\$/g, '').replace(' ', '')
								}
								onChange={(value) => this.onChangeAmount(value, 'gc')}
							/>
						</div>
					</Form.Item>
					<Form.Item>
						<h3 className="txtFondoBlanco">Superficie Construida</h3>
						<div>
							<InputNumber id="mConstruidos" onChange={(e) => this.onChange({ target: { name: 'm_construidos', value: e } })} addonAfter={<span>m2</span>} placeholder="112" name="m_construidos" className="input-m2" defaultValue="" />
							<span className="m2">m2</span> 
            </div>
					</Form.Item>
					<Form.Item>
						<h3 className="txtFondoBlanco">Superficie Total</h3>
						<div>
							<InputNumber id="mTerreno" onChange={(e) => this.onChange({ target: { name: 'm_terreno', value: e } })} addonAfter={<span>m2</span>} placeholder="112" name="m_terreno" className="input-m2" defaultValue="" /> 
							<span className="m2">m2</span> 
            </div>
					</Form.Item>
					<Divider />
					<Form.Item>
						<div className="formitem">
							<h3 className="txtFondoBlanco">Habitaciones</h3>
							<div className="circlesContainer">
								<ChipOptions name="dormitorios" update={(data) => this.setState(data)} data={[1,2,3,4,5,6 ]} />
							</div>
						</div>

					</Form.Item>
					<Divider />
					<Form.Item>
						<div className="formitem">
							<h3 className="txtFondoBlanco">Baños</h3>
							<div className="circlesContainer">
								<ChipOptions name="banos" update={(data) => this.setState(data)} data={[1, 2, 3, 4, 5, 6]} />
							</div>
						</div>

					</Form.Item>
					<Divider />

					<Form.Item>
						<div className="formitem txtFondoBlanco">
							<h3 className="txtFondoBlanco">Marque según corresponda</h3><br />
							<div className="switchRow">
								<SwitchOption className="switchOptions" name="servicios" update={(data) => this.setState(data)} label="Tiene pieza de servicio" />
							</div>
							<div className="switchRow">
								<SwitchOption className="switchOptions" name="amoblado" update={(data) => this.setState(data)} label="Amoblado" />
							</div>
							<div className="switchRow">
								<SwitchOption className="switchOptions" name="calefaccion" update={(data) => this.setState(data)} label="Tiene calefacción" />
							</div>
							<div className="switchRow">
								<SwitchOption className="switchOptions" name="piscina" update={(data) => this.setState(data)} label="Tiene piscina" />
							</div>
							{/* <div className="switchRow">
								<SwitchOption className="switchOptions" name="condominio" update={(data) => this.setState(data)} label="Es condominio" />
							</div> */}
						</div>
					</Form.Item>
					<Divider />
					<div className="btnContainer">
						<Button type="primary" onClick={() => this.submit()} loading={this.state.loading} className="btnPrincipal">Continuar</Button>
					</div>		

                </Form>
			</Card>


		</div>
	}
}

export default PropertyDetail;
