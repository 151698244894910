import React, { Component } from 'react';
import './comentariosLead.css';

class comentariosLead extends Component {
  render() {
    return (
      <div>
        comentarios de los usuarios
      </div>
    );
  }
}

export default comentariosLead;
