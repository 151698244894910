/*Elementos */
import React, { Component } from 'react';

/*CSS*/
// import './Landing.css';
// import './ResetFont.css';
// import './landingMobile.css';
// import './landingDesktop.css';

/* Secciones del home */
import Intro from './intro/intro';
import Beneficios from './beneficios/beneficios';
import PagoFacil from './pagoFacil/pagoFacil';
import ComoFunciona from './comoFunciona/comoFunciona';
import Precios from './precios/precios';
import Aliados from './aliados/aliados';
import Prensa from './prensa/prensa';
import Contacto from './contacto/contacto';
import { updateData } from '../../utils/intercom';

class Landing extends Component {

  componentDidMount(){
    updateData({})
  }

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <Intro />
        <Beneficios />
        <PagoFacil />
        <ComoFunciona />
        <Precios />
        <Aliados />
        <Prensa />
        <Contacto />
      </div>
    );
  }
}

export default Landing;
