import React, { Component } from 'react';
import { Row, Col, Button, Divider, Input, Icon } from 'antd';
import { passwordService } from '../../../services/passwordService';
import { updateData } from '../../../utils/intercom';

import './properties/password.css';

class ApplicantList extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        properties : []
    }
  }

  async componentDidMount(){
    updateData({})
  }



   setPassword = async () => {
      if(this.state.password && this.state.repeatPassword){
           if(this.state.password === this.state.repeatPassword && this.state.password.length >3){
                let pass = await passwordService.change(this.state.password)
                 if(pass){
                  localStorage.setItem('set_password', false)
                  let uid = localStorage.getItem('property_uid')

                  let user = JSON.parse(localStorage.getItem('user')) 
                  user.intercomstage = 4
                  user.pipedrivestage = 195
                  user.nombrestage = 'COORDINAR_FOTO'
                  updateData(user)

                  this.props.history.push('/propietary/properties/' + uid + '/select_listing')


                }else{
										// alert('no se pudo cambiar')
										document.getElementById('errNotChange').className = 'alert alert-error';
										document.getElementById('errMissOne').className = 'alert alertHide';
										document.getElementById('errNotMatch').className = 'alert alertHide';
                } 
           }else{
								// alert('No coinciden o son muy cortas')
								document.getElementById('errNotChange').className = 'alertHide';
								document.getElementById('errMissOne').className = 'alert alertHide';
								document.getElementById('errNotMatch').className = 'alert alert-error';
           }
      }else{
					// alert('Ingresa ambas')
					document.getElementById('errMissOne').className = 'alert alert-error';
					document.getElementById('errNotChange').className = 'alert  alertHide';
					document.getElementById('errNotMatch').className = 'alert alertHide';
      }
  }
  



	render() {
		return <div style={{ width: '90%', margin: '0 auto' }} >
			<Row className="admin-header" gutter={16}>
				<Col span={20}>
					<h1 className="tituloInfo1">Crea tu contraseña</h1>
				</Col>
				{/* <Col span={4}>
                    <Button onClick={()=>this.create()} type="primary">Nuevo Postulante</Button>
                </Col> */}
				<Divider />
				<h2 className="tituloInfo1 txtFondoBlanco subtitlePassword">Para poder continuar y guardar tu información crea una contraseña </h2>
				<div className="cards cardPasword">
					<div id="errNotChange" className="alert alertHide"><Icon type="warning" className="alertIcon alertIconPassword"/>No se pudo cambiar la contraseña</div>
					<div id="errNotMatch" className="alert alertHide"><Icon type="warning" className="alertIcon alertIconPassword"/>No coinciden o son muy cortas</div>
					<div id="errMissOne" className="alert alertHide"><Icon type="warning" className="alertIcon alertIconPassword"/>No ingresaste las contraseñas</div>
					<h3 className="txtFondoBlanco labelPassword">Contraseña</h3>
					<p className="txtFondoBlanco helpPassword">La contraseña debe contener mínimo 6 caracteres</p>		
					<Input className="passwordInput" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} placeholder="Crea tu contraseña" />
					<h3 className="txtFondoBlanco labelPassword">Repite la contraseña</h3>
					<Input className="passwordInput" value={this.state.repeatPassword} onChange={(e) => this.setState({ repeatPassword: e.target.value })} placeholder="Repite tu contraseña" />
					<div className="btnContinuar">
						<Button className="btnPrincipal" onClick={() => this.setPassword()} type="primary">Guardar</Button>
					</div>
				</div>				
				

			</Row>
		</div>
	}
}

export default ApplicantList;
