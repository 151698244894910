import {  API_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class MoneyService
{

    getUf(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: API_URL,  headers: headers  })
        return http.get( '/api/v1/get_uf' ).catch( error => { console.log(error); return false })
    }


}

export const moneyService = new MoneyService();
