import React from 'react';
import { Form, Row, Col, Button, Table } from 'antd';
import { paymentService } from '../../../../services/paymentService';
import { applicantService } from '../../../../services/applicantService';
import {  NavLink, Link } from "react-router-dom";

const columns = [{
    title: 'ID Solicitud',
    key: 'name',
    render:(text, record) => (
        <span>
          { record.name } {record.last_name} {record.second_last_name}
        </span>
      )
  },{
    title: 'Teléfono',
    key: 'phone',
    dataIndex: 'phone',
  },{
    title: 'Estado',
    key: 'approved',
    render:(text, record) => (
        <span>
          { record.approved? <span>Aprobado</span> : <span>Por aprobar</span> }
        </span>
      )
  },{
    title: 'Acciones',
    key: 'name',
    render:(text, record) => (
        <span>
            <Link to={'/applicants/' + record.id}  >Ver Más</Link>  |  {record.approved ? <a href={"/quotations/"}>Aprobar</a> : <a href={"/quotations/"}>Rechazar</a> } 
        </span>
      )
  }


];

class Applicants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true
        };
    }

    async componentDidMount() {
        let request = await applicantService.getAll()
        if(request ){
          await this.setState({
              loading:false,
              applicants: request.data
          })
        }
    }

    renderApplicants = ()=>{

        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(this.state.applicants){
            if(this.state.applicants.results.length === 0) return 'No hay pagos atrasados'
        }
        
        return this.state.applicants ?
        <Table columns={columns} dataSource={ this.state.applicants.results } /> : 'No hay pagos atrasados';
    }

    goToQuotation = (quotation_id)=>{
        console.log(quotation_id);
    }

    renderTodayPayments = ()=>{
        let payments = this.state.today ? this.state.today.results : null
        if(this.state.loading){
            return <Button shape="circle" loading />
        }

        if(payments){
            if(payments.length === 0) return 'No hay pagos agendados para el día de hoy'
        }

        return this.state.today ?
        <Table columns={columns} dataSource={ this.state.today.results } /> : null;
    }

    render() {

        return (
                <div>
                    <Row >
                        <Col className="gutter-row" >
                            <h2>Postulantes</h2>
                            {this.renderApplicants()}
                        </Col>

                    </Row>

                </div>

        );
    }
}

export const ApplicantIndex = Form.create()(Applicants);