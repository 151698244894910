import {  PYTHON_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class PasswordService
{

    change(password){
        let headers = { 'Content-Type': 'application/json', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })
        return http.post( '/propietary/change_password', {password:password} ).catch( error => { console.log(error); return false })
    }
}

export const passwordService = new PasswordService();
