import React, { Component } from 'react';
import { Row, Col, Button, Icon } from 'antd';
import './ventajasLead.css';

class ventajasLead extends Component {
  render() {
    return (
      <div className="ventajasContainer">
        <h2 className="txtFondoOscuro">¿Por qué ArriendoAsegurado.com?</h2>
        <Col span={3} className="checkCol">
          <Icon className="checkIcon" type="check" />
          <Icon className="checkIcon" type="check" /> 
          <Icon className="checkIcon" type="check" />   
        </Col>
        <Col span={9} className="textCol">
          <p className="txtFondoOscuro txtVentajas">Arrendamos mucho más rápido</p>
          <p className="txtFondoOscuro txtVentajas">Recibe el dinero del arriendo <span className="boldText">Siempre!</span></p>
          <p className="txtFondoOscuro txtVentajas">Nos ocupamos de tu arriendo de principio a fin</p>
        </Col>
      </div>
    );
  }
}

export default ventajasLead;
