import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import {statsService} from '../../../../../services/statsService'
import { Card, Row, Col } from 'antd';
import PlanPie from './plans';

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

const data = {
    datasets: [{
        label: 'Ventas',
        type:'line',
        data: [],
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        yAxisID: 'y-axis-2'
      },{
        type: 'bar',
        label: 'Ingreso Bruto',
        data: [],
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        yAxisID: 'y-axis-1'
      }]
  };


  const options = {
    responsive: true,
    tooltips: {
        mode: 'label',
        callbacks: {
            label: function(tooltipItem, data) {
               
                return data.datasets[tooltipItem.datasetIndex].label + ': '+  numberWithCommas(tooltipItem.yLabel)
            }
        }
    },
    elements: {
      line: {
        fill: false
      }
    },
    scales: {
        xAxes: [
            {

              display: true,
              gridLines: {
                display: false
              },
          
              labels: [],
            }
          ],
          yAxes: [
            {
              ticks: {
                    callback: function (value) {
                        return numberWithCommas(value)
                    }
              },
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              }
            },
            {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-axis-2',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              }
            }
          ]
    }
  };

  const plugins = [];

class SubscriptionSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getSum(total, num) {
        return total + num;
    }

    async componentDidMount(){
        let data = await statsService.getSummary()
        let mrr = await statsService.getMRR()
        if(mrr){
            this.setState({mrr: mrr.data.mrr})
        }
        if(data){
                     
           let items = (data.data);
           items = (items.data);
           
           let count=[], subscription_price=[], debt_continental=[], label = []
           let total = 0
           for(let i = 0; i<items.length; i++ ){
                count.push(items[i][0])
                subscription_price.push(parseInt(items[i][1]))
                debt_continental.push(items[i][2])
                label.push(items[i][3])
                total+=(items[i][0])
           }

           await this.setState({
               count:count,
               subscription_price:subscription_price,
               debt_continental:debt_continental,
               label:label,
               total:total
           })

           this.forceUpdate()
        }
    }

    render() {
        if(this.state.count){
            options.scales.xAxes[0].labels = this.state.label
            data.datasets[0].data = this.state.count
            data.datasets[1].data = this.state.subscription_price
        }
        
        return (
        <div>
            <Row>
                <Col  span={5}>
                    <div>
                        <p style={{marginBottom:0}}>Ventas Totales: </p>
                        <h4>{this.state.total ? this.state.total : null}</h4>
                        <p style={{marginBottom:0}}>Ingreso bruto total: </p>
                        <h4>{this.state.subscription_price ? numberWithCommas( this.state.subscription_price.reduce(this.getSum)) : null}</h4>
                        <p style={{marginBottom:0}}>Ingreso neto total: </p>
                        <h4>{this.state.subscription_price ? numberWithCommas( this.state.subscription_price.reduce(this.getSum) - this.state.debt_continental.reduce(this.getSum) ) : null}</h4>
                        <p style={{marginBottom:0}}>MRR: </p>
                        <h4>{this.state.mrr ? numberWithCommas( this.state.mrr) : null}</h4>
                    </div>
            
            </Col>
            <Col span={5} >
                <PlanPie/>
            </Col>
            
            </Row>

            <Card>
            <Bar
                data={data}
                options={options}
                plugins={plugins}
                />
            </Card>
        </div>
        );
  }

}

export default SubscriptionSummary;