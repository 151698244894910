/*Elementos */
import React, { Component } from 'react';
import { Col, Button, Card, Tabs, } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './beneficios.css';
import '../ResetFont.css';
import './beneficiosMobile.css';
import './beneficiosTablet.css';
import './beneficiosDesktop.css';

/*Imagenes */
import ImgBen1Mobile from '../../../assets/img/rent_fast.png';
import ImgBen2Mobile from '../../../assets/img/pago_seguro.png';
import ImgBen3Mobile from '../../../assets/img/relax.png';
import ImgBen1Arrendatario from '../../../assets/img/beneficio_sinAval.png';
import ImgBen2Arrendatario from '../../../assets/img/beneficio_cualquierMedio.png';
import ImgBen3Arrendatario from '../../../assets/img/beneficio_rapido.png';


const TabPane = Tabs.TabPane;

class Beneficios extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="beneficiosContainer">
          <Tabs className="tabsBeneficios" id="tabsMobile" defaultActiveKey="1" onChange={this.callback}>
            <TabPane className="propietario" tab="Soy propietario" key="1">
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards beneficio1Card cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile" src={ImgBen1Mobile} alt="arrienda seguro siempre" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion">
                    <span className="titleTextCard">
                     Arrendamos mucho más rápido.
                    </span>
                    <br />
                      Conseguimos y revisamos a los arrendatarios de manera gratuita y veloz para que tu propiedad no esté vacia
                    </p>
                  </Col>
                </Card>
              </Col>
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile" src={ImgBen2Mobile} alt="revisamos a tus arrendatarios gratis" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion"><span className="titleTextCard">Recibe tu dinero del arriendo SIEMPRE!.</span><br />Contamos con un seguro en caso de que no pague tu arrendatario</p>
                  </Col>
                </Card>
              </Col>
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile" src={ImgBen3Mobile} alt="recibe tu dinero sin falta cada mes" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion"><span className="titleTextCard">Nos ocupamos de tu arriendo de principio a fin:</span> cobranza digital, gestión del contrato en línea y toda la información en tu cuenta</p>
                  </Col>
                </Card>
              </Col>
            </TabPane>
            {/* TAB ARRENDATARIO */}
            <TabPane className="arrendatario" tab="Soy arrendatario" key="2">
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards beneficio1Card cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile imgBeneficioArrendatario1" src={ImgBen1Arrendatario} alt="arrienda seguro siempre" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion beneficioArrendatarioDescripcion"><span className="titleTextCard">Sin necesidad de AVAL, cheques de garantía u otros.</span><br />Solo necesitas ser aprobado por nuestro análisis de crédito.</p>
                  </Col>
                </Card>
              </Col>
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile imgBeneficioArrendatario2" src={ImgBen2Arrendatario} alt="revisamos a tus arrendatarios gratis" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion beneficioArrendatarioDescripcion"><span className="titleTextCard">Puedes pagar tu arriendo </span>con cualquier medio de pago, incuyendo tarjetas de crédito </p>
                  </Col>
                </Card>
              </Col>
              <Col span={{ sm: 24, md: 8, lg: 8 }}>
                <Card className="cards cardsBeneficios">
                  <Col span={{ sm: 12, md: 24, lg: 24 }} >
                    <img className="imgBeneficio1Mobile imgBeneficioArrendatario3" src={ImgBen3Arrendatario} alt="recibe tu dinero sin falta cada mes" />
                  </Col>
                  <Col className="beneficio1Txt" span={{ sm: 12, md: 24, lg: 24 }} >
                    <p className="beneficio1CardDescripcion beneficioArrendatarioDescripcion"><span className="titleTextCard">Todo fácil</span>y rápido para que no pierdas tiempo</p>
                  </Col>
                </Card>
              </Col>
            </TabPane>
          </Tabs>
          <Button className="btnTerciario btnBeneficios"><Link to="/lead">Publica tu propiedad ahora</Link></Button>
        </div>
      </div>
    );
  }
}

export default Beneficios;
