import {  API_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class QuotationService
{

    quotations(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: API_URL,  headers: headers  })

        return http.get( '/api/v1/quotations' ).catch( error => { console.log(error); return false })
    }
    quotation(id){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: API_URL,  headers: headers  })

        return http.get( '/api/v1/quotations/'+id+'/show_full_info' ).catch( error => { console.log(error); return false })
    }


}

export const quotationService = new QuotationService();
