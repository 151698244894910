import React, { Component } from 'react';
import {
    Form, Card, Button, Row, Input, Select, Divider, Col, PageHeader, DatePicker, Drawer
  } from 'antd';
import PropertyEdit from '../partials/PropertyEdit';
import './styles.css'
import {numberWithCommas} from '../../../../../utils'

const Option = Select.Option;



class PropertyTitle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            visible:false
        }
    }
    




   render() {
    let {property} = this.props

        return <div>
            <Card bodyStyle={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0px', paddingTop:'20px'}} style={{border:'0px'}}>
                <div style={{flex:11, textAlign:'left'}} >
              <PageHeader
                onBack={() => console.log(this.props.history.push('/propietary/properties')) }
                title={property.address + ' ' + property.street_number}
                subTitle={property.comuna + ', ' + property.region}
              />
                </div>
                <div style={{flex:5}} >
                    { property.property_details.length > 0 ?
                    <h2  style={{marginBottom:'0px'}} >{property.property_details[0].tipo_moneda === 'Pesos' ? '$ '+  numberWithCommas(property.property_details[0].valor)  : property.property_details[0].valor + ' UF'  }</h2> : null
                    }
                    <p onClick={()=>this.setState({visible:true})} className="btnTeciario">EDITAR PROPIEDAD</p>
                </div>
            </Card>


        <Drawer
            title="Editar Propiedad"
            width={window.innerWidth}
            height={window.innerHeight * 0.9}
            placement='bottom'
            onClose={ ()=> this.setState({visible:false}) }
            visible={this.state.visible}
            bodyStyle={{height:'100%'}}
        >
        
            <PropertyEdit property={property} />
        
        </Drawer>
            

        </div>
    }
}

export default PropertyTitle;
