import React, { Component } from 'react';
import { pythonService } from '../../services/pythonService';

class Pay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_type: '',
            onload: '',
            lessor_name: "",
            lessor_last: "",
            lessor_rut: "",
            error: [],
            isInputDisabled: false,
            loader: "false",
            isButtonDisabled: false,
            isRutifyActive: true,
            rutValido: false
        };
        this.updateStatePersonRut = this.updateStatePersonRut.bind(this);
        this.updateStateName = this.updateStateName.bind(this);
        this.updateStateLastName = this.updateStateLastName.bind(this);
    }

    async componentDidMount() {
        if(localStorage.getItem('hmac')){
    let email = localStorage.getItem('email')
    let user_type = localStorage.getItem('role')
    let hmac = localStorage.getItem('hmac')

    window.Intercom("update",{
        user_id : email,
        email : email,
        user_type : user_type,
        user_hash : hmac
    });
}else{
    window.Intercom("update");
}


        await this.setState(pre => { return Object.assign(pre, this.props.location.state) })
        console.log(this.state)
    }

    handleClick = (error) => {
        alert(error)
    }

    updateStateName(e) {
        var update_state = this.state.lessor_name;
        update_state = e.target.value;
        this.setState({ lessor_name: update_state });
    }

    updateStateLastName(e) {
        var update_state = this.state.lessor_last;
        update_state = e.target.value;
        this.setState({ lessor_last: update_state });
    }

    // async updateStatePersonRut(data) {
    //     await this.setState({
    //         lessor_rut: data.rut,
    //         lessor_name: data.name === "" ? "" : data.name,
    //         lessor_last: data.last_name === "" ? "" : data.last_name,
    //         rutValido: data.validate,
    //         isInputDisabled: data.last_name === "" && data.name === "" ? false : true
    //     })
    // }

    async updateStatePersonRut(e) {
        let format = this.formateaRut(e.value);
        let validate = false;
        let array = ["El rut no es válido"];
        await this.setState({
            lessor_rut: format
        })
        if (await this.validar(format) === false) {
            await this.setState({
                rutValido: false,
                error: array
            })
        }
        else {
            if (this.state.lessor_rut.length < 7) {
                await this.setState({
                    rutValido: false,
                    error: array
                })
            }
            else {
                await this.setState({
                    rutValido: true,
                    error: [],
                })
            }
        }
    }

    formateaRut(rut) {
        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 1) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (var i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return rutPuntos;
    }

    async validar(Objeto) {
        var tmpstr = "";
        var intlargo = Objeto
        if (intlargo === undefined || intlargo === "") {
            return false
        }
        if (intlargo.length > 0) {
            var crut = Objeto
            var largo = crut.length;
            if (largo < 2) {
                return false;
            }
            for (var i = 0; i < crut.length; i++)
                if (crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-') {
                    tmpstr = tmpstr + crut.charAt(i);
                }
            var rut = tmpstr;
            crut = tmpstr;
            largo = crut.length;

            if (largo > 2)
                rut = crut.substring(0, largo - 1);
            else
                rut = crut.charAt(0);

            var dv = crut.charAt(largo - 1);

            if (rut == null || dv == null)
                return 0;

            var dvr = '0';
            var suma = 0;
            var mul = 2;

            for (var i = rut.length - 1; i >= 0; i--) {
                suma = suma + rut.charAt(i) * mul;
                if (mul == 7)
                    mul = 2;
                else
                    mul++;
            }
            var res = suma % 11;
            if (res == 1)
                var dvr = 'k';
            else if (res == 0)
                dvr = '0';
            else {
                var dvi = 11 - res;
                dvr = dvi + "";
            }

            if (dvr !== dv.toLowerCase()) {
                return false;
            }
            return true;
        }
    }


    async pagar() {
        await this.setState({
            isButtonDisabled: true,
            loader:"true"

        })

        var rut = this.state.lessor_rut
        var valido = true;
        var error = '';
        if (rut === "") {
            valido = false;
            error += "El rut es invalido. \n "
        }
        if (!this.state.rutValido) {
            valido = false;
            error += "Ingrese un rut válido. \n"
        }
        if (!valido) {
            var array = new Array()
            array = error.split("\n")
            array.splice(array.length - 1)
            for (var i = 0; i < array.length; i++) {
                console.log(array)
                await this.setState({
                    error: array
                })
            }
            await this.setState({
                loader:"false",
                isButtonDisabled:false
            })
            return;
        }
        else {
            let res = await pythonService.pay(this.state.lessor_rut)
            if(res){
                if(res.data.results.length>0){
                    window.location.replace("https://pagos.arriendoasegurado.com/servipag/pay/rental/" + res.data.results[0].contract.quotation[0].id );
                }else{
                    this.handleClick('No se encuentra el pago')
                    await this.setState({
                        loader:"false",
                        isButtonDisabled: false
                    })
                }
            }
            
        }

        
    }


    render() {
        let loading =
            <div className={this.state.loader === "false" ? 'hidden' : ' fadeInRight animated  loader-cont'}>
                <div className="row">
                    <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12  text-center">
                    </div>
                </div>
            </div>

        return (
            <div style={{maxWidth:'500px', margin:'0 auto', paddingLeft:'10%', paddingRight:'10%'}} >
                <section className={this.state.onload == 'true' ? 'selection' : ' fadeInRight animated selection'} >
                    {loading}
                    <div className="container">
                        <div className=" slide-cont">
                            <div className="row">
  
                                <div className="text-center col-lg-10 col-lg-offset-1 col-md-12 col-md-offset-0 col-sm-12">
                                    <h1 className="title">Ingresa el rut del arrendatario para pagar </h1>
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 text-center">
                                    <ul className="this-error" >{this.state.error.map(f => <li key={f}> <i className="fa fa-exclamation"></i> {f} </li>)}</ul>
                                </div>
                            </div>
                            <div className="col-lg-10 col-lg-offset-1 col-md-12 col-md-offset-0 col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12 col-md-offset-1 col-md-10">
                                        <div className="form-group">
                                            <label >Rut</label>
                                            <input className="form-control" name="rutAval" value={this.state.lessor_rut} onChange={(e) => this.updateStatePersonRut(e.target)} />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-10 col-lg-offset-1 col-md-12 col-md-offset-0 col-sm-12 text-center">
                                        <button className="main-btn  " onClick={() => this.pagar()} disabled={this.state.isButtonDisabled}>
                                            Pagar
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Pay;
