import React, { Component } from 'react';
import './TopNavbar.css';
import { Icon, Button } from 'antd';
import {Link} from "react-router-dom";
import logoTituloMobile from '../../../assets/img/logo.png';


class TopNavbar extends Component {

  render() {
    return (
       <ul className="topNavMenu">
        <li className="logoTitulo">
          <Link to="/">
            <img src={logoTituloMobile} alt="arriendo asegurado.com"/>
          </Link>
        </li>
        <div className="buttonContainerNavbar">
          <li className="liNavbar">
            <Button className="buttonNavbar btnPrincipalNav">
              <Link to="/pagar" >
                <Icon type="credit-card" className="iconosMenu"/>Pagar arriendo
              </Link>
            </Button>
          </li>
          <li className="liNavbar btnContactoNavbar">
            <Button className="buttonNavbar btnSecundario">
              <a href="#footer" className="contactA">
                <Icon type="phone" className="iconosMenu"/>Contáctanos
              </a>
            </Button>
          </li>
          <li className="liNavbar">
            <Button className="buttonNavbar btnTerciario">
              <Link to="/login" className="aLink">
                <Icon type="home" className="iconosMenu"/>Ir a mi cuenta
              </Link>
            </Button>
          </li>
        </div>
       </ul>
    );
  }
}

export default TopNavbar;
// ReactDOM.render(<TopNavbar />,document.getElementById('app'));
