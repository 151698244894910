import React, { Component } from 'react';
import Check from './checkListLead';
import Comentarios from './comentariosLead';
import Form from './formLead';
import Ventajas from './ventajasLead';
import Precios from '../Landing/precios/precios';
import ComoFunciona from '../Landing/comoFunciona/comoFunciona';
import { updateData } from '../../utils/intercom';


class Lead extends Component {

  componentDidMount() {
    updateData({})
    window.scroll(0,0)
  }
 
  render() {
    return (
      <div className="leadContainer" style={{marginTop:'100px'}}>
          <h1 className="leadTitle">Crea tu cuenta</h1>
          <h2 className="leadSubtitle">y anuncia tu inmueble ahora</h2>
        <Form {...this.props} />
        <Ventajas />
        <Precios />
        <Check />
        <ComoFunciona />
        <Comentarios />
      </div>
    );
  }
}

export default Lead;
