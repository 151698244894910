import React, { Component } from 'react';
import {
    Form, Card, Button, Radio, Input, Select
  } from 'antd';

import PlaceSuggest from './PlaceSuggest';
import ReactGoogleMap from "react-google-map"
import ReactGoogleMapLoader from "react-google-maps-loader"

import {Comunas, Regiones} from '../../../../Shared/partials/Address'
import { propertyService } from '../../../../../services/propertyService';

const Option = Select.Option;
const API_KEY = "AIzaSyDPWrtJQrSfU0xyhhDhiGhVOqX8d0vNBqo"



class PropertyForm extends Component {
    state = {
        type:'departamento',
        comuna:'Ñuñoa',
        region:'Región Metropolitana de Santiago',
        street_number:'',
        disabled_places:false,
        loading:false
      };

    async submit(){
        this.setState({loading:true})
        let state = this.state
        let {comuna, region, street_number, address, type} = state
        

        if(comuna && region && street_number && address && type){
            let data = {
                comuna : comuna, 
                region : region, 
                street_number : street_number, 
                address : address, 
                type : type,
                intercomstage: 2,
                pipedrivestage: 193,
                stagename: 'COMPLETAR_INFO_2'

            }

            let res = await propertyService.store(data)
            if(res){
                    localStorage.setItem('property_uid', res.data.uid)
                    this.props.history.push('/propietary/properties/'+res.data.uid + '/complete')  
            }
        }else{
            alert("ingrese toda la info");
        }


    }
    
    
    onChange = (e) => {
        this.setState({
          type: e.target.value,
        });
    }

    onNumberChange = (e) => {
        this.setState({
          street_number: e.target.value,
        });
    }


    onRegionChange = (e)=>{
        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region===e
        })
        this.setState({region:e, comuna:comunas[0].comunas})
    }
  
    onComunaChange = (e)=>{
        this.setState({comuna:e})
    }

    onMoneyTypeChange = (e)=>{
        this.setState({comuna:e})
    }

    render() {

        let comunas = Regiones.regiones.filter( (item)=>{
            return item.region.includes(this.state.region)
        })

        return <div>
            <h2 className="txtFondoBlanco subtituloInfo1">Detalle Propiedad</h2>
            <Card style={{border:'0px'}}>
            
                <Form layout='vertical'>
                    <Form.Item className="txtFondoBlanco" label="Dirección">
                        <PlaceSuggest updateAddress={(st)=> this.setState(st) } />
                    </Form.Item>

                    { this.state.ask_street_number ?
                    <Form.Item>
                        <div className="formitem">
                            <p className="txtFondoBlanco">Número <span style={{fontWeight:100}}></span></p>
                            <div>
                                <Input className="numDepto" onChange={(e)=>this.onNumberChange(e)} value={this.state.street_number} placeholder="1445" defaultValue="" />
                            </div>
                        </div>
                    </Form.Item>: null
                    }

                    <Form.Item className="txtFondoBlanco" label="Tipo de vivienda" >
                        <Radio.Group defaultValue="departamento" onChange={(e)=>this.onChange(e)} buttonStyle="solid">
                            <Radio.Button clasname="btnSecundario" value="departamento">Departamento</Radio.Button>
                            <Radio.Button clasname="btnSecundario" value="casa">Casa</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {this.state.type==='departamento'?
                        <Form.Item label="N° departamento">
                            <Input className="numDepto" placeholder="32b" />
                        </Form.Item> : null
                    }

                    <br/>

                    { this.state.ask_region ?
                    <Form.Item label="Región">
                        <Select 
                            dropdownClassName= 'dropSelectRegion'                        
                            disabled={this.state.disabled_places}
                            value={this.state.region}
                            onChange={this.onRegionChange}>
                            {Regiones.regiones.map(city => <Option  key={city.region}>{city.region}</Option>)}
                        </Select>
                    </Form.Item> : null }

                    { this.state.ask_comuna ?
                    <Form.Item label="Comuna">
                        <Select
                            dropdownClassName= 'dropSelectComuna'   
                            disabled={this.state.disabled_places}
                            value={this.state.comuna}
                            onChange={this.onComunaChange}>
                            {comunas[0].comunas.map(city => <Option key={city}>{city}</Option>)}
                        </Select>
                    </Form.Item> : null}


                    <br/><br/>

                    {this.state.lat && this.state.lng ? 

                    <ReactGoogleMapLoader
                    height="100px"
                                style={{height:'100px'}}
                    params={{
                        key: API_KEY, // Define your api key here
                        libraries: "places,geometry", // To request multiple libraries, separate them with a comma
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <div className="mapContainer">
                                <ReactGoogleMap
                                    googleMaps={googleMaps}
                                    coordinates={[{position:{lat: this.state.lat, lng: this.state.lng}} ]}
                                    center={{lat: this.state.lat, lng: this.state.lng}}
                                    zoom={16}
                                    />
                            </div>
                        )}
                    />
                     : null }

                </Form>
            </Card>
						<div className="btnContainer">
								<Button type="primary" className="btnPrincipal" onClick={()=>this.submit()} loading={this.state.loading}>Continuar</Button>
						</div>


        </div>
    }
}

export default PropertyForm;
