import React, { Component } from "react";
import TopNavbar from "./TopNavbar/TopNavbar";
import Footer from './Footer/Footer';

class MainLayout extends Component {
  
  render(){
    return (
    <div>
      <TopNavbar/>
      {this.props.children}
      <Footer />
    </div>)
  }

  ;
}

export default MainLayout;
