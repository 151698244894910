import {  PYTHON_URL  } from '../env'
import axios from 'axios'
import { getToken } from './helper';

class UsersService
{



    me(){
        let headers = { 'Content-Type': 'application/x-www-form-urlencoded', "Authorization": "Bearer " + getToken() }
        let http = axios.create({ baseURL: PYTHON_URL,  headers: headers  })

        return http.get( '/propietary/me/' ).catch( error => { console.log(error); return false })
    }



}

export const userService = new UsersService();
