import React, { Component } from 'react';
import './main.css'

class Faq extends Component {
    constructor(props) {
        super(props);
        let tabselected = props.location.state ? props.location.state : "preguntas"
        console.log(tabselected,  props.location.state ) 
        this.state = {
            content: false,
            tabs: tabselected,
            onload: '',
            links: '',
        }
        this.ToggleContent =  this.ToggleContent.bind(this)
        this.ToggleContenedor = this.ToggleContenedor.bind(this)
    }
    async componentDidMount()
    {
        if(localStorage.getItem('hmac')){
    let email = localStorage.getItem('email')
    let user_type = localStorage.getItem('role')
    let hmac = localStorage.getItem('hmac')

    window.Intercom("update",{
        user_id : email,
        email : email,
        user_type : user_type,
        user_hash : hmac
    });
}else{
    window.Intercom("update");
}

        this.ToggleContenedor(this.state.tabs)
        window.scrollTo(0, 0)


    }
    
    async ToggleContenedor(e){
        await this.setState({
            tabs: e
        });
        console.log(this.state.tabs)
    }

    async ToggleContent(e){
        this.state.content === e ? 
        await this.setState({
            content: false
        }) : 
        await this.setState({
            content: e
        });
        console.log(this.state.content)
    }


    render() {
        return (
            <section className="faq-box" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="faqHeader">
                            <div className="col-lg-12 col-md-12 text-center">
                                <ul className="faqOptionsBox">
                                    <li className={this.state.tabs === 'preguntas' ? "faqOptions active" : " faqOptions"}  ><a onClick={() => this.ToggleContenedor("preguntas")}>Preguntas frecuentes</a></li>
                                    <li className={this.state.tabs === 'terminos' ? "faqOptions active" : " faqOptions"} ><a onClick={() => this.ToggleContenedor("terminos")}>Términos y condiciones</a></li>
                                    <li className={this.state.tabs === 'politica' ? "faqOptions active" : " faqOptions"}  ><a onClick={() => this.ToggleContenedor("politica")}>Pólitica de privacidad y protección de datos</a></li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className={this.state.tabs === "preguntas" ? "contenedor fadeInRight animated" : "contenedor-hidden"}>
                    <div className="container">
                        <div className="row">    
                            <div className="col-lg-10 col-md-12 col-lg-offset-1">
                                <h4 className="subtitle" style={{textAlign: 'center', textTransform: 'uppercase', paddingBottom: '22px'}}>General</h4>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('1')}>¿Qué es ArriendoAsegurado?</h4>
                                    <div className={this.state.content === '1' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            ArriendoAsegurado es un nuevo servicio que llegó a Chile, que evalúa los antecedentes de tu Arrendatario y posteriormente 
                                            se encarga de cobrar la renta de arrendamiento. Una vez contratado el servicio, el usuario paga en nuestra plataforma por medio de Servipag, 
                                            y nosotros nos encargamos de realizar las gestiones de recaudación y cobranza, recordando periódicamente cuándo se debe pagar la renta. 
                                            Al cumplirse 15 días de atraso en el pago de la renta, se activa el seguro de arriendo, y se da inicio a la cobranza judicial. 
                                            Dicho seguro, a partir de dos meses de vacancia, cubre hasta nueve meses impagos de renta, junto con los gastos legales 
                                            hasta lograr la restitución del inmueble. 
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("2") }>¿Quién puede acceder a ArriendoAsegurado?</h4>
                                    <div className={this.state.content === "2" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Cualquier empresa o persona mayor de 18 años, que esté interesada en arrendar, que cuente con una propiedad arrendada o por arrendar. 
                                            Entre el Propietario y Arrendatario no debe existir ningún tipo de vinculaciones societarias, familiares o de parentesco.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('3')}>¿Qué tipo de propiedades pueden contratar el servicio de ArriendoAsegurado?</h4>
                                    <div className={this.state.content === '3' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Por el momento ArriendoAsegurado solo ofrece el servicio para propiedades residenciales, pudiendo incluir bodegas y estacionamientos, 
                                            siempre y cuando se encuentren en el mismo contrato de arriendo. Estas propiedades pueden ubicarse en cualquier parte de Chile, con excepción de la Isla de Pascua, 
                                            el Archipiélago de Juan Fernández y la Antártica chilena. 
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("4") }>¿Cómo evalúan los antecedentes del Arrendatario?</h4>
                                    <div className={this.state.content === "4" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Utilizamos bases de datos para evaluar su historial y solicitamos antecedentes que permitan establecer su capacidad financiera 
                                            para cumplir con el pago de la renta de arrendamiento.
                                        </p>
                                    </div>   
                                </div>
                                {/*<div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('5')}>Qué antecedentes se requieren para contratar ArriendoAsegurado?</h4>
                                    <div className={this.state.content === '5' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            En un comienzo ArriendoAsegurado sólo ofrecerá el servicio por propiedades residenciales, pudiendo incluir bodegas y estacionamientos, 
                                            si es que se encuentran en el mismo contrato de arriendo. Estas propiedades deben encontrarse Chile, a excepción de la Isla de Pascua, 
                                            el Archipiélago de Juan Fernández y la Antártica chilena. 
                                        </p>
                                    </div>   
                                 </div>*/}
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("6") }>¿Qué antecedentes se requieren para contratar ArriendoAsegurado?</h4>
                                    <div className={this.state.content === "6" ? "faqContent-int content" : " content faqContentHide "}>
                                        <h5 className="subtitles-faq">Información de la Propiedad:</h5>
                                        <p >Dirección</p>

                                        <h5 className="subtitles-faq">Información del Propietario:</h5>
                                        <p >Nombre, RUT y correo electrónico.</p>

                                        <h5 className="subtitles-faq">Información del Arrendatario:</h5>
                                        <p >Nombre, RUT y correo electrónico.</p>

                                        <ul className="faq-list">
                                            Si es trabajador dependiente:

                                            <li>3 últimas liquidaciones de renta.</li>
                                            <li>Certificado de antigüedad laboral con vigencia menor o igual a 30 días.</li>
                                            {/* <li>Certificado de las 12 últimas cotizaciones de AFP.</li> */}
                                            <li>Acreditación de otros ingresos, de poseerlos (No obligatorio).</li>
                                        </ul>

                                        <ul className="faq-list">
                                            Si es trabajador independiente: 

                                            <li>2 últimas declaraciones de renta.</li>
                                        </ul>

                                        <ul className="faq-list">
                                            Extranjero:

                                            <li>Copia del carnet de identidad por ambos lados.</li>
                                            <li>Requisitos solicitados a persona dependiente o independiente.</li>
                                        </ul>

                                        <ul className="faq-list">
                                            Si es una persona jurídica:  

                                            <li>Escritura donde consten las facultades de administración de la sociedad (personería vigente a 30 días).</li>
                                            <li>3 últimas declaraciones de renta.</li>
                                            <li>Ficha jurídica ArriendoAsegurado.</li>
                                        </ul>

                                        <h5 className="subtitles-faq">Información del Contrato de Arriendo:</h5>
                                        <p >Monto arriendo mensual, fecha inicio contrato, día de pago, multa y tipo de multa.</p>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('7')}>¿Cómo se encargan de cobrar el arriendo?</h4>
                                    <div className={this.state.content === '7' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Contamos con un sistema de cobranza que recuerda al Arrendatario en distintas instancias cuándo debe pagar el arriendo. 
                                            El Arrendatario pagará en nuestra página a través de Servipag. 
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("8") }>¿Cuánto se demora el dinero en ser depositado en mi cuenta?</h4>
                                    <div className={this.state.content === "8" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Le informaremos mediante correo electrónico el instante en que el Arrendatario efectúe el pago. 
                                            Los fondos serán transferidos a la cuenta designada por el Propietario dentro de un plazo de 3 días hábiles.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('9')}>¿Qué pasa si el Arrendatario se atrasa en el pago?</h4>
                                    <div className={this.state.content === '9' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Aumentamos la intensidad de cobranza desde el primer día de atraso. Al día 15 de atraso se siniestra la póliza del seguro de arriendo, 
                                            dando inicio al proceso de cobranza judicial. El Arrendatario será demandado al día 30 de atraso. 
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("10") }>¿Qué debo hacer para activar el seguro de arriendo?</h4>
                                    <div className={this.state.content === "10" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Al día 15 de atraso LegalChile se pondrá en contacto con el Propietario, quien deberá entregar los siguientes 
                                            antecedentes dentro de un plazo de 10 días:
                                        </p>
                                        <ul className="faq-list" style={{listStyleType: 'disc'}}>
                                            <li>Formulario de declaración de siniestro firmado, incluyendo una declaración simple de cumplimiento de las obligaciones 
                                            por parte del Arrendatario. El formato de documento será enviado a su correo. </li>
                                            <li>Copia del contrato de arriendo, el cual debe estar firmado ante notario.</li>
                                            <li>Información sobre gastos comunes y servicios impagos.</li>
                                        </ul>
                                        <p>
                                            LegalChile coordinará la firma en Notaría de un poder amplio para que puede representar al Propietario en el juicio.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('11')}>¿Qué pasa si no entrego los documentos dentro del plazo de 10 días?</h4>
                                    <div className={this.state.content === '11' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            El seguro de arriendo no cubrirá el siniestro. 
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("12") }>¿Quién regula la actividad de ArriendoAsegurado?</h4>
                                    <div className={this.state.content === "12" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            ArriendoAsegurado.com se rige bajo las leyes y normas que involucran a los Auxiliares del Comercio de Seguro, 
                                            y tiene como ente regulador a la Comisión del Mercado Financiero (ex Superintendencia de Valores y Seguros).
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("13") }>¿Cómo se paga el servicio de ArriendoAsegurado?</h4>
                                    <div className={this.state.content === "13" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            En caso de contratarlo el Propietario:
                                            El primer mes se pagará en la misma página mediante Servipag y en adelante el cobro se descontará mensualmente del pago 
                                            de tu Arrendatario. Es decir, te depositaremos el arriendo menos el cobro de ArriendoAsegurado.
                                            <br/>
                                            En caso de contratarlo el Arrendatario:
                                            La suscripción se pagará al comienzo del servicio en un solo pago mediante Servipag.
                                        </p>
                                    </div>   
                                </div>



                                <h4 className="subtitle" style={{textAlign: 'center', textTransform: 'uppercase', padding: '22px 0'}}>Beneficios</h4>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('14')}>¿Cuáles son los beneficios para el Propietario?</h4>
                                    <div className={this.state.content === '14' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Evaluación Arrendatario: Evaluamos los antecedentes de tu Arrendatario para asegurarnos que tu propiedad esté en buenas manos. 
                                        </p>
                                        <p>
                                            Gestión de cobro: Realizamos una cobranza institucional amigable. En caso de problemas realizamos cobranza judicial.  
                                        </p>
                                        <p>
                                            Asesoría legal: Una vez activado, el seguro cubre todos los honorarios de abogados, gastos judiciales y notariales hasta que logremos recuperar tu propiedad. 
                                        </p>
                                        <p>
                                            Seguro de arriendo: Te cubre, a partir de dos meses de vacancia, hasta 9 meses de arriendos impagos mientras recuperamos tu propiedad.
                                        </p>
                                        <p>
                                            Plataforma online: Todo el proceso es en línea.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("15") }>¿Cuáles son los beneficios para el Arrendatario?</h4>
                                    <div className={this.state.content === "15" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            No todos cuentan con un aval que respalde la buena intención de arrendar; por eso al contratar nuestro servicio, 
                                            le garantizamos al propietario la confianza que necesita sobre sus rentas sin necesidad de la figura de un aval. 
                                        </p>
                                        <p>
                                            Certificado buen pagador: Pagando al día te entregaremos al final del contrato un certificado de buen pagador. 
                                        </p>
                                        <p>
                                            Acumula puntos pagando tu arriendo con tarjetas de crédito. 
                                        </p>
                                        <p>
                                            Plataforma online: Todo el proceso es en línea.
                                        </p>
                                    </div>   
                                </div>


                                <h4 className="subtitle" style={{textAlign: 'center', textTransform: 'uppercase', padding: '22px 0'}}>Seguro de arriendo</h4>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('16')}>Póliza de Arriendo</h4>
                                    <div className={this.state.content === '16' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            La póliza de seguro integral para contratos de arrendamiento se encuentra inscrita con el código POL420131814 en la Comisión para
                                            el Mercado Financiero (ex Superintendencia de Valores y Seguros). 
                                            La puedes encontrar en el siguiente link:
                                            <a style={{color: '#666'}} href="http://www.svs.cl/sitio/seil/pagina/rgpol/muestra_documento.php?ABH89548=37G70IE7IX10663ABPRX4ABCIV864AJ35MNS8IYMJ35MN64IBMJ35MNABPRX" target="_blank">
                                            (http://www.svs.cl/sitio/seil/pagina/rgpol/muestra_documento.php?ABH89548=37G70IE7IX10663ABPRX4ABCIV864AJ35MNS8IYMJ35MN64IBMJ35MNABPRX)
                                            </a>  
                                        </p>
                                        <br/>
                                        <p>
                                            Es de exclusiva responsabilidad del usuario la contratación y revisión de la póliza de arriendo, no asumiendo ArriendoAsegurado la ejecución de las coberturas de la misma.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("17") }>¿Qué es y cuánto dura el período de carencia?</h4>
                                    <div className={this.state.content === "17" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Es un periodo determinado donde el seguro no te cubre el siniestro de ocurrir este dentro de aquel plazo establecido. En este caso, tiene una duración de 2 meses 
                                            y sirve para evitar los fraudes.
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('18')}>¿Qué cubre el seguro de arrendamiento de ArriendoAsegurado?</h4>
                                    <div className={this.state.content === '18' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            Nuestro seguro cubre:
                                        </p>
                                        <p>
                                            Monto de arriendo que el Propietario deja de percibir con un máximo de 9 meses.
                                        </p>
                                        <p> 
                                            Gastos de cobranza legal, juicio por cobro y restitución del inmueble.  
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("19") }>¿Qué no cubre el seguro de arrendamiento?</h4>
                                    <div className={this.state.content === "19" ? "faqContent-int content" : " content faqContentHide "}>
                                        <ul className="faq-list" style={{listStyleType: 'initial'}}>
                                            <li>El pago de las rentas de arrendamiento adeudadas con anterioridad al término del período de Carencia 
                                                y las adeudadas con posterioridad a la muerte del Arrendatario.</li>
                                            <li>El incumplimiento de cualquier obligación del Arrendatario diferente de las de (i) pagar las rentas de arrendamiento y 
                                                (ii) restituir el inmueble al término del contrato por no pago de la renta de arrendamiento.</li>
                                            <li>Gestiones que deban tramitarse fuera del Área de Cobertura (Isla de Pascua, Archipiélago de Juan Fernández y Antártica chilena).</li>
                                            <li>Los gastos que se deriven de una acumulación de juicios, o de la tramitación de una demanda reconvencional.</li>
                                            <li>La tramitación del desahucio del Contrato de Arrendamiento como acción principal del juicio o cuando la acción de terminación 
                                                interpuesta en virtud de esta Póliza se enervare por el demandado mediante el pago de las rentas adeudadas.</li>
                                            <li>La tramitación de cualquier otra acción que no derive del incumplimiento del pago de las rentas de arrendamiento.</li>
                                            <li>La restitución del inmueble cuando exista subarrendatario.</li>
                                            <li>La atención de asuntos ocurridos antes de la entrada en vigencia de la Póliza, tales como juicios pendientes entre el Asegurado 
                                                y el Arrendatario.</li>
                                            <li>Gestiones destinadas a percibir un pago por consignación.</li>
                                            <li>La atención de asuntos regidos por una legislación distinta a la establecida en la Ley 18.101 y sus modificaciones.</li> 
                                        </ul>
                                    </div>   
                                </div>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('20')}>¿Cuánto tiempo después de que el Arrendatario deja de pagar, recibo el pago por parte de la compañía de seguro?</h4>
                                    <div className={this.state.content === '20' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            El primer pago se realizará dentro de un plazo de 10 días posterior al comparendo del juicio de arrendamiento, lo cual se estima suceda 2 meses después de demandar 
                                            al Arrendatario. Desde ese punto en adelante los arriendos impagos se pagarán mensualmente hasta lograr la restitución del inmueble o cumplido el plazo máximo de 9 meses.  
                                        </p>
                                    </div>   
                                </div>
                                <div className="faqContent">
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent("21") }>¿Qué pasa con los gastos comunes y servicios impagos?</h4>
                                    <div className={this.state.content === "21" ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            No son parte de la cobertura del seguro. 
                                        </p>
                                    </div>   
                                </div>
                                

                                <h4 className="subtitle" style={{textAlign: 'center', textTransform: 'uppercase', padding: '22px 0'}}>Otros</h4>
                                <div className="faqContent"  >
                                    <h4 style={{padding: '1em'}} onClick={() => this.ToggleContent('22')}>¿Ustedes pueden ayudar a conseguir Arrendatario?</h4>
                                    <div className={this.state.content === '22' ? "faqContent-int content" : " content faqContentHide "}>
                                        <p>
                                            No, nosotros trabajamos con las mejores corredoras de propiedades del país. 
                                            Pero nosotros sí te ayudamos a evaluar a tus postulantes a Arrendatario e informarte si son candidatos a ArriendoAsegurado.
                                        </p>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                



                <div className={this.state.tabs === "politica" ? "contenedor fadeInRight animated" : "contenedor-hidden"}>
                    <div className="container politica">
                        
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h3 className="main-title" style={{fontSize: '38px'}}> Política de privacidad y protección de datos de ArriendoAsegurado</h3>
                                <p>
                                    <strong style={{textTransform: 'initial'}}>Insurtech SpA</strong>, Rol Único Tributario Número
                                    76.874.069-0, en adelante, "ArriendoAsegurado", conforme a la legislación vigente en
                                    materia de Protección de Datos de Carácter Personal, informa a los usuarios de su
                                    plataforma online, acerca de la Política de Privacidad y Protección de Datos que aplicará
                                    en el tratamiento de los datos personales que el usuario facilite voluntariamente al
                                    acceder al Sitio www.arriendoasegurado.com, en adelante el "Sitio Web".
                                </p>
                                <p>
                                    El usuario, al proporcionar a ArriendoAsegurado o a cualquiera de sus filiales, sus datos
                                    de carácter personal a través de los formularios electrónicos del Sitio Web, consiente
                                    expresamente que la misma pueda tratar esos datos en los términos de esta e Política de
                                    Privacidad y Protección de Datos y para los fines aquí expresados, en adelante, la "Política
                                    de Privacidad".
                                </p>
                                <p>
                                    Antes de registrase en el Sitio Web de ArriendoAsegurado, los usuarios deben leer la
                                    presente Política de Privacidad. Al marcar el botón "Registrarse", los usuarios afirman que
                                    han leído y que consienten expresamente la presente Política de Privacidad y Protección
                                    de Datos.
                                </p>
                                <p>
                                    Al registrarse, los usuarios deberán proporcionar algunos datos y adjuntar ciertos
                                    antecedentes para la creación de su cuenta y la edición de su perfil, en adelante, el 
                                    "Formulario de Registro". Éstos deberán proporcionar, a lo menos, los siguientes datos:
                                    Nombre de usuario, nombre completo, correo electrónico, número de teléfono, número de
                                    cédula de identidad, domicilio y tarjeta de crédito. Adicionalmente, los documentos a
                                    adjuntar serán, a lo menos, los siguientes: (i) Información de su actual contrato de
                                    arriendo, la que consiste en: Singularización y datos de contactos de las partes del
                                    contrato, canon de arriendo, dirección de inmueble, si incluye aval o no, fecha de inicio del
                                    contrato, duración del contrato, día de pago de la renta, si existen multas asociadas y
                                    descripción de las mismas, entre otros; (ii) documentación vigente y verídica de acuerdo a
                                    su ocupación y nacionalidad; (iii) certificado de antigüedad laboral; (iv) liquidaciones de renta;
                                    (v) certificado de cotizaciones de AFP; (vi) copia de la cédula de identidad por ambos lados;
                                    (vii) balances generales al 31 de diciembre del año anterior; (viii) personería vigente, 
                                    en caso que el usuario sea persona jurídica, y(ix) ficha jurídica de ArriendoAsegurado.
                                </p>
                                <p>
                                    Sin perjuicio de lo anterior, ArriendoAsegurado se reserva el derecho de solicitar datos o
                                    antecedentes adicionales a los antes singularizados.
                                </p>
                                <p>
                                    Asimismo, ArriendoAsegurado, siempre y cuando los usuarios lo autoricen, recogerá
                                    datos relacionados con su localización, incluyendo la localización geográfica en tiempo real
                                    del ordenador o dispositivo móvil de los usuarios.
                                </p>
                                <p>
                                    Una vez completado el Formulario de Registro, todo usuario podrá acceder a su perfil y
                                    podrá completarlo y/o editarlo según estime conveniente. El usuario también podrá
                                    acceder y crear una cuenta a través de su perfil de diversas redes sociales, las cuales
                                    serán oportunamente informadas. La información y datos facilitados por el usuario estarán
                                    en todo momento disponibles en su cuenta de usuario y podrán ser modificados por el
                                    usuario a través de la opción "Editar Perfil".
                                </p>
                                <p>
                                    El usuario se compromete a introducir datos reales y veraces. Asimismo, será el único
                                    responsable de los daños y perjuicios que ArriendoAsegurado o terceros pudieran sufrir
                                    como consecuencia de la falta de veracidad, inexactitud, falta de vigencia y autenticidad
                                    de los datos facilitados.
                                </p>

                                <p>
                                    Los datos recabados por ArriendoAsegurado, serán exclusivamente utilizados para la
                                    consecución del objeto definido en los Términos y Condiciones del Sitio Web.
                                </p>
                                <ul style={{marginLeft: '122px'}}>
                                    <li>
                                        Legislación aplicable a los datos personales de los usuarios (Protección de Datos de carácter Personal).
                                    </li>
                                    <li>
                                        ¿Quién es el responsable de los datos que usted nos facilita?
                                    </li>
                                    <li>
                                        ¿Qué información recopila ArriendoAsegurado?
                                    </li>
                                    <li>
                                        ¿Con qué finalidad se recopilan los datos?
                                    </li>
                                    <li>
                                        ¿Comparte ArriendoAsegurado la información que recopila?
                                    </li>
                                    <li>
                                        ¿Qué derechos tienen los usuarios?
                                    </li>
                                    <li>
                                        ¿Cómo protegemos los datos de los usuarios de ArriendoAsegurado?
                                    </li>
                                    <li>
                                        Notificaciones y modificaciones.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">
                                    Legislación aplicable a los datos personales de los usuarios (Protección de Datos de carácter Personal).
                                </h4>
                                <p>
                                    ArriendoAsegurado manifiesta su compromiso de cumplir con la legislación vigente en
                                    cada momento en materia de protección de datos, principalmente pero no limitado a las
                                    disposiciones de la Ley 19.628 Sobre Protección de la Vida Privada. Se informa a los
                                    usuarios que los datos de carácter personal que sean facilitados en el Sitio Web serán
                                    objeto de tratamiento automatizado y pasarán a formar parte de los ficheros titularidad de
                                    ArriendoAsegurado, siendo el responsable del tratamiento, para las finalidades propias de
                                    gestión de usuarios registrados en el Sitio Web de ArriendoAsegurado.
                                </p>
                                <div className="table-responsive">
                                    <table style={{width: '100%', marginTop: '42px', marginBottom: '42px'}}>
                                        <thead>
                                            <tr>
                                                <th style={{borderRight: 'transparent'}}></th>
                                                <th style={{width: '100%', textAlign: 'center', borderLeft: 'transparent', position: 'relative', right: '50px'}}>Información básica sobre Protección de Datos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Razón Social</td>
                                                <td>Insurtech SpA</td>
                                            </tr>
                                            <tr>
                                                <td>Objeto</td>
                                                <td>
                                                    Servicio recaudación y cobranza de rentas de arriendo, contratación de un seguro asociado al pago 
                                                    de la misma, incluyendo la cobertura de los gastos legales que correspondan en caso de incumplimiento 
                                                    en el pago de dicha renta.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Legitimación</td>
                                                <td>Cumplimiento de la relación contractual, interés legítimo y consentimiento del usuario.</td>
                                            </tr>
                                            <tr>
                                                <td>Derechos</td>
                                                <td>Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional.</td>
                                            </tr>
                                            <tr>
                                                <td>Información Adicional</td>
                                                <td>Puede consultar la información adicional y detallada sobre Protección de datos en los apartados siguientes.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>
                                    De acuerdo con la legislación aplicable, a continuación, se detalla la Política de Privacidad
                                    que ArriendoAsegurado utiliza en el tratamiento de los datos de los usuarios que se
                                    registran en el Sitio Web.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Quién es el responsable de los Datos que usted nos facilita?</h4>
                                <p>
                                    Toda la información personal que facilite o se recoja a través del Sitio Web, será tratada
                                    por ArriendoAsegurado, como responsable del tratamiento.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Qué información recopila ArriendoAsegurado o cualquier de sus filiales?</h4>
                                <p>
                                    El Sitio Web de ArriendoAsegurado se utiliza tanto por los usuarios, como por nuestros
                                    proveedores. La información que recibimos por parte de los usuarios se recopila como
                                    exponemos a continuación.   
                                </p>
                                <p>
                                    ArriendoAsegurado recopila la información del usuario que éste aporta directamente y
                                    otra que es aportada de forma indirecta mediante el uso del Sitio Web.
                                </p>
                                <p style={{ fontWeight: 'bold' }}> 
                                    a) Información los usuarios nos facilitan directamente:
                                </p>
                                <ul style={{marginTop: '25px', marginBottom: '15px'}}>
                                    <li style={{marginTop: '15px'}}>
                                        Datos de Registro: La información que el usuario nos facilita
                                        cuando se crea una cuenta en el Sitio Web, correspondiente a toda
                                        aquella singularizada precedentemente en esta Política de Privacidad
                                        correspondiente a la que se solicita al comento de completar el
                                        Formulario de Registro.
                                    </li>
                                    <li>
                                        Información del Perfil del usuario: La información que el usuario añade en el Sitio Web a efectos 
                                        de poder utilizar el servicio de ArriendoAsegurado, esto es, el teléfono móvil y el domicilio del
                                        usuario. El usuario puede ver y editar los datos personales de su perfil cuando
                                        lo estime oportuno. ArriendoAsegurado no almacena los datos de la tarjeta de
                                        crédito y/o débito del usuario, pero éstos se facilitan a prestadores de servicios
                                        de pago electrónico con licencia, los cuales recibirán directamente los datos
                                        incluidos y los almacenarán para facilitar el proceso de pago al usuario y
                                        gestionarán el mismo en nombre de ArriendoAsegurado. En ningún caso
                                        ArriendoAsegurado almacena en sus servidores estos datos. El usuario podrá,
                                        en cualquier momento, eliminar los datos de sus tarjetas vinculados a su
                                        cuenta. Con esa acción, el proveedor de servicios de pago electrónico
                                        procederá al borrado de los datos y será necesario volver a introducirlos o
                                        seleccionarlos para poder utilizar el Sitio Web nuevamente.
                                    </li>
                                    <li>
                                        Información adicional que el usuario quiere compartir: La información que podría
                                        facilitar el usuario a ArriendoAsegurado con otros fines. Por ejemplo, una
                                        fotografía suya o la dirección de facturación, en caso de solicitar recibir facturas
                                        de ArriendoAsegurado.
                                    </li>
                                    <li>
                                        Información acerca de las comunicaciones realizadas con ArriendoAsegurado:
                                        ArriendoAsegurado tendrá acceso a información que los usuarios faciliten para
                                        la resolución de dudas o quejas sobre el uso del Sitio Web.
                                    </li>
                                </ul>
                                <p>
                                    El tratamiento de estos datos por parte de ArriendoAsegurado es necesario para
                                    poder cumplir con la relación contractual que se establece. Si el usuario no los
                                    facilita, los servicios solicitados podrán no estar disponibles y ArriendoAsegurado
                                    no podrá prestarlos
                                </p>
                                <p style={{ fontWeight: 'bold' }}> 
                                    b) Información que los usuarios nos facilitan indirectamente: 
                                </p>
                                <ul>
                                    <li style={{marginTop: '15px'}}>
                                        Datos derivados del uso del Sitio Web: ArriendoAsegurado recolecta los datos
                                        derivados del uso del Sitio Web por parte del usuario cada vez que éste
                                        interactúa en éste.
                                    </li>
                                    <li>
                                        Datos del ordenador: ArriendoAsegurado almacena los datos del ordenador
                                        que el usuario utiliza para acceder al Sitio Web. Estos son:
                                        <ul style={{listStyleType: 'circle', marginTop: '15px', marginBottom: '15px'}}>
                                            <li>
                                                Dirección IP de Internet que utiliza el usuario para conectarse a Internet
                                                con su ordenador.
                                            </li>
                                            <li>
                                                Información de su ordenador, como su conexión a Internet, su tipo de
                                                navegador, la versión y el sistema operativo, y el tipo de ordenador.
                                            </li>
                                            <li>
                                                El clickstream completo de localizadores de recursos Uniformes(URL), incluyendo la fecha y la hora.
                                            </li>
                                            <li>
                                                Datos de la cuenta del usuario: La información de los servicios contratados
                                                por el usuario, así como las valoraciones y/o comentarios que realice acerca
                                                de los mismos.
                                            </li>
                                            <li>
                                                El historial de navegación y las preferencias del usuario.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Datos derivados del origen del usuario: Si el usuario llega al Sitio Web de ArriendoAsegurado a través 
                                        de una fuente externa(como sería por ejemplo un enlace de otra página web o de una red social), ArriendoAsegurado
                                        recopila los datos de la fuente de la que procede el usuario de ArriendoAsegurado.
                                    </li>
                                    <li>
                                        Datos derivados de la gestión de incidencias: Si el usuario se dirige al Sitio
                                        Web de ArriendoAsegurado a través del Formulario de Contacto o a través del
                                        teléfono de ArriendoAsegurado, ArriendoAsegurado recopilará los mensajes
                                        recibidos en el formato utilizado por el usuario y los podrá utilizar y almacenar
                                        para gestionar incidencias presentes o futuras.
                                    </li>
                                    <li>
                                        Datos derivados de las "cookies": ArriendoAsegurado utiliza cookies propias y
                                        de terceros para facilitar la navegación a sus usuarios y con fines estadísticos.
                                    </li>
                                    <li>
                                        Datos derivados de terceros externos: ArriendoAsegurado podría recopilar
                                        información o datos de carácter personal de terceros externos únicamente si el
                                        usuario autoriza a esos terceros a compartir la información citada con ArriendoAsegurado.
                                        Por ejemplo, en caso de que el usuario se cree una cuenta a
                                        través de su Facebook, Facebook podría cedernos datos de carácter personal
                                        de dicho usuario en caso de que éste los tuviera en su perfil de Facebook
                                        (nombre, género, edad, etc.). Del mismo modo, si el usuario accede a
                                        ArriendoAsegurado a través de los productos y servicios ofrecidos por Google,
                                        éste podrá enviar datos de navegación del usuario a ArriendoAsegurado, con
                                        el acceso al Sitio Web a través de los enlaces creados por Google. La
                                        información facilitada por el tercero externo podrá ser controlada por el
                                        usuario según la propia política de privacidad del tercero externo.
                                    </li>
                                    <li>
                                        Datos sobre la Geolocalización: Siempre y cuando los usuarios lo autoricen,
                                        ArriendoAsegurado recogerá datos relacionados con su localización,
                                        incluyendo la localización geográfica en tiempo real del ordenador de los
                                        usuarios.
                                    </li>
                                </ul>
                                <p>
                                Estos datos son tratados para dar cumplimiento a la relación contractual que se
                                establece con el usuario, así como por los intereses legítimos que ArriendoAsegurado
                                tiene para su tratamiento.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Con qué finalidad se recopilan los datos?</h4>
                                <ol>
                                    <li>
                                        ArriendoAsegurado utiliza los datos que recopila de los usuarios para que puedan
                                        acceder y comunicarse con el Sitio Web y para prestar los servicios que soliciten a través
                                        de su cuenta en el Sitio Web según el mecanismo descrito en los Términos y Condiciones.
                                        También se utilizarán para mostrar al usuario el conjunto de operaciones realizadas a
                                        través del Sitio Web y para que puedan dar su opinión sobre los servicios prestados por
                                        ArriendoAsegurado.
                                    </li>
                                    <li>
                                        ArriendoAsegurado también utiliza la información para investigar y analizar cómo
                                        mejorar los servicios que presta a los usuarios, así como para desarrollar y mejorar las
                                        características del servicio que ofrece. En particular, ArriendoAsegurado podrá utilizar los
                                        datos a efectos de velar por el buen y correcto uso de los servicios prestados a través de
                                        su Sitio Web.
                                    </li>
                                    <li>
                                        ArriendoAsegurado también puede utilizar la información de los usuarios con el
                                        propósito de detectar e investigar fraudes, así como otras actividades ilegales y
                                        quebrantamientos potenciales de nuestros Términos y Condiciones.
                                    </li>
                                    <li>
                                        Internamente, ArriendoAsegurado utiliza la información con fines estadísticos a
                                        efectos de analizar el comportamiento y las tendencias de los usuarios, de comprender
                                        cómo los usuarios utilizan el Sitio Web de ArriendoAsegurado, y de gestionar y mejorar
                                        los servicios ofrecidos, incluyendo la posibilidad de añadir nuevos distintivos al Sitio Web.
                                        Asimismo, ArriendoAsegurado podrá utilizar los datos facilitados a los efectos de
                                        gestionar las incidencias que pudieran producirse en la prestación de servicios.
                                    </li>
                                    <li>
                                        Asimismo, ArriendoAsegurado podrá utilizar los datos personales que el usuario le
                                        facilita para realizar comunicaciones vía correo electrónico, SMS, whatsapp u otros
                                        similares al usuario acerca de la operativa del servicio. ArriendoAsegurado enviará
                                        mensajes al correo electrónico del usuario con información, publicidad, mensajes
                                        promocionales y ofertas relativas al servicio. Al registrarse en el Sitio Web se entenderá
                                        que el usuario acepta la presente Política de Privacidad y que, por lo tanto, autoriza a
                                        ArriendoAsegurado para realizar ambas comunicaciones.
                                    </li>
                                    <li>
                                        Sin perjuicio de lo anterior, si el usuario de ArriendoAsegurado desea no recibir la
                                        citada información y/o comunicaciones comerciales, los usuarios podrán optar en
                                        cualquier momento a la opción de "Cancelar la suscripción" en el propio correo
                                        electrónico, y consecuentemente, ArriendoAsegurado cesará inmediatamente en el envío
                                        de la citada información.
                                    </li>
                                    <li>
                                        Asimismo, ArriendoAsegurado podrá utilizar la dirección registrada por el usuario a
                                        efectos de realizar actividades promocionales para la entrega de muestras, promociones o
                                        productos gratuitos de ArriendoAsegurado.
                                    </li>
                                    <li>
                                        Como se ha descrito anteriormente, si el usuario conecta su cuenta de ArriendoAsegurado 
                                        a otra red social o a la plataforma de un tercero, ArriendoAsegurado podría
                                        utilizar la información cedida a esa red social o tercero, siempre que dicha información se
                                        haya puesto a disposición de ArriendoAsegurado cumpliendo con la política de privacidad
                                        de dicha red social o plataforma de tercero.
                                    </li>
                                    <li>
                                        Si la propiedad de ArriendoAsegurado cambiara o la mayor parte de sus activos
                                        fueran adquiridos por un tercero, los usuarios son informados de que ArriendoAsegurado
                                        transferirá sus datos a las entidades del adquirente con la finalidad de seguir prestando
                                        los servicios objeto del tratamiento de datos. El nuevo responsable comunicará a los
                                        usuarios sus datos de identificación. ArriendoAsegurado comunicará a los usuarios el
                                        cambio de responsable en el momento que se produzca.
                                    </li>
                                    <li>
                                        ArriendoAsegurado no utiliza la información de los usuarios para ningún otro fin que
                                        los descritos en la presente Política de Privacidad.
                                    </li>
                                </ol>
                                <p>
                                Los tratamientos definidos en este punto son realizados por ArriendoAsegurado o por
                                terceros en nombre de ArriendoAsegurado, según se describe en el siguiente punto,
                                dado el interés legítimo de ArriendoAsegurado en mejorar los servicios ofrecidos a los
                                usuarios, así como para mejorar su experiencia de uso del Sitio Web y para cumplir con la
                                relación contractual que se establece entre el usuario y ArriendoAsegurado.
                                </p>
                            </div>
                        </div>
                    

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Comparte ArriendoAsegurado la información que recopila?</h4>
                                <p>
                                    Como se ha indicado en el apartado anterior, los datos de carácter personal que ArriendoAsegurado 
                                    recopile serán exclusivamente utilizados para la consecución del objeto definido
                                    en los Términos y Condiciones.
                                </p>
                                <p>
                                    Para el correcto desarrollo de la relación contractual y la excelencia en la prestación del
                                    servicio, así como por el interés legítimo de ArriendoAsegurado, compartirá ciertos datos
                                    de carácter personal de los usuarios con:
                                </p>
                                <ul>
                                    <li>
                                        Proveedores de servicios: Los terceros proveedores de servicios de ArriendoAsegurado 
                                        tendrán acceso a la información personal de los usuarios necesaria para
                                        realizar sus funciones, pero no podrán utilizarla para otros fines. Éstos deberán
                                        tratar la información personal de conformidad con la presente Política de Privacidad
                                        y la legislación aplicable en la materia de protección de datos.
                                    </li>
                                    <li>
                                        Proveedores con los que ArriendoAsegurado mantiene un acuerdo comercial para el
                                        desarrollo de su objeto: A través del Sitio Web, se puede utilizar ArriendoAsegurado
                                        para facilitar la recaudación y cobranza de rentas, la contratación de pólizas de
                                        seguros, el arriendo de inmuebles, entre otros. En dichos casos, ArriendoAsegurado
                                        podrá entregar información del usuario con el único propósito de facilitar la
                                        operación respectiva. En el supuesto que el usuario contacte directamente con los
                                        proveedores descritos y facilite sus datos directamente a éstos, ArriendoAsegurado
                                        no será responsable por el uso que los proveedores hagan.
                                    </li>
                                    <li>
                                        El usuario podrá recibir también correos electrónicos de ArriendoAsegurado para
                                        confirmar datos, operaciones y detalles de las mismas, solicitar la valoración del
                                        usuario sobre el servicio prestado, entre otros.
                                    </li>
                                    <li>
                                        ArriendoAsegurado garantiza que todos los socios comerciales, técnicos,
                                        proveedores, o terceros independientes están contractualmente obligados para
                                        procesar información que se comparte con ellos siguiendo las indicaciones que
                                        ArriendoAsegurado le da, la presente Política de Privacidad, así como la legislación
                                        aplicable en materia de protección de datos.
                                    </li>
                                    <li>
                                        Tribunales de Justicia, Policía de Investigaciones de Chile y Carabineros de Chile:
                                        ArriendoAsegurado podrá divulgar información personal y datos de cuentas de los
                                        usuarios cuando crea que su divulgación es necesaria para el cumplimiento de la ley
                                        aplicable, para hacer cumplir o aplicar los Términos y Condiciones, o para proteger
                                        los derechos, la propiedad o seguridad de ArriendoAsegurado, sus usuarios o
                                        terceros. Lo anterior incluye, por lo tanto, el intercambio de información con los
                                        Tribunales Ordinarios de Justicia, Policía de Investigaciones de Chile y Carabineros
                                        de Chile, para protección contra el fraude u otros. El usuario declara conocer que
                                        ArriendoAsegurado estará obligado a entregar todos los antecedentes que tenga en
                                        su poder cuando así lo ordene un Tribunal de la República de Chile.
                                    </li>
                                    <li>
                                        Pasarela de pago: ArriendoAsegurado no almacena los datos de las tarjetas de
                                        crédito de los usuarios. No obstante, los datos identificativos de la tarjeta de
                                        crédito/débito (número y fecha de caducidad) se almacenan por el proveedor de
                                        servicios de pago escogido por ArriendoAsegurado, cuyas medidas de seguridad son
                                        elevadas. Si el usuario solicita borrar los datos correspondientes a los medios de
                                        pago que introdujo en su perfil, el tercero proveedor de servicios de pago borrará
                                        dichos datos de sus servidores.
                                    </li>
                                    <li>
                                        Prestadores de servicios para el control del fraude: ArriendoAsegurado compartirá
                                        los datos de los usuarios con proveedores que ofrecen servicios de control del
                                        fraude, para estudiar el riesgo de las operaciones que se llevan a cabo.
                                    </li>
                                    <li>
                                        Prestadores de servicio para la anonimización de algunos datos: Para evitar el uso
                                        indebido de los datos de los usuarios por parte de terceros prestadores de servicios,
                                        ArriendoAsegurado puede ceder los datos de los usuarios a fin de que estos puedan
                                        ser anonimizados y utilizados únicamente para la prestación del servicio a los usuarios.
                                    </li>
                                    <li>
                                        Los datos de los usuarios de ArriendoAsegurado se almacenan en los servidores
                                        contratados por ArriendoAsegurado. ArriendoAsegurado manifiesta que dichos
                                        servidores cumplen con la legislación aplicable en materia de Protección de Datos y
                                        con los compromisos expuestos en la Presente Política de Privacidad.
                                    </li>
                                    <li>
                                        Los datos de los usuarios de ArriendoAsegurado no se transmitirán a ningún tercero
                                        a excepción de que(i) sea necesario para los servicios solicitados, en caso de que ArriendoAsegurado 
                                        colabore con terceros; (ii) cuando ArriendoAsegurado tenga autorización expresa e inequívoca 
                                        por parte del usuario; (iii) cuando se solicite por parte de autoridad competente en el ejercicio 
                                        de sus funciones(para investigar, prevenir o tomar acciones relativas a acciones ilegales);
                                        o(iv) por último, si lo requiere la ley aplicable o un Tribunal de justicia de la República de Chile.
                                    </li>
                                    <li>
                                        Con el consentimiento del usuario también: A terceros no socios comerciales, sin
                                        vínculo con ArriendoAsegurado, siempre y cuando exista una autorización previa e
                                        inequívoca por parte del usuario.
                                    </li>
                                </ul>
                                <p>
                                    Ninguna de las comunicaciones de datos anterior incluirá vender, alquilar, compartir o
                                    revelar de otro modo información personal de usuarios con fines comerciales de modo
                                    contrario a los compromisos adquiridos en la presente Política de Privacidad.
                                </p>
                                <p>
                                    ArriendoAsegurado comparte la información de los usuarios según lo descrito para dar
                                    cumplimiento a la normativa aplicable, para ayudar a ArriendoAsegurado a mejorar la
                                    prestación de sus servicios, para dar cumplimiento a la relación contractual con el usuario,
                                    así como por el interés legítimo de ArriendoAsegurado.
                                </p>
                            </div>
                        </div>
                    


                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Qué derechos tienen los usuarios?</h4>
                                <ul style={{listStyleType: 'none'}}>
                                    <h4 className="subtitle" style={{fontSize: '18px'}}>¿Por cuánto tiempo conservaremos sus datos?</h4>
                                    <li>
                                        Los datos personales proporcionados se conservarán mientras no se solicite su
                                        supresión por el interesado durante un plazo de 24 meses desde la última interacción.
                                        En caso de retirar el consentimiento u oponerse al tratamiento, los datos se
                                        bloquearán y dejarán de tratarse, y se conservarán por un plazo de 4 años a efectos
                                        de poder reclamar o defendernos de posibles reclamaciones.
                                    </li>
                                    <h4 className="subtitle" style={{fontSize: '18px'}}>Derecho a retirar su consentimiento.</h4>
                                    <li>
                                        <p>En cualquier momento el usuario podrá retirar su consentimiento para el tratamiento
                                        de datos por parte de ArriendoAsegurado.</p>

                                        <p>Para su retirada bastará con que el usuario rellene y envíe el formulario de contacto
                                        disponible en contacto @arriendoasegurado.cl. La retirada del consentimiento podrá
                                        realizarse en cualquier momento por parte del interesado. Los usuarios aceptan y
                                        consienten que la retirada del consentimiento implicará que ArriendoAsegurado no
                                        pueda prestar los servicios al usuario.</p>
                                    </li>
                                    <h4 className="subtitle" style={{fontSize: '18px'}}>¿A qué información pueden acceder los usuarios?</h4>
                                    <li>
                                        <p>ArriendoAsegurado da al usuario acceso a una gran cantidad de información sobre su
                                        cuenta y sus operaciones en su perfil de usuario para que pueda ver, y en ciertos
                                        casos, actualizar dicha información.</p>

                                        <p>Todo usuario podrá acceder a su perfil y completarlo y/o editarlo según estime
                                        conveniente. Como se ha indicado anteriormente, el usuario podrá acceder y crear
                                        una cuenta a través de su perfil de diversas redes sociales. La información y datos
                                        facilitados por el usuario estarán en todo momento disponibles en su cuenta de usuario.</p>
                                    </li>
                                    <h4 className="subtitle" style={{fontSize: '18px'}}>Derechos de acceso, rectificación, supresión, limitación del tratamiento, 
                                        oposición y derecho a la portabilidad de los datos</h4>
                                    <li>
                                        <p>El derecho de acceso se concibe como el derecho que tiene el usuario a solicitar a
                                        ArriendoAsegurado si trata algún dato suyo. El usuario podrá solicitar a ArriendoAsegurado 
                                        la rectificación de los datos presentes en su base de datos si éstos fueran incompletos.</p>

                                        <p>El derecho de supresión faculta al usuario a solicitar a ArriendoAsegurado la
                                        supresión de sus datos personales. Si el usuario lo solicitara, ArriendoAsegurado
                                        limitará el uso de sus datos.</p>

                                        <p>El usuario también tendrá el derecho de oponerse al trato de los datos personales que
                                        tenga ArriendoAsegurado en su poder. El ejercicio de estos derechos es de carácter
                                        personalísimo por lo que será necesario que el usuario acredite su identidad. El
                                        ejercicio de estos derechos deberá realizarse por escrito, firmado por el titular de los
                                        datos, con indicación de su domicilio, adjuntando copia de su cédula nacional de
                                        identidad u otro documento que acredite su identidad, dirigiéndose a ArriendoAsegurado 
                                        a través de su formulario de contacto.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">¿Cómo protegemos los datos de los usuarios?</h4>
                                <p>
                                    ArriendoAsegurado ha adoptado las medidas necesarias para mantener el nivel de
                                    seguridad requerido, según la naturaleza de los datos personales tratados y las
                                    circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible y
                                    siempre según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no
                                    autorizado. Como se ha mencionado anteriormente, los datos personales facilitados no
                                    serán cedidos a terceros sin autorización previa por parte del titular de los mismos.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
                                <h4 className="subtitle">Notificaciones y modificaciones</h4>
                                <p>
                                    Como se ha indicado anteriormente, todos los usuarios tienen derechos a acceder,
                                    actualizar y cancelar sus datos, así como oponerse a su tratamiento. Puede ejercer estos
                                    derechos o realizar cualquier consulta en relación con la Política de Privacidad de ArriendoAsegurado 
                                    a través del formulario de contacto.
                                </p>
                                <p>
                                    A causa de la continua evolución de las actividades de ArriendoAsegurado, la presente
                                    Política de Privacidad podrán igualmente modificarse. ArriendoAsegurado enviará al
                                    usuario los avisos sobre los cambios y modificaciones sustanciales de esta Política de
                                    Privacidad a través del correo electrónico, o de otro medio que asegure la recepción de los
                                    mismos. De todas formas, ArriendoAsegurado en ningún caso modificará las políticas ni
                                    prácticas para hacerlas menos eficaces en la protección de los datos personales de
                                    nuestros suarios almacenados anteriormente.
                                </p>
                            </div>
                        </div>       
                    </div>
                </div>

                <div className={this.state.tabs === "terminos" ? "contenedor fadeInRight animated" : "contenedor-hidden"}>
                    <div className="container politica">
                        <div className="row">    
                            <div className="col-lg-10 col-md-12 col-lg-offset-1">
                                <h3 className="main-title" style={{fontSize: '38px'}}> Términos y condiciones <br/>ArriendoAsegurado </h3>
                                <h4 className="subtitle">1.- Objeto</h4>
                                <p>
                                    Los presentes Términos y Condiciones Generales de Uso (en adelante, los "Términos y Condiciones") regulan el uso de del sitio web www.arriendoasegurado.com (en adelante, el "Sitio Web"), ya sea con su actual nombre o con cualquier otra denominación con la que pueda figurar en el futuro. El Sitio Web es propiedad de Insurtech SpA, rol único tributario número 76.874.069-0, con domicilio en Av. del Parque N° 5275, Of. 504, comuna de Huechuraba, ciudad de Santiago, Región Metropolitana, y dirección de correo electrónico contacto@arriendoasegurado.cl (en adelante “Arriendo Asegurado” o "AA").
                                </p>
                                <p>
                                    Al acceder y utilizar el Sitio Web, usted ("Usuario") reconoce que ha leído y aceptado estos Términos y Condiciones y se compromete a cumplir con todo lo aquí estipulado. Asimismo, el Usuario reconoce y acepta que el acceso y uso del Sitio Web estará sujeto a los Términos y Condiciones que se encuentren vigentes al momento en que acceda al mismo. Arriendo Asegurado se reserva el derecho de modificar en cualquier momento los presentes Términos y Condiciones con pleno respeto a las leyes que regulan el consumo de bienes y servicios en Chile, así como cualquiera otras condiciones generales o particulares que resulten de aplicación. Asimismo, Arriendo Asegurado se reserva el derecho de suspender, interrumpir o dejar de operar el Sitio Web en cualquier momento. 
                                </p>
                                <p>
                                    Al momento de pulsar el botón "Acepto", en la página web de AA, los presentes Términos y Condiciones serán vinculantes y obligarán al Usuario para todos los efectos legales a que haya lugar.
                                </p>

                                <h4 className="subtitle">2.- Contenidos</h4>
                                <p>
                                    A través del Sitio Web, Arriendo Asegurado facilita al Usuario un servicio recaudación y cobranza de renta de arriendo, y la contratación de un seguro asociado al pago de la misma, incluyendo la cobertura de los gastos legales que correspondan en caso de incumplimiento en el pago de dicha renta (en adelante, el "Servicio"). El Sitio Web, facilita al Usuario el acceso a diversos contenidos, información y datos proporcionados por Arriendo Asegurado en relación con el Servicio (en adelante, los "Contenidos"). Arriendo Asegurado se reserva el derecho a modificar en cualquier momento la presentación y la configuración del Sitio Web, así como las correspondientes condiciones de acceso y uso. Arriendo Asegurado no garantiza que los Contenidos proporcionados a través del Sitio Web serán en todo caso correctos, completos o actualizados.
                                </p>
                                <p>
                                    Será responsabilidad exclusiva de los Usuarios que los contratos de arrendamiento entre propietarios y arrendatarios se materialicen, ya que Arriendo Asegurado únicamente pone a disposición del Usuario una herramienta para que éstos últimos puedan, eventualmente, celebrar dicho contrato. Asimismo, será obligación de los Usuarios que los datos de los contratos de arrendamiento solicitados por Arriendo Asegurado al ingresar al Sitio Web, sean ingresados en idénticos términos que aquellos correspondientes al contrato de arrendamiento firmado por las partes ante notario. En caso contrario, esto es, que existan divergencias entre los datos ingresados por los Usuarios en el Sitio Web y el contrato de arrendamiento suscrito, la póliza de seguros asociada, cuando corresponda, no será aplicable.
                                </p>
                                <p>
                                    Se deja expresa constancia que Arriendo Asegurado no participa en la búsqueda ni selección de arrendatarios, ni le corresponde participación alguna en la negociación de los contratos de arriendo que el Usuario celebre.
                                </p>
                                <p>
                                    El contrato de arrendamiento que Arriendo Asegurado pone disposición del Usuario en el Sitio Web es meramente referencial, siendo la responsabilidad de su suscripción exclusivamente de las partes comparecientes al mismo, esto es, propietario y arrendatario.
                                </p>

                                <h4 className="subtitle">3.- Acceso y Uso</h4>
                                <p>
                                    El acceso al Sitio Web está limitado a mayores de 18 años. No obstante lo anterior, el acceso a determinados contenidos y/o participación en las promociones de Arriendo Asegurado puede estar limitado a menores emancipados, según se indique en cada caso. Con carácter general, el Usuario podrá acceder al Sitio Web de forma libre y gratuita. El Usuario reconoce y acepta que el acceso y uso del Sitio Web tiene lugar libre y conscientemente, bajo su exclusiva responsabilidad. Arriendo Asegurado no será en ningún caso responsable del uso que el Usuario y/o terceros pudieran hacer del Sitio Web ni de los daños y perjuicios que pudieran derivarse del mismo.
                                </p>
                                <p>
                                    El Usuario se compromete a hacer un uso adecuado y lícito del Sitio Web de conformidad con la legislación aplicable, los presentes Términos y Condiciones, la moral y buenas costumbres generalmente aceptadas y el orden público. El Usuario deberá abstenerse de (i) hacer un uso no autorizado o fraudulento del Sitio Web; (ii) acceder o intentar acceder a recursos restringidos del Sitio Web; (iii) utilizar el Sitio Web con fines o efectos ilícitos, ilegales, contrarios a lo establecido en los presentes Términos y Condiciones, a la buena fe y al orden público, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar o sobrecargar o impedir la normal utilización o disfrute del Sitio Web; (iv) provocar daños en el Sitio Web o en los sistemas de sus proveedores o de terceros; (v) introducir o difundir virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en los sistemas de Arriendo Asegurado, de sus proveedores o de terceros, (vi) intentar acceder, utilizar y/o manipular los datos de Arriendo Asegurado, terceros proveedores y otros usuarios; (vii) reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los Contenidos y/o el Sitio Web, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello esté legalmente permitido; (viii) obtener o intentar obtener los Contenidos empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su disposición a este efecto.
                                </p>
                                <p>
                                    En caso que el Usuario incumpla con el uso adecuado y lícito del Sitio Web, de acuerdo a los parámetros antes señalados, Arriendo Asegurado podrá bloquear unilateralmente de forma temporal o definitiva su cuenta, sin derecho a indemnización alguna en favor del Usuario. El Usuario únicamente podrá acceder al Sitio Web a través de los medios autorizados.
                                </p>

                                <h4 className="subtitle">4.- Condiciones Adicionales para el Acceso y Uso</h4>
                                <p>
                                    El acceso y uso del Sitio Web estará sujeto en todo caso al previo registro del Usuario mediante la cumplimentación del correspondiente formulario ("Formulario de Registro"), que será previamente facilitado digitalmente al Usuario, debiendo éste completar todos los antecedentes que revisten el carácter de obligatorios. Arriendo Asegurado podría negar el Registro de un Usuario si, a su juicio, no cumple con los Términos y Condiciones, política de privacidad de Arriendo Asegurado y demás disposiciones pertinentes. Los datos introducidos por el Usuario en el Formulario de Registro deberán ser exactos, actuales y veraces en todo momento.
                                </p>
                                <p>
                                    El acceso y uso del Sitio Web estará sujeto una contraseña asignada al Usuario por Arriendo Asegurado al rellenar el Formulario de Registro y el acceso se reputará realizado por dicho Usuario, quien responderá en todo caso de tal acceso y uso. El Usuario será responsable en todo momento de la custodia de su contraseña, asumiendo, en consecuencia, cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación o extravío de la misma. En caso de olvido de la contraseña o cualquier otra circunstancia que suponga un riesgo de acceso y/o utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente a Arriendo Asegurado a fin de que éste proceda inmediatamente al bloqueo y sustitución de la misma. En todo caso, cualesquiera operaciones realizadas antes de dicha comunicación se reputarán efectuadas por el Usuario, quien será responsable y pagará los gastos y/o daños que se deriven de cualquier acceso y/o uso no autorizado que se realice con anterioridad a dicha comunicación.
                                </p>

                                <h4 className="subtitle">5.- Relación Contractual y Precio del Servicio</h4>
                                <p>
                                    El Usuario, declara que a partir del momento en que acepta estos Términos y Condiciones a través del Sitio Web, ha consentido en iniciar una relación contractual con Arriendo Asegurado, la cual podrá acreditarse ante cualquier persona natural o jurídica, de derecho público o privada, mediante el comprobante de pago, factura, boleta y/o cualquier otro documento tributario que se emita en virtud de la prestación del Servicio, según corresponda, facultando a Arriendo Asegurado para presentar cualquiera de dichos documentos ante quien estime conveniente.
                                </p>
                                <p>
                                    El Usuario deberá pagar a Arriendo Asegurado los montos que corresponden a la prima del seguro contratado con la respectiva compañía de seguros y a la remuneración de los servicios de recaudación y cobranza prestados por Arriendo Asegurado. La suma que corresponda a dichos montos ascenderá a un porcentaje del valor de la renta de arrendamiento anual del respectivo contrato de arrendamiento u otro valor, el que, en cualquier caso, será informado en el Sitio Web de forma previa a su cancelación. El precio por el Servicio, independiente cual sea su valor, podrá ser pagado en cuotas, siempre y cuando sea efectuado mediante tarjeta de crédito, o también mediante otros medios de pago, los que serán debidamente informados en el Sitio Web.
                                </p>

                                <h4 className="subtitle">6.- De la Conducta de los Usuarios</h4>
                                <p>
                                    El Usuario reconoce y acepta que no podrá autorizar a terceros para utilizar la cuenta que hubiere creado en el Sitio Web. Asimismo, no podrá ceder o transferir dicha cuenta.
                                </p>

                                <h4 className="subtitle">7.- Mandato de Cobranza y Recaudación de la Renta y de Defensa Judicial</h4>
                                <p>
                                    Las rentas asociadas a los contratos de arrendamiento serán cobradas y recaudadas directamente por Arriendo Asegurado o a través de terceros que ésta subcontrate. En caso que sea por medio de un tercero, Arriendo Asegurado transferirá al Usuario la renta correspondiente solo una vez que dicho tercero se la haya previamente transferido.  Para estos efectos, por el presente acto, el Usuario viene en otorgar mandato irrevocable a Arriendo Asegurado tan amplio como en derecho sea necesario, en los términos del artículo 2.161 y siguientes del Código Civil, para que, actuando por medio de uno cualquiera de sus apoderados, en su nombre y representación, pueda recaudar directa o indirectamente y pagarle toda suma que éste reciba a través del Sitio Web, especialmente aquellas que digan relación con la renta de arrendamiento. Dentro del referido mandato se encuentra incluida la facultad de Arriendo Asegurado para pagar la prima del seguro contratado con la respectiva compañía de seguros y la de efectuar, directamente o a través de terceros, toda acción legal que tenga por objeto obtener el pago de la renta de arrendamiento y de toda otra suma que adeude un arrendatario, ya sea por concepto de multas, indemnización, reajuste o intereses, además del desalojo y restitución del inmueble que corresponda.
                                </p>
                                <p>
                                    ArriendoAsegurado acepta este mandato y queda expresamente facultado para ejercerlo y cumplir con las instrucciones precedentemente señaladas, en cualquier tiempo y a su sola elección, exclusivamente para cumplir con lo previsto precedentemente.
                                </p>
                                <p>
                                    El presente mandato así como estos Términos y Condiciones se mantendrán vigentes durante todo el tiempo que el Usuario mantenga vigente un contrato de arrendamiento regido por el Servicio.
                                </p>
                                <p>
                                    ArriendoAsegurado queda expresamente relevado de la obligación de rendir cuenta del presente mandato.
                                </p>
                                <p>
                                    ArriendoAsegurado no realizará transacciones de reversa con respecto a los montos recaudados, es decir, el Usuario no podrá anular las recaudaciones ya realizadas por Arriendo Asegurado o por un tercero que éste subcontrate.
                                </p>
                                <p>
                                    Sin perjuicio del mandato otorgado y de que Arriendo Asegurado realizará sus mejores esfuerzos, no garantiza que la renta de arrendamiento sea efectivamente pagada, ni tampoco que la defensa judicial asociada a dicho incumplimiento sea exitosa. 
                                </p>
                                <p>
                                    Asimismo, se deja expresa constancia que la defensa judicial no incluye la restitución del inmueble cuando existan subarrendatarios. 
                                </p>
                                <p>
                                    Con arreglo a lo dispuesto en la Política de Privacidad, los datos de la tarjeta de crédito y/o débito facilitados en el Formulario de Registro, cuando corresponda, serán remitidos a la empresa proveedora de pagos y encargada de almacenar dichos datos del Usuario. El Usuario deberá acceder o podrá ser redirigido al sitio web del proveedor de pagos a través del enlace facilitado al efecto en el Formulario de Registro. En todo caso, se recomienda al Usuario consultar los términos y condiciones de uso y las políticas de privacidad de los sitios web de terceros a los que accedan a través de enlaces incluidos en el Sitio Web.
                                </p>
                                <p>
                                    Por motivos de seguridad no está permitido utilizar un mismo método de pago en más de dos cuentas independientes. En caso de que se sobrepase este número, ArriendoAsegurado puede proceder a bloquear el servicio a todas las cuentas hasta que se demuestre documentalmente la propiedad de las cuentas y del método de pago.
                                </p>
                                <p>
                                    Asimismo, conforme a lo previsto en la Política de Privacidad, los datos bancarios del Usuario a los que Arriendo Asegurado pueda tener acceso a raíz del pago por el Servicio mediante transferencia bancaria, tendrán la consideración a todos los efectos de datos personales de los Usuarios.
                                </p>
                                <p>
                                    Las comprobantes de pago, facturas, boletas y/o cualquier otro documento tributario que se emita en virtud de la prestación del Servicio, podrá ser solicitado por el Usuario directamente en el Sitio Web, o bien estarán a su disposición en su perfil de Usuario de que dispondrá en el Sitio Web.
                                </p>

                                <h4 className="subtitle">8.- Derechos de Propiedad Intelectual e Industrial</h4>
                                <p>
                                    El Usuario reconoce y acepta que todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o cualesquiera otros elementos insertados en el Sitio Web (incluyendo, sin limitación, marcas, logotipos, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentación, audio y vídeo), pertenecen exclusivamente a Arriendo Asegurado.
                                </p>
                                <p>
                                    El Usuario reconoce y acepta que todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o cualesquiera otros elementos insertados en el Sitio Web (incluyendo, sin limitación, marcas, logotipos, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentación, audio y vídeo), pertenecen exclusivamente a Arriendo Asegurado.
                                </p>
                                <p>
                                    Cualquier otro uso o explotación de cualesquiera contenidos y/u otros elementos insertados en el Sitio Web distinto de los aquí expresamente previstos, estará sujeto a la autorización previa de Arriendo Asegurado.
                                </p>

                                <h4 className="subtitle">9.- Protección De Datos</h4>
                                <p>
                                    Los datos personales (en adelante, los "Datos") que el Usuario proporciona por medio del Formulario de Registro se integrarán en un fichero de datos personales del que es responsable Arriendo Asegurado, cuya dirección figura en el encabezamiento del presente documento. Para más información consultar la Política de Privacidad.
                                </p>

                                <h4 className="subtitle">10.- Exclusión de Garantías y Responsabilidad</h4>
                                <p>
                                    ArriendoAsegurado no garantiza la disponibilidad y continuidad del funcionamiento del Sitio Web. En consecuencia, Arriendo Asegurado no será en ningún caso responsable por cualesquiera daños y perjuicios que puedan derivarse de (i) la falta de disponibilidad o accesibilidad al Sitio Web; (ii) la interrupción en el funcionamiento del Sitio Web o fallos informáticos, averías telefónicas, desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas telefónicas, centros de datos, en el sistema de Internet o en otros sistemas electrónicos, producidos en el curso de su funcionamiento; y (iii) otros daños que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al control de Arriendo Asegurado.
                                </p>
                                <p>
                                    ArriendoAsegurado no garantiza la ausencia de virus ni de otros elementos en el Sitio Web introducidos por terceros ajenos a Arriendo Asegurado que puedan producir alteraciones en los sistemas físicos o lógicos del Usuario o en los documentos electrónicos y ficheros almacenados en sus sistemas. En consecuencia, ArriendoAsegurado no será en ningún caso responsable de cualesquiera daños y perjuicios de toda naturaleza que pudieran derivarse de la presencia de virus u otros elementos que puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos o ficheros del Usuario.
                                </p>
                                <p>
                                    ArriendoAsegurado adopta diversas medidas de protección para proteger el Sitio Web y los contenidos contra ataques informáticos de terceros. No obstante, Arriendo Asegurado no garantiza que terceros no autorizados no puedan conocer las condiciones, características y circunstancias en las cuales el Usuario accede al Sitio Web. En consecuencia, Arriendo Asegurado no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.
                                </p>
                                <p>
                                    Con la suscripción de los presentes Términos y Condiciones, el Usuario declara que mantendrá indemne frente a cualquier reclamación a Arriendo Asegurado, su sociedad matriz, directores, socios, empleados, abogados y agentes, de hechos que le sean imputables derivados del (i) incumplimiento por parte del Usuario de cualquier disposición contenida en los presentes Términos y Condiciones o de cualquier ley o regulación aplicable a los mismos; (ii) incumplimiento o violación de los derechos de terceros incluyendo; y (iii) incumplimiento del uso permitido del Sitio Web. 
                                </p>

                                <h4 className="subtitle">11.- Notificaciones</h4>
                                <p>
                                    ArriendoAsegurado podrá realizar las notificaciones oportunas a través de una notificación general en el Sitio Web, a través de la dirección de correo electrónico facilitada por el Usuario en el Formulario de Registro o mediante correo ordinario a la dirección facilitada por el Usuario en el Formulario de Registro. El Usuario podrá notificar a Arriendo Asegurado a través del envío de un correo electrónico a la dirección contacto@arriendoasegurado.cl, o bien por medio del Contacto del Sitio Web.
                                </p>

                                <h4 className="subtitle">12.- Cesión</h4>
                                <p>
                                    El Usuario no podrá ceder sus derechos y obligaciones que emanen de los presentes Términos y Condiciones sin el previo consentimiento escrito de Arriendo Asegurado. Arriendo Asegurado podrá ceder, sin necesidad de recabar el consentimiento previo del Usuario, los presentes Términos y Condiciones a cualquier entidad comprendida dentro de su grupo de sociedades, en todo el mundo, así como a cualquier persona o entidad que le suceda en el ejercicio de su negocio por cualquier título.
                                </p>

                                <h4 className="subtitle">13.- Ley Aplicable y Jurisdicción</h4>
                                <p>
                                    Los presentes Términos y Condiciones, así como la relación entre Arriendo Asegurado y el Usuario, se regirán e interpretarán con arreglo la ley chilena. Las partes acuerdan que conforme a la Ley de Protección al Consumidor, el Usuario (consumidor) que desee ejercer acciones legales contra Arriendo Asegurado, podrá hacerlos ante el Juzgado de Policía Local de (i) lugar donde se celebró o ejecutó el acto o contrato, (ii) domicilio del proveedor, o (iii) domicilio del consumidor, a su elección. Es decir, el Usuario siempre puede elegir en cual de esos tres territorios ejercerá su acción.
                                </p>

                                <h4 className="subtitle">14.- Documentos Electrónicos</h4>
                                <p>
                                    El Usuario en su calidad de receptor manual de documentos electrónicos, de conformidad con la Resolución Exenta N° 11 del 14 de febrero de 2003 del Servicio de Impuestos Internos (que estableció el procedimiento para que contribuyentes autorizados para emitir documentos electrónicos puedan también enviarlos por estos medios a receptores manuales), declara y acepta lo siguiente:
                                </p>
                                <p>
                                    Al aprobar estos Términos y Condiciones, el Usuario autoriza a la empresa <strong>Insurtech SpA</strong>, rol único tributario número 76.874.069-0, para que le sean enviados solamente por un medio electrónico <span style={{textDecoration:" underline"}}>los documentos tributarios que no amparan el traslado de bienes corporales muebles, ya sea vía correo electrónico registrado en su perfil de Usuario o mediante su publicación en el sitio web www.arriendoasegurado.com</span>
                                </p>
                                <p>
                                    De conformidad con la normativa indicada, y en caso que el Usuario lo requiera para respaldar la información contable, éste asume en relación a dichos documentos tributarios, las siguientes obligaciones:
                                </p>
                                <ul>
                                    <li>Imprimir los documentos recibidos en forma electrónica, para cada período tributario, en forma inmediata a su recepción desde el emisor.</li>
                                    <li>Imprimir el documento en el tamaño y forma que fue generado.</li>
                                    <li>Utilizar papel blanco tipo original de tamaño mínimo 21,5 cm x 14 cm (1/2 carta) y de tamaño máximo 21,5 x 33 cm (oficio).</li>
                                    <li>Imprimir en una calidad que asegure la permanencia de la legibilidad del documento durante un periodo mínimo de seis años, conforme lo establece la legislación vigente sobre la materia. Esta impresión se hará hecha usando impresión láser o de inyección de tinta, excepto que se establezca una autorización o norma distinta al respecto.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;
