import React, { Component } from 'react';
import "antd/dist/antd.css";
import AppRouter from './Components/Shared/AppRouter';
import './reset.css';
import './App.css';


class App extends Component {

  render() {
    return (
      <div className="App">
       <AppRouter/>
      </div>
    );
  }
}

export default App;
