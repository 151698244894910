import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from '../Landing/Landing';
import Lead from '../Lead/Lead';
import AdminContainer from '../Admin/'
import MainLayout from "./MainLayout";
import { WrappedLogin } from "../Admin/auth/Login";
import Pay from "../Legacy/Pay";
import Faq from "../Legacy/Faq";


function renderWithLayout(Component, Layout, props) {
  return <Layout><Component {...props} /></Layout>
}

function AppRouter(props) {
  return (
    <Router>
      <Switch>
          <Route exact path="/" render={() => renderWithLayout(Landing, MainLayout)} />
          <Route exact path="/lead" render={(props) => renderWithLayout(Lead, MainLayout, props)} />
          <Route exact path="/login" component={WrappedLogin} />
          <Route exact path="/pagar" component={Pay} />
          <Route exact path="/faq" component={Faq} />

          <AdminContainer />
          
        </Switch>



    </Router>
  );
}

export default AppRouter;
