import React, { Component } from 'react';
import { Row, Col, Card, Button, Divider } from 'antd';

import ImageUpload from './partials/ImageUpload';
import PropertyForm from './partials/PropertyForm';
import PropertyDetail from './partials/PropertyDetail';
import ListingDetail from './partials/ListingDetail';
import SelectListing from './partials/SelectListing';

import './info1.css'
import { updateData } from '../../../../utils/intercom';




class PropertyStore extends Component {

  constructor(props) {
    super(props);

    this.state = {
      properties: [],
      loading_step: false,
      step: 1
    }
  }

  async componentDidMount() {
    updateData({})
  }

  back = () => {
    this.props.history.goBack()
  }

  next = () => {
    this.setState({ loading_step: true })
    setTimeout(() => { this.setState({ loading_step: false, step: this.state.step + 1 }) }, 1500)
  }

  listing = () => {
    this.setState({ loading_step: true })
    setTimeout(() => { this.setState({ loading_step: false, step: this.state.step + 1 }) }, 1500)
  }

  noListing = () => {
    this.props.history.push('/propietary/applicants')
  }

  render() {
    return (
      <div style={{ width: '90%', margin: '0 auto' }} >
        <Row className="admin-header" gutter={16}>
          <Col xs={17} span={20}>
            <h1 className="tituloInfo1">Nueva propiedad</h1>
          </Col>
          {/* <Col xs={6} span={4}>
                    <Button onClick={()=>this.back()} type="default">Cancelar</Button>
                </Col> */}
          <Divider />
        </Row>

        <div className="paso1">

          <Row gutter={50} >
            {/*                 <Col md={12} xs={24}>
                        <ImageUpload/>
                    </Col> */}
            <Col md={6} xs={0} ></Col>

            <Col md={12} xs={24} >

              {this.state.step == 1 ?
                <PropertyForm next={() => this.next()} history={this.props.history} /> :
                null
              }

              {this.state.step == 2 ?
                <SelectListing listing={() => this.listing()} noListing={() => this.noListing()} /> :
                null
              }

              {this.state.step == 3 ?
                <PropertyDetail /> :
                null
              }

              {this.state.step == 4 ?
                <ListingDetail /> :
                null
              }
              {/*                         <Card style={{border:'0px'}}>
                           {this.state.step!=2 ?
                           <Button type="primary" onClick={()=>this.next()} loading={this.state.loading_step} style={{marginTop:'50px'}} block>Continuar</Button>:
                           null
                           }
                        </Card> */}
            </Col>


          </Row>
        </div>
      </div>

    );
  }
}

export default PropertyStore;
