/*Elementos */
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

/*CSS*/
import './aliados.css';
import '../ResetFont.css';
import './aliadosMobile.css';
import './aliadosTablet.css';
import './aliadosDesktop.css';


/*Logos*/
import logoContinental from '../../../assets/img/continental.png'
import logoAbogados from '../../../assets/img/legal.png'
import logoBci from '../../../assets/img/bci.png'
import logoServipag from '../../../assets/img/servipag.png'


class Aliados extends Component {

  callback(key) {
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="partnersContainer">
          <h1 className="tituloPasos">Nuestros aliados</h1>
          <div className="logosContainer">
            <Row>
              <div className="punteoPartners bloqueLogoPartnersSinTexto">
                <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 12, lg: 12 }}>    
                  <img className="logosPartners logoPartnerSinTexto" src={logoContinental} alt="avalados por seguros continental" />
                </Col>
                <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>               
                  <img className="logosPartnersHorizontales logoPartnerSinTexto" src={logoAbogados} alt="logo grupo de abogados legal chile" />                
                </Col>
              </div>
            </Row>
            <Row>
              <div className="bloqueLogoPartnersSinTexto">
                <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
                  <img className="logosPartners logoPartnerSinTexto" src={logoBci} alt="logo banco Bci" />    
                </Col>
                <Col className="partnersCol" span={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
                  <img className="logosPartnersHorizontales logoPartnerSinTexto" src={logoServipag} alt="logo servipag" />
                </Col>
              </div>
            </Row>         
          </div>
        </div>
        <Row className="pagoDirectoRow">
          <Col span={24}>
            <Button className="btnSecundarioFondoOScuro btnPublicaAhora"><Link to="/">Publica tu propiedad ahora</Link></Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Aliados;
